import React from 'react'
import {Route, Routes} from 'react-router-dom'
import BuyerAdsList from './componenets/BuyerAdsList'
import BuyerAdsDetailPage from './BuyerAdsDetailPage'

const BuyerAdsPage: React.FC = () => {

  return <Routes>
      <Route index element={<BuyerAdsList/>}/>
      <Route path={'/list'} element={<BuyerAdsList/>}/>
      <Route path={'/detail/:id'} element={<BuyerAdsDetailPage/>}/>
  </Routes>
}
export default BuyerAdsPage