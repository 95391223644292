import React, {useEffect, useState} from "react";
import * as Yup from "yup"
import * as auth from '../../redux/AuthRedux'
import './RegisterForm.css'
import {useIntl} from "react-intl";
import {useFormik} from "formik";
import {smsVerify, userInit} from "../../redux/AuthCRUD";
import {Notify, usePageData} from "../../../../layouts/core/PageData";
import {Link, useNavigate} from "react-router-dom";
import clsx from "clsx";
import LoginForm from "../login/LoginForm";
import {useModal} from "../../../../AppContext";
import {useDispatch} from "react-redux";
import {InitUserInit} from "../../models/UserInitModel";
import Select from "react-select";
import {cityList} from "../../../cities/redux/cityCRUD";
import {InitialSelectOptionData, SelectOptionModel} from "../../../global/models/SelectOptionModel";
import {districtList} from "../../../districts/redux/districtCRUD";
import {IDataFilter} from "../../../global/models/DataFilter";
import {Me} from '../../../global/helpers/UserHelpers'

type VerifyData = {
  code: string
}

type Props = {
  className: string
}

const RegisterForm:React.FC<Props> = ({className}) => {
  const user = Me()
  const dispatch = useDispatch()
  const intl = useIntl()
  const {setModal} = useModal()
  const navigate = useNavigate()
  const {setNotifyPush} = usePageData()

  const [step, setStep] = useState(1)
  const [smsId, setSmsId] = useState('')
  const [stopRecentTimer, setStopRecentTimer] = useState(false)
  const [recentTimer, setRecentTimer] = useState(60)

  const initValidationScheme = Yup.object().shape({
    phone: Yup.string().required().matches(
      /^\+[0-9\-().\s]{12}$/,
      intl.formatMessage({'id': 'Length'}, {name: intl.formatMessage({'id': 'Phone'}), len: 13})
    ),
    fullName: Yup.string()
      .min(3, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Full name'}), len: 3}))
      .max(150, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Full name'}), len: 150})),
    password: Yup.string().required()
      .min(3, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Password'}), len: 3}))
      .max(150, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Password'}), len: 150})),
    districtId: Yup.string().required(intl.formatMessage({'id': 'Required field'}, {name: intl.formatMessage({id: 'District'})})),
    birth: Yup.date().required(intl.formatMessage({'id': 'Required field'}, {name: intl.formatMessage({id: 'Birth'})})),
  })

  const verifyValidationScheme = Yup.object().shape({
    code: Yup.string().required(intl.formatMessage({'id': 'Required field'}, {name: intl.formatMessage({id: 'SMS code'})})).matches(
      /[0-9]{6}/,
      intl.formatMessage({'id': 'SMS code required 6 numbers'})
    ),
  })

  const initVerifyData = {code: ''} as VerifyData

  const initFormik = useFormik({
    initialValues: InitUserInit,
    validationSchema: initValidationScheme,
    onSubmit: (values, {setSubmitting}) => {
      setTimeout(()=>{
        userInit(values).then(response =>{
          setSubmitting(false)
          const msg: Notify = {
            title: intl.formatMessage({id: 'Register'}),
            message: intl.formatMessage({id: 'Error'}),
            danger: true,
          }
          if ([200, 201].includes(response.status)) {
            msg.danger = false
            msg.message = intl.formatMessage({id: 'Success'})
            if (response.data.user_sms_id && response.data.user_sms_id.length === 36) {
              setSmsId(response.data.user_sms_id)
              setStep(3)
              startRecentTimer()
            }
          }
          setNotifyPush(msg)
        })
      }, 1000)
    }
  })

  const verifyFormik = useFormik({
    initialValues: initVerifyData,
    validationSchema: verifyValidationScheme,
    onSubmit: (values, {setSubmitting}) => {
      setTimeout(()=>{
        smsVerify(smsId, values.code).then(response =>{
          setSubmitting(false)
          const msg: Notify = {
            title: intl.formatMessage({id: 'Verify'}),
            message: intl.formatMessage({id: 'Error'}),
            danger: true,
          }
          if ([200, 201].includes(response.status)) {
            msg.danger = false
            msg.message = intl.formatMessage({id: 'Success'})
            if (response.data.token) {
              dispatch(auth.actions.login(response.data.token))
              navigate('/dashboard')
            }
          }
          setNotifyPush(msg)
        })
      }, 1000)
    }
  })

  const startRecentTimer = () => {
    setRecentTimer(60)
    setStopRecentTimer(false)
  }

  // city
  const [citySearch, setCitySearch] = useState('')
  const [cityMenuOpen, setCityMenuOpen] = useState(false)
  const [cityHasNextPage, setCityHasNextPage] = useState(false)
  const [cityOptions, setCityOptions] = useState([InitialSelectOptionData,])
  const [cityPage, setCityPage] = useState(1)
  const [cityValue, setCityValue] = useState<SelectOptionModel>(InitialSelectOptionData)
  // district
  const [districtSearch, setDistrictSearch] = useState('')
  const [districtMenuOpen, setDistrictMenuOpen] = useState(false)
  const [districtHasNextPage, setDistrictHasNextPage] = useState(false)
  const [districtOptions, setDistrictOptions] = useState<SelectOptionModel[]>([])
  const [districtPage, setDistrictPage] = useState(1)
  const [districtValue, setDistrictValue] = useState<SelectOptionModel>(InitialSelectOptionData)

  const loadCityOptions = () => {
    if (cityMenuOpen && cityHasNextPage) {
      const filter = {'page': cityPage, search: citySearch, lan: intl.locale} as IDataFilter
      cityList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setCityHasNextPage(cityPage <= totalPages)
          if ((cityPage === 1 || totalItems > cityOptions.length) && response.data.items) {
            const newItems = [
              ...(cityPage > 1 ? cityOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setCityOptions(newItems)
          }
        }
      })
    }
  }

  const loadDistrictOptions = () => {
    if (districtMenuOpen && districtHasNextPage) {
      const filter: IDataFilter = {'page': districtPage, search: districtSearch, lan: intl.locale, cityId: cityValue.value}
      districtList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setDistrictHasNextPage(districtPage <= totalPages)
          if ((districtPage === 1 || totalItems > districtOptions.length) && response.data.items) {
            const newItems = [
              ...(districtPage > 1 ? districtOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setDistrictOptions(newItems)
          }
        }
      })
    }
  }

  const loadData = () => {
    if (user.id.length === 36 && user.status === 'subscriber') {
      navigate('/dashboard/home')
    }
  }

  const loadTimer = () => {
    let interval: NodeJS.Timeout;
    if (!stopRecentTimer && recentTimer > 0) {
      interval = setInterval(() => {
        setRecentTimer((seconds) => seconds - 1);
      }, 1000);
    } else if (recentTimer === 0) {
      setStopRecentTimer(true);
    }
    return () => clearInterval(interval);
  }

  // eslint-disable-next-line
  useEffect(() => loadTimer(), [stopRecentTimer, recentTimer])

  // eslint-disable-next-line
  useEffect(()=>loadCityOptions(), [cityMenuOpen, cityPage, citySearch])

  // eslint-disable-next-line
  useEffect(()=>loadDistrictOptions(), [districtMenuOpen, districtPage, districtSearch, cityOptions])

  // eslint-disable-next-line
  useEffect(()=>loadData(), [user])

  return <div className={className}>
    {/* Login Form */}
    <div className="login-form default-form">
      <div className="form-inner">
        <h3 className='text-center'>{intl.formatMessage({id: 'Register'})}</h3>
        {/* begin::RegisterForm */}
        {step === 3 ? <form onSubmit={verifyFormik.handleSubmit}>
          {/* begin::Code */}
          <div className="form-group">
            <label>{intl.formatMessage({id: 'SMS code'})}</label>
            <input
              className={clsx('me-2 text-center form-control form-control-solid ',
                { 'is-invalid': verifyFormik.touched.code && verifyFormik.errors.code },
                { 'is-valid': verifyFormik.touched.code && !verifyFormik.errors.code }
              )}
              type='text'
              {...verifyFormik.getFieldProps('code')}
            />
            {verifyFormik.touched.code && verifyFormik.errors.code && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{verifyFormik.errors.code}</span>
              </div>
            )}
          </div>
          {/* end::Code */}
          {/* begin::ReSend */}
          <div className="form-group">
            <button
              className="theme-btn btn btn-success"
              type="button"
              name="register"
              disabled={!stopRecentTimer}
              onClick={_=>initFormik.submitForm()}
            >
              {intl.formatMessage({id: 'Recent'})} {recentTimer > 0 ? <span className="badge badge-light">{recentTimer}</span> : null}
            </button>
          </div>
          {/* end::ReSend */}
          <div className="form-group">
            <button className="theme-btn btn-style-one" type="submit" name="register">
              {intl.formatMessage({id: 'Verify'})}
            </button>
          </div>
        </form> : null}
        {step === 2 ? <form onSubmit={initFormik.handleSubmit}>
          {/* begin::FullName */}
          <div className="form-group">
            <label>{intl.formatMessage({id: 'Full name'})}</label>
            <input
              className={clsx('me-2 text-center form-control form-control-solid ',
                { 'is-invalid': initFormik.touched.fullName && initFormik.errors.fullName },
                { 'is-valid': initFormik.touched.fullName && !initFormik.errors.fullName }
              )}
              type='text'
              {...initFormik.getFieldProps('fullName')}
            />
            {initFormik.touched.fullName && initFormik.errors.fullName && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{initFormik.errors.fullName}</span>
              </div>
            )}
          </div>
          {/* end::FullName */}
          {/* begin::Phone */}
          <div className="form-group">
            <label>{intl.formatMessage({id: 'Phone'})}</label>
            <input
              className={clsx('me-2 text-center form-control form-control-solid ',
                { 'is-invalid': initFormik.touched.phone && initFormik.errors.phone },
                { 'is-valid': initFormik.touched.phone && !initFormik.errors.phone }
              )}
              type='text'
              {...initFormik.getFieldProps('phone')}
            />
            {initFormik.touched.phone && initFormik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{initFormik.errors.phone}</span>
              </div>
            )}
          </div>
          {/* end::Phone */}
          {/* begin::Password */}
          <div className="form-group">
            <label>{intl.formatMessage({id: 'Password'})}</label>
            <input
              className={clsx('me-2 text-center form-control form-control-solid ',
                { 'is-invalid': initFormik.touched.password && initFormik.errors.password },
                { 'is-valid': initFormik.touched.password && !initFormik.errors.password }
              )}
              type='password'
              {...initFormik.getFieldProps('password')}
            />
            {initFormik.touched.password && initFormik.errors.password && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{initFormik.errors.password}</span>
              </div>
            )}
          </div>
          {/* end::Password */}
          <div className="form-group">
            <button
              type="button"
              className="theme-btn btn-style-one"
              onClick={_=> initFormik.submitForm()}
            >
              {intl.formatMessage({id: 'Register'})}
            </button>
          </div>
        </form> : null}
        {step === 1 ? <form onSubmit={initFormik.handleSubmit}>
          {/* begin::SelectCity */}
          <div className="form-group">
            <label>{intl.formatMessage({id: 'City'})}:</label>
            <Select
              className='basic-single'
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              value={cityValue}
              menuIsOpen={cityMenuOpen}
              onChange={(s)=>{
                if (!s) { s = InitialSelectOptionData }
                setCityValue(s)
              }}
              onMenuScrollToBottom={()=>{
                setCityPage(1 + cityPage)
              }}
              onInputChange={(s, m)=> {
                if (m.action === "input-change"){
                  setCitySearch(s)
                }
              }}
              onMenuClose={()=>setCityMenuOpen(false)}
              onMenuOpen={()=>{
                setCityMenuOpen(true)
                setCityHasNextPage(true)
                setCityPage(1)
                setCitySearch('')
              }}
              options={cityOptions}
              styles={{
                control: (provided: any) => ({
                  ...provided,
                  flexWrap: 'nowrap',
                  height: '60px',
                })
              }}
            />
            <div className='form-text text-muted'>
              {intl.formatMessage({id: 'Required'})}
            </div>
          </div>
          {/* end::SelectCity */}
          {/* begin::SelectDistrict */}
          <div className='form-group'>
            <label>{intl.formatMessage({id: 'District'})}:</label>
            <Select
              className='basic-single'
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              value={districtValue}
              menuIsOpen={districtMenuOpen}
              onChange={(s)=>{
                if (!s) { s = InitialSelectOptionData }
                setDistrictValue(s)
                initFormik.setFieldValue('districtId', s.value)
              }}
              onMenuScrollToBottom={()=>{
                setDistrictPage(1 + districtPage)
              }}
              onInputChange={(s, m)=> {
                if (m.action === "input-change"){
                  setDistrictSearch(s)
                }
              }}
              onMenuClose={()=>setDistrictMenuOpen(false)}
              onMenuOpen={()=>{
                setDistrictMenuOpen(true)
                setDistrictHasNextPage(true)
                setDistrictPage(1)
                setDistrictSearch('')
              }}
              options={districtOptions}
              styles={{
                control: (provided: any) => ({
                  ...provided,
                  flexWrap: 'nowrap',
                  height: '60px',
                })
              }}
            />
            <div className='form-text text-muted'>
              {intl.formatMessage({id: 'Required'})}
            </div>
          </div>
          {/* end::SelectDistrict */}
          {/* begin::Birth */}
          <div className="form-group">
            <label>{intl.formatMessage({id: 'Birth'})}</label>
            <input
              className={clsx('me-2 text-center form-control form-control-solid ',
                { 'is-invalid': initFormik.touched.birth && initFormik.errors.birth },
                { 'is-valid': initFormik.touched.birth && !initFormik.errors.birth }
              )}
              type='date'
              {...initFormik.getFieldProps('birth')}
            />
            {initFormik.touched.birth && initFormik.errors.birth && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{initFormik.errors.birth}</span>
              </div>
            )}
          </div>
          {/* end::Birth */}
          <div className="form-group" style={{zIndex: 0}}>
            <button
              type="button"
              className="theme-btn btn-style-one"
              onClick={_=> setStep(2)}
            >
              {intl.formatMessage({id: 'Continue'})}
            </button>
          </div>
        </form> : null}
        {/* end::RegisterForm */}
        <div className="bottom-box">
          <div className="text">
            {intl.formatMessage({id: "Do you have an account?"})} {className === 'login-modal' ? <button
            type='button'
            className="call-modal signup"
            onClick={_=>setModal(<LoginForm className={className}/>)}
          >
            {intl.formatMessage({id: 'Login'})}
            </button> : <Link to={'/auth/login'}>
              {intl.formatMessage({id: 'Login'})}
            </Link>}
          </div>
        </div>
      </div>
    </div>
    {/*End Login Form */}
  </div>
}

export default RegisterForm