import React, {useEffect} from 'react'
import axios from 'axios'
import {usePageData} from './core/PageData'

const MainInit: React.FC = () => {
    const {setShowLoading} = usePageData()

    useEffect(()=>{
        // set loading from axios request
        let startLoad: number
        axios.interceptors.request.use(
          (request)=>{
              startLoad = new Date().getTime()
              setShowLoading(true)
              return request
          }
        )
        // set loading from axios request
        axios.interceptors.response.use(
          (response)=>{
              const interval = 1000 - (new Date().getTime() - startLoad)
              if (interval > 0) {
                  setTimeout(()=>{setShowLoading(false)}, interval)
              } else {
                  setShowLoading(false)
              }

              return response
          },
          (error: any) => {
              setShowLoading(false)
          }
        )
    }, [])

  return <></>
}
export default MainInit