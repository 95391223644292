import React from "react"
import {usePageData} from '../core/PageData'

const LoadingSpinner: React.FC = () => {
  const {showLoading} = usePageData()

  return <div
    className={`position-fixed w-100 h-100 ${showLoading ? '' : 'd-none'}`}
    style={{left: '50%', top: '50%', zIndex: 1000000}}
  >
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
}

export default LoadingSpinner