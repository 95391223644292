import axios, { AxiosResponse } from "axios";
import * as querystring from "querystring";
import {IDataFilter} from "../../global/models/DataFilter";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'buyer-ads'
const LIST = `${API_URL}/${_RESOURCE}/list`
const GET = `${API_URL}/${_RESOURCE}/get`

export function buyerAds(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    per: filter.perPage,
    page: filter.page,
  }))
}

export function buyerAdsGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}
