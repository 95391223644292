import React from 'react'
import {useParams} from 'react-router-dom'
import BuyerAdsDetail from './componenets/BuyerAdsDetail'

type Params = {
  id: string
}

const BuyerAdsDetailPage: React.FC = () => {
  const {id} = useParams<Params>()

  return <BuyerAdsDetail id={id}/>
}
export default BuyerAdsDetailPage