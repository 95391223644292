import { CategoryModel, InitialCategory } from "../../categories/models/CategoryModel";
import { DimensionModel, InitialDimension } from "../../dimensions/models/DimensionModel";

export interface GlobalProductModel {
  id: string
  name: string
  slug: string
  dimension: DimensionModel
  category: CategoryModel
}

export interface GlobalProductDataModel {
  id: string
  categoryId: string
  dimensionId: string
  nameUz: string
  nameOz: string
  nameRu: string
  slugUz: string
  slugOz: string
  slugRu: string
}

export const InitialGlobalProduct:GlobalProductModel = {
  id: '',
  name: '',
  slug: '',
  dimension: InitialDimension,
  category: InitialCategory,
}

export const InitialGlobalProductData:GlobalProductDataModel = {
  id: '',
  categoryId: '',
  dimensionId: '',
  nameUz: '',
  nameOz: '',
  nameRu: '',
  slugUz: '',
  slugOz: '',
  slugRu: '',
}