import React from "react"
import MainRoutes from "./MainRoutes";
import {SubscriberModel} from "../modules/subscribers/models/SubscriberModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../setup/redux/RootReducer";

const Routes: React.FC = () => {
  return <MainRoutes/>
}

export default Routes