import axios, { AxiosResponse } from "axios"
import * as querystring from "querystring"
import {IDataFilter} from "../../global/models/DataFilter";
const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'file-category'
const LIST = `${API_URL}/private/${_RESOURCE}/list`
const ONE = `${API_URL}/private/${_RESOURCE}/one`


export function fileCategoryOne(filter: IDataFilter) {
  return axios.post(ONE, querystring.stringify({
    id: filter.id,
    period: filter.period,
  }))
}

export function fileCategoryList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    search: filter.search,
    page: filter.page,
    except_periods: filter.except ? JSON.stringify(filter.except) : null
  }))
}