import React, {MouseEventHandler, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {useLang, useModal} from "../../../AppContext";
import LoginForm from "../../auth/components/login/LoginForm";
import {Me} from "../helpers/UserHelpers";
import ChoiceAdsAdd from "./ChoiceAdsAdd";
import {checkIsActive} from "../helpers/RouteHelpers";

type Props = {
  className?: string
}

const MainHeader:React.FC<Props> = ({className}) => {
  const user = Me()
  const navigate = useNavigate()
  const {lang, setLang} = useLang()
  const intl = useIntl()
  const {setModal} = useModal()
  const cns = ['mm-wrapper', 'mm-wrapper_opened', 'mm-wrapper_blocking', 'mm-wrapper_background', 'mm-wrapper_opening']

  const [mobileMenuShow, setMobileMenuShow] = useState(false)

  const accountHandler = () => {
    if (user.id.length === 36) {
      navigate('/dashboard')
    } else {
      setModal(<LoginForm className={'login-modal'}/>)
    }
  }

  const langSwitch = (lan: 'uz'|'oz'|'ru') => {
    setLang(lan)
  }

  const mobileMenuClose = () => {
    if (mobileMenuShow) {
      for (const c of cns) {
        document.body.classList.remove(c)
      }
    }
  }

  const mobileMenuOpen = () => {
    if (!mobileMenuShow) {
      for (const c of cns) {
        document.body.classList.add(c)
      }
    }
  }

  const mobileMenuToggle = () => {
    if (mobileMenuShow) {
      mobileMenuClose()
    } else {
      mobileMenuOpen()
    }
    setMobileMenuShow(!mobileMenuShow)
  }

  return <>
    <header className={`main-header ${className}`}>

      {/* begin::MainBox */}
      <div className="main-box">
        {/* begin::NavOuter */}
        <div className="nav-outer">
          <div className="logo-box">
            <div className="logo">
              <Link to={'/'}>
                <img src="/images/logo.svg" alt="logo" title="infobozor.uz" style={{height: 50}}/>
              </Link>
            </div>
          </div>
          {/* begin::MainMenu */}
          <nav className="nav main-menu">
            <ul className="navigation" id="navbar">
              {/* begin::Home */}
              <li className="current">
                <span>
                  <Link to={'/'}>{intl.formatMessage({id: 'Home'})}</Link>
                </span>
              </li>
              {/* end::Home */}
              {/* begin::Prices */}
              <li className="">
                <Link to={'/dashboard/file-download/add'}>{intl.formatMessage({id: 'Prices'})}</Link>
              </li>
              {/* end::Prices */}
              {/* begin::BazaarCalculator */}
              <li className="has-mega-menu" id="has-mega-menu">
                <Link to={'/dashboard/bazaar-calc'}>{intl.formatMessage({id: 'Bazaar calculator'})}</Link>
              </li>
              {/* end::BazaarCalculator */}
              {/* begin::Delivery */}
              <li className="">
                <Link to={'/delivery'}>{intl.formatMessage({id: 'Delivery'})}</Link>
              </li>
              {/* end::Delivery */}
              {/* begin::Advertisements */}
              <li className="">
                <Link to={'/advertisement'}>{intl.formatMessage({id: 'Advertisement'})}</Link>
              </li>
              {/* end::Advertisements */}

              {/* Only for Mobile View */}
              {/*<li className="mm-add-listing">
                <a href="add-listing.html" className="theme-btn btn-style-one">Job Post</a>
                <span>
                  <span className="contact-info">
                    <span className="phone-num"><span>Call us</span><a href="tel:1234567890">123 456 7890</a></span>
                    <span className="address">329 Queensberry Street, North Melbourne VIC <br/>3051, Australia.</span>
                    <a href="mailto:support@superio.com" className="email">support@superio.com</a>
                  </span>
                  <span className="social-links">
                    <a href="#"><span className="fab fa-facebook-f"></span></a>
                    <a href="#"><span className="fab fa-twitter"></span></a>
                    <a href="#"><span className="fab fa-instagram"></span></a>
                    <a href="#"><span className="fab fa-linkedin-in"></span></a>
                  </span>
                </span>
              </li>*/}
            </ul>
            <ul className="navigation">
              {/* begin::LanguageSwitch */}
              <li className="mx-2">
                <span
                  className={lang === 'uz' ? 'px-2 border-1 border-primary rounded bg-info text-white' : ''}
                  onClick={_=>langSwitch('uz')}
                >Uz</span>
              </li>
              <li className="mx-2">
                <span
                  className={lang === 'oz' ? 'px-2 border-1 border-primary rounded bg-info text-white' : ''}
                  onClick={_=>langSwitch('oz')}
                >Ўз</span>
              </li>
              <li className="mx-2">
                <span
                  className={lang === 'ru' ? 'px-2 border-1 border-primary rounded bg-info text-white' : ''}
                  onClick={_=>langSwitch('ru')}
                >Ру</span>
              </li>
              {/* end::LanguageSwitch */}
            </ul>
          </nav>
          {/* end::MainMenu */}
        </div>
        {/* end::NavOuter */}
        <div className="outer-box">
          {/* Add Listing */}
          {/* Login/Register */}
          <div className="btn-box">
            {/*<a href="login-popup.html" className="theme-btn btn-style-five call-modal"></a>*/}
            <button
              type='button'
              className='theme-btn btn-style-five btn mr15'
              onClick={accountHandler}
            >
              {intl.formatMessage({id: user.id.length === 36 ? 'Cabinet' : 'Login / Register'})}
            </button>

            <button
              type='button'
              className="theme-btn btn-style-one"
              onClick={_=>setModal(<ChoiceAdsAdd/>)}
            >
              {intl.formatMessage({id: 'Add advertisement'})}
            </button>
          </div>
        </div>
      </div>
      {/* end::MainBox */}

      {/* begin::MobileHeader */}
      <div className="mobile-header">
        <div className="logo">
          <Link to={'/'}><img src="/images/logo.svg" alt="infobozor.uz logo" title="infobozor.uz"/></Link>
        </div>
        {/*Nav Box*/}
        <div className="nav-outer clearfix">
          <div className="outer-box">
            {/* begin::CabinetOrLogin */}
            <div className="login-box">
              <button
                type='button'
                className='call-modal'
                onClick={accountHandler}
              >
                <span className="icon-user"></span>
              </button>
            </div>
            {/* end::CabinetOrLogin */}
            <button
              className="mobile-nav-toggler navbar-trigger"
              onClick={mobileMenuToggle}
            >
              <span className="flaticon-menu-1"></span>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Nav */}
      <div id="nav-mobile" className='mm-menu mm-menu_offcanvas mm-menu_position-bottom mm-menu_fullscreen mm-menu_theme-black mm-menu_opened'>
        <div className="mm-panels">
          <div id="navbar" className="mm-panel mm-panel_opened">
            <div className="mm-navbar mm-navbar_sticky">
              <span className="mm-navbar__title">Menu</span>
            </div>
            <ul className="navigation mm-listview" onClick={mobileMenuToggle}>
              {/* begin::Home */}
              <li className={`mm-listitem${checkIsActive('') ? ' current ' : '__btn mm-listitem__text'}`}>
                <Link to={'/'} className='mm-btn mm-listitem__btn mm-listitem__text'>
                  {intl.formatMessage({id: 'Home'})}
                </Link>
              </li>
              {/* end::Home */}
              {/* begin::Prices */}
              <li className={`mm-listitem${checkIsActive('/dashboard/file-download/add') ? ' current ' : '__btn mm-listitem__text'}`}>
                <Link to={'/dashboard/file-download/add'} className='mm-btn mm-listitem__btn mm-listitem__text'>
                  {intl.formatMessage({id: 'Prices'})}
                </Link>
              </li>
              {/* end::Prices */}
              {/* begin::BazaarCalculator */}
              <li className={`mm-listitem${checkIsActive('/dashboard/bazaar-calc') ? ' current ' : '__btn mm-listitem__text'}`}>
                <Link to={'/dashboard/bazaar-calc'} className='mm-btn mm-listitem__btn mm-listitem__text'>
                  {intl.formatMessage({id: 'Bazaar calculator'})}
                </Link>
              </li>
              {/* end::BazaarCalculator */}
              {/* begin::Delivery */}
              <li className={`mm-listitem${checkIsActive('/delivery') ? ' current ' : '__btn mm-listitem__text'}`}>
                <Link to={'/delivery'} className='mm-btn mm-listitem__btn mm-listitem__text'>
                  {intl.formatMessage({id: 'Delivery'})}
                </Link>
              </li>
              {/* end::Delivery */}
              {/* begin::Advertisements */}
              <li className={`mm-listitem${checkIsActive('/advertisement') ? ' current ' : '__btn mm-listitem__text'}`}>
                <Link to={'/advertisement'} className='mm-btn mm-listitem__btn mm-listitem__text'>
                  {intl.formatMessage({id: 'Advertisement'})}
                </Link>
              </li>
              {/* end::Advertisements */}
              {/* begin::OnlyForMobileView */}
              <li className="mm-add-listing mm-listitem">
                <button
                  type='button'
                  className="theme-btn btn-style-one mm-listitem__text"
                  onClick={_=>setModal(<ChoiceAdsAdd/>)}
                >
                  {intl.formatMessage({id: 'Add advertisement'})}
                </button>
                <span className="mm-listitem__text">
                  <span className="contact-info">
                    <span className="phone-num">
                      <span>{intl.formatMessage({id: 'Contact us'})}</span>
                      <a href="tel:1234567890">123 456 7890</a>
                    </span>
                    <span className="address">{intl.formatMessage({id: 'Address full'})}</span>
                    <a href="mailto:support@superio.com" className="email">support@superio.com</a>
                  </span>
                  <span className="social-links">
                    <a href="#"><span className="fab fa-facebook-f"></span></a>
                    <a href="#"><span className="fab fa-twitter"></span></a>
                    <a href="#"><span className="fab fa-instagram"></span></a>
                    <a href="#"><span className="fab fa-linkedin-in"></span></a>
                  </span>
                </span>
              </li>
              {/* end::OnlyForMobileView */}
            </ul>
          </div>
        </div>
      </div>
    </header>
  </>
}

export default MainHeader