import {IDataFilter} from "../../global/models/DataFilter";
import axios, {AxiosResponse} from "axios";
import * as querystring from "querystring";
import {ProductDataModel} from "../models/ProductModel";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'product-type'
const LIST = `${API_URL}/${_RESOURCE}/list`
const GET = `${API_URL}/${_RESOURCE}/get`

export function productTypeList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    search: filter.search,
    page: filter.page,
  }))
}


export function productTypeGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}
