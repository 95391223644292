import React, {useEffect, useState} from "react";
import {BazaarModel} from "../../bazaars/models/BazaarModel";
import Select, {MultiValue} from "react-select"
import {DistrictModel} from "../../districts/models/DistrictModel";
import {BazaarTypeModel} from "../../bazaar-types/models/BazaarTypeModel"
import {InitialSelectOptionData, SelectOptionModel} from "../../global/models/SelectOptionModel";
import {useIntl} from "react-intl"
import {downloadFileBazaars, downloadFileBazaarsForReport} from "../redux/downloadFileCRUD";
import {IDataFilter} from '../../global/models/DataFilter'
import {FileProduct} from '../models/DownloadFileModel'

type Params = {
  fileId?: string
  fileProducts?: FileProduct[]
  periodStart: string
  periodEnd: string
  setIds(v: string[]): void
}
const ProductBazaarsSelect: React.FC<Params> = ({fileId, fileProducts, periodStart, periodEnd, setIds}) => {
  const intl = useIntl()

  const [search, setSearch] = useState('')
  const [value, setValue] = useState<MultiValue<SelectOptionModel>>([])
  const [menuOpen, setMenuOpen] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [options, setOptions] = useState([InitialSelectOptionData,])
  const [page, setPage] = useState(1)

  const loadData = () => {
    if (fileId && fileId.length === 36) {
      downloadFileBazaars(fileId, intl.locale).then(response => {
        if (response.status === 200) {
          const res = response.data.items ? response.data.items.map((e: any) => {
            const m: BazaarModel = {
              id: e.id,
              name: e.name,
              slug: e.slug,
              openDays: e.open_days,
              district: {id: e.district.id, name: e.district.name, slug: e.district.slug} as DistrictModel,
              bazaarType: {id: e.type.id, name: e.type.name, slug: e.type.slug} as BazaarTypeModel,
            }
            return {value: m.id, label: `${m.district.name} - ${m.name} - ${m.bazaarType.name}`, color: ''}
          }) : []
          setValue(res)
        }
      })
    }
  }

  const loadBazaarOptions = () => {
    if (menuOpen && hasNextPage) {
      const filter: IDataFilter = {page, fileProducts, periodStart, periodEnd, search, lan: intl.locale}
      downloadFileBazaarsForReport(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setHasNextPage(page < totalPages)
          if ((page === 1 || totalItems > options.length) && response.data.items) {
            const newItems = [
              ...(page > 1 ? options : []),
              ...response.data.items.map((e: any)=>{
                return {value: e.id, label: `${e.district.name} - ${e.name} - ${e.type.name}`}
              })
            ]

            setOptions(newItems)
          }
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [fileId])

  // eslint-disable-next-line
  useEffect(()=>loadBazaarOptions(), [menuOpen, page, search])

  return <div className='row mb-10'>
    <label className='col-lg-3 col-form-label text-lg-end'>
      {intl.formatMessage({id: 'Bazaars'})}:
    </label>
    <div className='col-lg-9 col-xl-9'>
      <Select
        className='basic-single'
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        value={value}
        menuIsOpen={menuOpen}
        onChange={(s)=>{
          if (!s) { s = [] }
          setValue(s)
          setIds(s.map(e=>e.value))
        }}
        onMenuScrollToBottom={()=>{
          setPage(1 + page)
        }}
        onInputChange={(s, m)=> {
          if (m.action === "input-change"){
            setSearch(s)
          }
        }}
        onMenuClose={()=>setMenuOpen(false)}
        onMenuOpen={()=> {
          setMenuOpen(true)
          setHasNextPage(true)
          setOptions([])
          setPage(1)
          setSearch('')
        }}
        options={options}
      />
      <div className='form-text text-muted'>
        {intl.formatMessage({id: 'Required'})}
      </div>
    </div>
  </div>
}

export default ProductBazaarsSelect