import React from 'react'
import LoginForm from './components/login/LoginForm'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

const LoginPage: React.FC = () => {
  const intl = useIntl()

  return <>
    {/*Header Span*/}
    <span className="header-span"></span>
    {/* End Header Span*/}
    {/*Page Title*/}
    <section className="page-title">
      <div className="auto-container">
        <div className="title-outer">
          <h1>{intl.formatMessage({id: 'Login page'})}</h1>
          <ul className="page-breadcrumb">
            <li><Link to={'/'}>{intl.formatMessage({id: 'Home'})}</Link></li>
            <li>{intl.formatMessage({id: 'Login'})}</li>
          </ul>
        </div>
      </div>
    </section>
    {/*End Page Title*/}
    {/* List section */}
    <section className="ls-section">
      <div className="auto-container">
        <div className="filters-backdrop"></div>

        <div className="row d-flex justify-content-center">
          <LoginForm className={'col-sm-10 col-md-5 col-lg-3'}/>
        </div>
      </div>
    </section>
  </>
}
export default LoginPage