import React, { useEffect, useState } from "react";
import {ImageModel, InitialImageModel} from "../models/ImageModel";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import {useModal} from "../../../AppContext";
import ModelImageForm from "./ModelImageForm";

interface Params {
  max?: number
  deletes?: Array<string>
  images: Array<ImageModel>
  setDeletes(value: Array<string>): void
  setImages(value: Array<ImageModel>): void
}
const ModelImages:React.FC<Params> = ({deletes, images, max, setDeletes, setImages}) => {
  const {setModal} = useModal()
  const intl = useIntl()

  const movePosition = (current: number, next: number) => {
    if (next >= 0 && next < images.length && next !== current) {
      const updates = [...images]

      const old = updates[current]
      const move = updates[next]

      if (old && move) {
        const pos = old.pos

        old.pos = move.pos
        move.pos = pos

        updates[current] = move
        updates[next] = old

        setImages(updates)
      }
    }
  }

  const setImage = (value: ImageModel) => {
    setModal(<ModelImageForm
      initImage={value}
      setImages={setImages}
      setDeletes={setDeletes}
      initImages={images}
      initDeletes={deletes}
    />)
  }

  return <div className='mb-4 w-100'>
    {/* begin::ImageForm */}
    {/* end::ImageForm */}
    <div className='flex-nowrap d-flex justify-content-center' style={{overflowX: "auto", height: 225}}>
      {/* begin::AddButton */}
      {(!max || images.length < max) ? <div className='col-6' style={{maxWidth: 400}}>
        <button
          type='button'
          className='btn w-100 h-100 bg-light'
          style={{height: '300px!important'}}
          onClick={_=>setImage({id: '', path: '', pos: 0} as ImageModel)}
        >
          <i className='fa fa-plus fs-1 text-muted'></i>
        </button>
      </div> : null}
      {/* end::AddButton */}
      {images.map((e, i)=><div key={e.id} className='col-6 bg-light d-flex justify-content-end mx-2' style={{maxWidth: 400}}>
        <button
          type='button'
          className='btn col-6 h-100 '
          onClick={_=>setImage(e)}
        >
          <img src={e.path} alt={e.pos.toString()} className='w-100'/>
        </button>
        {/* end::ImageButton */}
        {/* begin::ChangePositionButtons */}
        <div className="row d-flex h-25px w-100px" style={{height:25, top: 120, position: "relative"}}>
          <button
            type='button'
            className='btn btn-icon btn-sm btn-light-info'
            onClick={_=>movePosition(i, i+1)}
            style={{bottom: 0}}
          >
            <i className='fa fa-arrow-alt-right'></i>
          </button>
          <button
            type='button'
            className='btn btn-icon btn-sm btn-light-info'
            onClick={_=>movePosition(i, i-1)}
            style={{bottom: 0}}
          >
            <i className='fa fa-arrow-alt-left'></i>
          </button>
        </div>
        {/* end::ChangePositionButtons */}
      </div>)}
    </div>
  </div>
}

export default ModelImages