import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {downloadFileCheckBazaarsProducts, downloadFileSave} from "../redux/downloadFileCRUD";
import {InitialDownloadFileData} from "../models/DownloadFileModel";
import {useIntl} from "react-intl";
import {useFormik} from "formik";
import clsx from "clsx"
import {InitialSelectOptionData, SelectOptionModel} from "../../global/models/SelectOptionModel";
import Select, {MultiValue} from "react-select"
import {Notify, usePageData} from "../../../layouts/core/PageData";
import {useNavigate} from "react-router-dom";
import {Me} from "../../global/helpers/UserHelpers";
import {IDataFilter} from "../../global/models/DataFilter";
import {DateFormat, PeriodToDate} from "../../global/helpers/DateHelpers";
import {FileCategoryModel} from "../models/FileCategoryModel";
import {fileCategoryList, fileCategoryOne} from "../redux/fileCategoryCRUD";
import {CapFirstLet} from "../../global/helpers/StringHelper";
import DownloadFileDayForm from "./DownloadFileDayForm";
import BazaarsSelect from '../../bazaars/components/BazaarsSelect'
import DownloadFileBazaarsSelect from './DownloadFileBazaarsSelect'
import {NumberFormat} from '../../global/helpers/ToHumansString'

type Props = {
  isCalc?: boolean
}

const DownloadFileAdd: React.FC<Props> = ({isCalc}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {setNotifyPush, showLoading} = usePageData()

  const [canPay, setCanPay] = useState<boolean>(false)
  // file category
  const [fileCategoryPrice, setFileCategoryPrice] = useState<number>(0)
  const [fileCategories, setFileCategories] = useState<FileCategoryModel[]>([])
  const [fileCategorySearch, setFileCategorySearch] = useState('')
  const [fileCategoryMenuOpen, setFileCategoryMenuOpen] = useState(false)
  const [fileCategoryHasNextPage, setFileCategoryHasNextPage] = useState(false)
  const [fileCategoryOptions, setFileCategoryOptions] = useState([InitialSelectOptionData,])
  const [fileCategoryPage, setFileCategoryPage] = useState(1)
  const [fileCategoryValue, setFileCategoryValue] = useState(InitialSelectOptionData)
  // period
  const [startDate, setStartDate] = useState('')

  const validationScheme = Yup.object().shape({})

  const formik = useFormik({
    initialValues: InitialDownloadFileData,
    validationSchema: validationScheme,
    onSubmit: (values, {setSubmitting}) =>{
      setTimeout(()=>{
        downloadFileSave(values).then(response =>{
          setSubmitting(false)
          const msg: Notify = {
            title: intl.formatMessage({id: 'File create'}),
            message: intl.formatMessage({id: 'Error'}),
            danger: true,
          }
          if ([200, 201].includes(response.status)) {
            msg.danger = false
            msg.message = intl.formatMessage({id: 'Success'})
            navigate(`/dashboard/choice-pay-method/file-download/${response.data.id}`)
          }
          setNotifyPush(msg)
        })
      }, 1000)
    }
  })

  const loadFileCategoryOptions = () => {
    if (fileCategoryMenuOpen && fileCategoryHasNextPage) {
      const filter = {page: fileCategoryPage, search: fileCategorySearch} as IDataFilter
      if (!isCalc) {
        filter.except = ['calc']
      }
      fileCategoryList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setFileCategoryHasNextPage(fileCategoryPage <= totalPages)
          if ((fileCategoryPage === 1 || totalItems > fileCategoryOptions.length) && response.data.items) {
            const newItems = [
              ...(fileCategoryPage > 1 ? fileCategoryOptions : []),
              ...response.data.items.map((e: any)=>{
                return {value: e.id, label: `${intl.formatMessage({id: CapFirstLet(e.period)})} - ${e.price}`, color: ''}
              })
            ]
            setFileCategories(response.data.items.map((e: any)=>{
              return {id: e.id, price: e.price, period: e.period} as FileCategoryModel
            }))

            setFileCategoryOptions(newItems)
          }
        }
      })
    }
  }

  const loadFileCategoryInit = () => {
    const period = isCalc ? 'calc' : formik.values.periodName
    fileCategoryOne({period}).then(response=>{
      if (response.status === 200) {
        const data = response.data
        formik.setFieldValue('fileCategoryId', data.id)
        setFileCategoryValue({value: data.id, label: `${intl.formatMessage({id: CapFirstLet(data.period)})} - ${data.price}`, color: ''} as SelectOptionModel)
        setFileCategoryPrice(data.price)
      }
    })
  }

  const loadData = () =>  {
    loadFileCategoryInit()
  }

  const loadDate = () => {
    const end = formik.values.periodEnd.length === 10 ? formik.values.periodEnd : undefined
    setStartDate(PeriodToDate(formik.values.periodName, end))
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [])

  // eslint-disable-next-line
  useEffect(() => loadDate(),[formik.values.periodName, formik.values.periodEnd])

  // eslint-disable-next-line
  useEffect(() => loadFileCategoryOptions(), [fileCategoryMenuOpen, fileCategoryPage, fileCategorySearch])

  // eslint-disable-next-line
  useEffect(()=>{
    if (['day', 'calc'].includes(formik.values.periodName)) {
      if ((formik.values.fileProducts && formik.values.fileProducts.length > 0)
        && (formik.values.bazaarIds && formik.values.bazaarIds.length > 0)) {
        setCanPay(true)
      } else {
        setCanPay(false)
      }
    } else {
      setCanPay(formik.values.periodEnd.length === 10)
    }
  }, [formik.values.periodName, formik.values.fileProducts, formik.values.bazaarIds])

  // eslint-disable-next-line
  useEffect(()=>{
    const find = fileCategories.find(e => e.id === fileCategoryValue.value)
    if (find) {
      formik.setFieldValue('periodName', find.period)
      setFileCategoryPrice(find.price)
    }
  }, [fileCategoryValue, formik.values.bazaarIds])

  // eslint-disable-next-line
  useEffect(()=>{
    if (['day', 'calc'].includes(formik.values.periodName)
      && formik.values.bazaarIds && formik.values.bazaarIds.length > 0
      && formik.values.fileProducts && formik.values.fileProducts.length > 0
      && startDate.length === 10
      && formik.values.periodEnd.length === 10
    ) {
      const filter: IDataFilter = {
        ids: formik.values.bazaarIds,
        fileProducts: formik.values.fileProducts,
        periodStart: startDate,
        periodEnd: formik.values.periodEnd,
      }
      downloadFileCheckBazaarsProducts(filter).then(r=>{
        if (r.status === 200) {
          const items = (r.data ?? []).map((e:any)=>{
            return {
              id: r.data.id,
              products: (e.products ?? []).map((p: any)=>{
                return {id: p.id, typeId: p.type_id}
              })
            }
          })

          if (formik.values.fileProducts) {
            const products = formik.values.fileProducts
            for (const product of products) {
              product.has = false
              for (const item of items) {
                for (const e of item.products) {
                  if (product.id === e.id && product.typeId === e.typeId) {
                    product.has = true
                    break
                  }
                }
              }
            }

            formik.setFieldValue('fileProducts', products)
          }
        }
      })
    }
  }, [formik.values.bazaarIds])

  return <>
    <div className="row">
      <div className="col-lg-12">
        {/* Ls widget */}
        <div className="ls-widget">
          <div className="tabs-box">
            <div className="widget-title">
              <h4>{intl.formatMessage({id: isCalc ? 'Bazaar calc' : 'File download'})}</h4>
            </div>

            {/* begin::Form */}
            <form onSubmit={formik.handleSubmit} className='form p-3'>
              {!isCalc ? <>
                {/* begin::SelectCategory */}
                <div className='row mb-10'>
                  <label className='col-lg-3 col-form-label text-lg-end'>
                    {intl.formatMessage({id: 'Category'})}:
                  </label>
                  <div className='col-lg-3 col-xl-3 px-3'>
                    <Select
                      className='basic-single'
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      value={fileCategoryValue}
                      menuIsOpen={fileCategoryMenuOpen}
                      onChange={(s)=>{
                        if (!s) { s = InitialSelectOptionData }
                        setFileCategoryValue(s)
                        formik.setFieldValue('fileCategoryId', s.value)
                        formik.setFieldValue('bazaarIds', [])
                      }}
                      onMenuScrollToBottom={()=>{
                        setFileCategoryPage(1 + fileCategoryPage)
                        loadFileCategoryOptions()
                      }}
                      onInputChange={(s, m)=> {
                        if (m.action === "input-change"){
                          setFileCategorySearch(s)
                        }
                      }}
                      onMenuClose={()=>setFileCategoryMenuOpen(false)}
                      onMenuOpen={()=>{
                        setFileCategoryMenuOpen(true)
                        setFileCategoryHasNextPage(true)
                        setFileCategoryOptions([])
                        setFileCategoryPage(1)
                        setFileCategorySearch('')
                      }}
                      options={fileCategoryOptions}
                    />
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                </div>
                {/* end::SelectCategory */}
                </> : null}
              {/* begin::Body */}
              <div className='card-body'>
                <div className='tab-content pt-3'>
                  {['day', 'calc'].includes(formik.values.periodName) ? <>
                    <DownloadFileDayForm
                      id={formik.values.id}
                      periodStart={startDate}
                      periodEnd={formik.values.periodEnd}
                      fileProducts={formik.values.fileProducts}
                      setFileProducts={v=>formik.setFieldValue('fileProducts', v)}
                      setBazaarIds={v=>formik.setFieldValue('bazaarIds', v)}
                      withQuantity={isCalc ?? false}
                    />
                  </> : <>
                    <DownloadFileBazaarsSelect
                      setIds={v=>formik.setFieldValue('bazaarIds', v)}
                      periodStart={startDate}
                      periodEnd={formik.values.periodEnd}
                      fileProducts={formik.values.fileProducts}
                    />
                    {/* begin::ChoiceDate */}
                    <div className='row mb-10'>
                      <label className='col-lg-3 col-form-label text-lg-end'>
                        {intl.formatMessage({id: 'Date'})}:
                      </label>
                      <div className='col-lg-9 col-xl-9'>
                        <div className="row">
                          <div className="col-6">
                            <div className="row ">
                              <div className="col-lg-3 col-12">
                                <label className='col-form-label text-end'>
                                  {intl.formatMessage({id: 'Start'})}:
                                </label>
                              </div>
                              <div className="col-lg-9 col-12">
                                <label className='h-40px text-center form-control border-0 form-control-solid '>
                                  {DateFormat(startDate)}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row ">
                              <div className="col-lg-3 col-12">
                                <label className='col-form-label text-end'>
                                  {intl.formatMessage({id: 'End'})}:
                                </label>
                              </div>
                              <div className="col-lg-9 col-12">
                                <input
                                  style={{height: 40}}
                                  className={clsx('text-center form-control form-control-solid ',
                                    {'is-invalid': formik.touched.periodEnd && formik.errors.periodEnd},
                                    {'is-valid': formik.touched.periodEnd && !formik.errors.periodEnd}
                                  )}
                                  type='date'
                                  {...formik.getFieldProps('periodEnd')}
                                />
                                {formik.touched.periodEnd && formik.errors.periodEnd && (
                                  <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.periodEnd}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='form-text text-muted'>
                          {intl.formatMessage({id: 'Required'})}
                        </div>
                      </div>
                    </div>
                    {/* end::ChoiceDate */}
                  </>}
                </div>
              </div>
              {/* end::Body */}
              {/* begin::Footer */}
              <div className='card-footer py-6'>
                <div className='row'>
                  <div className='col-lg-3'></div>
                  <div className='col-lg-9'>
                    <div className="col-6 col-lg-3">
                      <button
                        type='submit'
                        className='btn btn-success me-2 w-100'
                        disabled={!canPay}
                      >
                        {showLoading ? <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'Please wait'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span> : <span className='indicator-label'>
                          {NumberFormat(formik.values.bazaarIds ? formik.values.bazaarIds.length * fileCategoryPrice : 0)} {intl.formatMessage({id: 'CurUz'}).toLowerCase()} {intl.formatMessage({id: 'Pay'}).toLowerCase()}
                        </span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Footer */}
            </form>
            {/* end::Form */}
          </div>
        </div>
      </div>
    </div>
  </>
}
export default DownloadFileAdd