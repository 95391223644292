import React from 'react'
import {Route, Routes} from 'react-router-dom'
import SellerAdsList from './componenets/SellerAdsList'
import SellerAdsDetailPage from './SellerAdsDetailPage'

const SellerAdsPage: React.FC = () => {

  return <Routes>
      <Route index element={<SellerAdsList/>}/>
      <Route path={'/list'} element={<SellerAdsList/>}/>
      <Route path={'/detail/:id'} element={<SellerAdsDetailPage/>}/>
  </Routes>
}
export default SellerAdsPage