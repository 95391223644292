import React, {useEffect, useState} from "react";
import DownloadFileProductItem from "./DownloadFileProductItem";
import { useIntl } from "react-intl";
import {FileProduct, InitialFileProduct} from "../models/DownloadFileModel";
import DownloadFileBazaarsSelect from "./DownloadFileBazaarsSelect";

type Props = {
  id: string
  periodStart: string,
  periodEnd: string,
  fileProducts?: FileProduct[]
  setFileProducts(v: FileProduct[]): void
  setBazaarIds(v: string[]): void
  withQuantity: boolean
}

const DownloadFileDayForm: React.FC<Props> = ({
  id,
  periodStart,
  periodEnd,
  fileProducts,
  setFileProducts,
  setBazaarIds,
  withQuantity
}) => {
  const intl = useIntl()
  const [showSelectBazaars, setShowSelectBazaars] = useState(false)
  const [allowAdd, setAllowAdd] = useState(false)
  const [products, setProducts] = useState<FileProduct[]>([])

  const fileProductCallback = (i: number, v: FileProduct) => {
    const items = [...products]
    items[i] = v
    setProducts(items)
    setFileProducts(items.filter(e=>e.id.length === 36 && e.typeId.length === 36 && e.quantity > 0))
  }

  // eslint-disable-next-line
  useEffect(()=>setProducts(fileProducts ?? []), [])

  return <>
    {/* begin::AddProducts */}
    <div className='row mb-10 justify-content-center'>
      <label className='col-lg-3 col-form-label text-lg-end'>
        {intl.formatMessage({id: 'Products'})}:
      </label>
      <div className="col-9 mb-4">
        {products.map((e, i) => <DownloadFileProductItem
          key={e.id.length === 0 ? i : e.id}
          index={i}
          items={products}
          readonly={showSelectBazaars}
          setItem={fileProductCallback}
          setAllowAdd={setAllowAdd}
          withQuantity={withQuantity}
        />)}
        {((allowAdd && !showSelectBazaars) || products.length === 0) ? <div className='col-8 col-lg-3'>
          <button
            type='button'
            className='btn btn-info w-100'
            onClick={_ => setProducts([...products ?? [], InitialFileProduct])}
          >
            <i className="fa fa-add"></i>
          </button>
        </div> : null}
      </div>
    </div>
    {/* end::AddProducts */}
    {/* begin::AddBazaarsButton */}
    <div className="row mb-10 justify-content-center">
      <div className='col-lg-3 col-form-label text-lg-end'></div>
      <div className="col-9">
        <div className="col-8 col-lg-3">
          <button
            type='button'
            className='btn btn-success w-100 mb-4'
            onClick={_ => setShowSelectBazaars(!showSelectBazaars)}
            disabled={products.filter(e=>e.id.length === 36).length === 0}
          >
            {intl.formatMessage({id: showSelectBazaars ? 'Edit products' : 'Confirm products'})}
          </button>
        </div>
      </div>
    </div>
    {/* end::AddBazaarsButton */}
    {showSelectBazaars ? <>
      {/* begin::SelectBazaars */}
      <DownloadFileBazaarsSelect
        fileId={id}
        setIds={setBazaarIds}
        periodStart={periodStart}
        periodEnd={periodEnd}
        fileProducts={fileProducts}
      />
      {/* end::SelectBazaars */}
    </> : null}
  </>
}
export default DownloadFileDayForm