export interface CityModel {
  id: string
  name: string
  slug: string
}

export interface CityDataModel {
  id: string
  nameUz: string
  nameOz: string
  nameRu: string
  slugUz: string
  slugOz: string
  slugRu: string
}

export const InitialCity:CityModel = {
  id: "",
  name: "",
  slug: "",
}

export const InitialCityData:CityDataModel = {
  id: '',
  nameUz: '',
  nameOz: '',
  nameRu: '',
  slugUz: '',
  slugOz: '',
  slugRu: '',
}