import React, {useEffect, useState} from "react"
import {useIntl} from "react-intl";
import {ProductModel} from "../models/ProductModel";
import IListData, {InitialIListData} from "../../global/models/ListModel";
import {productList} from "../redux/productCRUD";
import {CityModel} from "../../cities/models/CityModel";
import {DistrictModel} from "../../districts/models/DistrictModel";
import {ProductTypeModel} from "../../product-types/models/ProductTypeModel";
import {SubscriberModel} from "../../subscribers/models/SubscriberModel";
import {CategoryModel} from "../../categories/models/CategoryModel";
import {IDataFilter} from "../../global/models/DataFilter";
import {UserModel} from "../../subscribers/models/UserModel";
import {DateFormat} from "../../global/helpers/DateHelpers";
import {Link} from "react-router-dom";
import {GlobalProductModel} from "../../global-products/models/GlobalProductModel";
import {NumberFormat} from "../../global/helpers/ToHumansString";
import {CapFirstLet} from "../../global/helpers/StringHelper";
import {useModal} from "../../../AppContext";
import TablePaginate from "../../global/components/TablePaginate";

type Props = {
  offer: boolean
}

const ProductAdsTable: React.FC<Props> = ({offer}) => {
  const intl = useIntl()
  const {setModal} = useModal()

  const [filter, setFilter] = useState({} as IDataFilter)
  const [listData, setListData] = useState<IListData<ProductModel>>(InitialIListData)

  const loadData = () => {
    filter.offer = offer
    productList(filter).then(response => {
      let result: IListData<ProductModel>  = {totalItems: 0, page: 0, totalPages: 0, items: []}
      if (response.status === 200) {
        result.items = response.data.items ? response.data.items.map((e: any) => {
          const m: ProductModel = {
            id: e.id,
            offer: e.offer === 'true',
            status: e.status,
            slug: e.slug,
            price: e.price,
            home: e.home,
            phone: e.phone,
            payMethod: e.pay_method,
            payAmount: e.pay_amount,
            payTime: e.pay_time,
            quantity: e.quantity,
            description: e.description,
            message: e.message,
            lon: e.lon,
            lat: e.lat,
            metaKeys: e.metaKeys,
            metaDesc: e.metaDesc,
            createdAt: e.created_at,
            updatedAt: e.updated_at,
            product: {
              id: e.product.id,
              name: e.product.name,
              slug: e.product.slug,
              category: {
                id: e.product.category.id,
                title: e.product.category.title,
                slug: e.product.category.slug
              } as CategoryModel
            } as GlobalProductModel,
            district: {
              id: e.district.id,
              name: e.district.name,
              slug: e.district.slug,
              city: {
                id: e.district.city.id,
                name: e.district.city.name,
                slug: e.district.city.slug
              } as CityModel
            } as DistrictModel,
            subscriber: {
              id: e.subscriber.id,
              balance: e.subscriber.balance,
              birth: e.subscriber.birth,
              user: {
                id: e.subscriber.user.id,
                fullName: e.subscriber.user.full_name,
                phone: e.subscriber.user.phone,
              } as UserModel
            } as SubscriberModel,
            type: {
              id: e.product_type.id,
              name: e.product_type.name,
              slug: e.product_type.slug,
            } as ProductTypeModel,
          }

          return m
        }) : []

        result.totalItems = response.data.paginate.total_items
        result.page = response.data.paginate.page
        result.totalPages = response.data.paginate.total_pages

        setListData(result)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [filter, offer])
  
  return <div className="row">
    <div className="col-lg-12">
      {/* Ls widget */}
      <div className="ls-widget">
        <div className="tabs-box">
          <div className="widget-title">
            <h4>{intl.formatMessage({id: `${offer ? 'Seller' : 'Buyer'} advertisements`})}</h4>

            <div className="chosen-outer">
              <Link
                className='btn btn-primary mx-2'
                to={`/dashboard/product-${offer ? 'seller' : 'buyer'}-ads/add`}
              >
                {intl.formatMessage({id: 'Add'})}
              </Link>
            </div>
          </div>

          <div className="widget-content">
            <div className="table-outer">
              <table className="default-table manage-job-table">
                <thead>
                  <tr>
                    <th>{intl.formatMessage({id: 'Product'})}</th>
                    <th>{intl.formatMessage({id: 'Status'})}</th>
                    <th>{intl.formatMessage({id: 'Type'})}</th>
                    <th>{intl.formatMessage({id: 'Price'})}</th>
                    <th>{intl.formatMessage({id: 'Time'})}</th>
                    <th>{intl.formatMessage({id: 'Action'})}</th>
                  </tr>
                </thead>

                <tbody>
                  {listData.items.map(e=><tr key={e.id}>
                    <td>
                      <h6>{e.product.name}</h6>
                      <span className="info"><i className="icon flaticon-map-locator"></i> {`${e.district.city.name}, ${e.district.name}`}</span>
                    </td>
                    <td>{intl.formatMessage({id: CapFirstLet(e.status) ?? 'Consideration'})}</td>
                    <td>{intl.formatMessage({id: e.offer ? 'Sale' : 'Need'})}</td>
                    <td>{NumberFormat(e.price)}</td>
                    <td>{DateFormat(e.updatedAt)}</td>
                    <td>
                      <div className="option-box">
                        <ul className="option-list">
                          {e.status === 'paying' ? <>
                            {/*<li>*/}
                            {/*  <button*/}
                            {/*    type='button'*/}
                            {/*    data-text={intl.formatMessage({id: 'Paying'})}*/}
                            {/*    onClick={_=>payedHandler(e.id)}*/}
                            {/*  >*/}
                            {/*    <span className="fa fa-credit-card"></span>*/}
                            {/*  </button>*/}
                            {/*</li>*/}
                          </> : null}
                          <li>
                            <Link to={`/dashboard/product-${offer ? 'seller' : 'buyer'}-ads/edit/${e.id}`} data-text={intl.formatMessage({id: 'Edit'})}>
                              <span className="fa fa-edit"></span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>)}
                </tbody>
              </table>
              {/* begin::Paginate */}
              <TablePaginate
                total={listData.items.length}
                pageCount={listData.totalPages}
                currentPage={listData.page}
                setPage={page=>setFilter({...filter, page})}
              />
              {/* end::Paginate */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default ProductAdsTable