import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {InitialSelectOptionData, SelectOptionModel} from '../models/SelectOptionModel'
import {useIntl} from 'react-intl'
import {cityGet, cityList} from '../../cities/redux/cityCRUD'
import {districtGet, districtList} from '../../districts/redux/districtCRUD'
import {IDataFilter} from '../models/DataFilter'

type Props = {
    cityId: string
    setCityId(v: string): void
    districtId: string
    setDistrictId(v: string): void
}
const ListFilterLocationSelect: React.FC<Props> = ({cityId, setCityId, districtId, setDistrictId}) => {
  const intl = useIntl()

  // city
  const [citySearch, setCitySearch] = useState('')
  const [cityMenuOpen, setCityMenuOpen] = useState(false)
  const [cityHasNextPage, setCityHasNextPage] = useState(false)
  const [cityOptions, setCityOptions] = useState([InitialSelectOptionData,])
  const [cityPage, setCityPage] = useState(1)
  const [cityValue, setCityValue] = useState<SelectOptionModel>(InitialSelectOptionData)
  // district
  const [districtSearch, setDistrictSearch] = useState('')
  const [districtMenuOpen, setDistrictMenuOpen] = useState(false)
  const [districtHasNextPage, setDistrictHasNextPage] = useState(false)
  const [districtOptions, setDistrictOptions] = useState<SelectOptionModel[]>([])
  const [districtPage, setDistrictPage] = useState(1)
  const [districtValue, setDistrictValue] = useState<SelectOptionModel>(InitialSelectOptionData)

  const loadCity = () => {
    if (cityId.length === 36) {
      cityGet(cityId, intl.locale).then(response=>{
        if (response.status === 200) {
          setCityValue({value: response.data.id, label: response.data.name, color: ''})
        }
      })
    }
  }

  const loadDistrict = () => {
    if (districtId.length === 36) {
      districtGet(districtId, intl.locale).then(response=>{
        if (response.status === 200) {
          setDistrictValue({value: response.data.id, label: response.data.name, color: ''})
        }
      })
    }
  }

  const loadCityOptions = () => {
    if (cityMenuOpen && cityHasNextPage) {
      const filter = {'page': cityPage, search: citySearch, lan: intl.locale} as IDataFilter
      cityList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setCityHasNextPage(cityPage <= totalPages)
          if ((cityPage === 1 || totalItems > cityOptions.length) && response.data.items) {
            const newItems = [
              ...(cityPage > 1 ? cityOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setCityOptions(newItems)
          }
        }
      })
    }
  }

  const loadDistrictOptions = () => {
    if (districtMenuOpen && districtHasNextPage) {
      const filter: IDataFilter = {'page': districtPage, search: districtSearch, lan: intl.locale, cityId: cityValue.value}
      districtList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setDistrictHasNextPage(districtPage <= totalPages)
          if ((districtPage === 1 || totalItems > districtOptions.length) && response.data.items) {
            const newItems = [
              ...(districtPage > 1 ? districtOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setDistrictOptions(newItems)
          }
        }
      })
    }
  }

  const loadData = () => {
    loadCity()
    loadDistrict()
  }

  // eslint-disable-next-line
  useEffect(()=>loadCityOptions(), [cityMenuOpen, cityPage, citySearch])

  // eslint-disable-next-line
  useEffect(()=>loadDistrictOptions(), [districtMenuOpen, districtPage, districtSearch, cityOptions])

  // eslint-disable-next-line
  useEffect(()=> loadData(), [cityId, districtId])

  return <>
    {/* Filter by city */}
    <div className="filter-block">
      <h4>{intl.formatMessage({id: 'City'})}</h4>
      <div className="form-group">
        <Select
          className='basic-single'
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          value={cityValue}
          menuIsOpen={cityMenuOpen}
          onChange={(s)=>{
            if (!s) { s = InitialSelectOptionData }
            setCityValue(s)
            setCityId(s.value)
            setDistrictId('')
            setDistrictOptions([])
            setDistrictValue(InitialSelectOptionData)
          }}
          onMenuScrollToBottom={()=>{
            setCityPage(1 + cityPage)
          }}
          onInputChange={(s, m)=> {
            if (m.action === "input-change"){
              setCitySearch(s)
            }
          }}
          onMenuClose={()=>setCityMenuOpen(false)}
          onMenuOpen={()=>{
            setCityMenuOpen(true)
            setCityHasNextPage(true)
            setCityPage(1)
            setCitySearch('')
          }}
          options={cityOptions}
          styles={{
            control: (provided: any) => ({
              ...provided,
              flexWrap: 'nowrap',
              height: '60px',
            })
          }}
        />
        {/*<span className="icon flaticon-map-locator"></span>*/}
      </div>
    </div>

    {/* Filter by district */}
    <div className="filter-block">
      <h4>{intl.formatMessage({id: 'District'})}</h4>
      <div className="form-group">
        <Select
          className='basic-single'
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          value={districtValue}
          menuIsOpen={districtMenuOpen}
          onChange={(s)=>{
            if (!s) { s = InitialSelectOptionData }
            setDistrictValue(s)
            setDistrictId(s.value)
          }}
          onMenuScrollToBottom={()=>{
            setDistrictPage(1 + districtPage)
          }}
          onInputChange={(s, m)=> {
            if (m.action === "input-change"){
              setDistrictSearch(s)
            }
          }}
          onMenuClose={()=>setDistrictMenuOpen(false)}
          onMenuOpen={()=>{
            setDistrictMenuOpen(true)
            setDistrictHasNextPage(true)
            setDistrictPage(1)
            setDistrictSearch('')
          }}
          options={districtOptions}
          styles={{
            control: (provided: any) => ({
              ...provided,
              flexWrap: 'nowrap',
              height: '60px',
            })
          }}
        />
        {/*<span className="icon flaticon-map-locator"></span>*/}
      </div>
    </div>
  </>
}
export default ListFilterLocationSelect