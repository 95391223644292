import {DistrictModel, InitialDistrict} from '../../districts/models/DistrictModel'

export type DeliveryAdsModel = {
  id: string
  vehicle: string
  weight: number
  disDate: string
  desDate: string
  phone: string
  description?: string
  offer?: boolean
  createdAt: string
  updatedAt: string
  curDistrict: DistrictModel
  desDistrict: DistrictModel
}

export const InitialDeliveryAds: DeliveryAdsModel = {
  id: '',
  vehicle: '',
  weight: 0,
  disDate: '',
  desDate: '',
  phone: '',
  createdAt: '',
  updatedAt: '',
  curDistrict: InitialDistrict,
  desDistrict: InitialDistrict,
}