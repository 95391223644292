import React from "react"
import ProductAdsForm from "./componenets/ProductAdsForm";
import {useParams} from "react-router-dom";

type Props = {
  offer: boolean
}

const ProductAdsFormPage: React.FC<Props> = ({offer}) => {
  const {id} = useParams<{id: string}>()

  return <ProductAdsForm id={id} offer={offer}/>
}
export default ProductAdsFormPage;