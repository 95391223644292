export type UserInitModel = {
  fullName: string
  phone: string
  password: string
  districtId: string
  birth: string
}

export const InitUserInit: UserInitModel = {
  fullName: '',
  phone: '',
  password: '',
  districtId: '',
  birth: '',
}