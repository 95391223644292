import React from 'react'
import {useIntl} from 'react-intl'
import {NumberFormat} from '../helpers/ToHumansString'

type Props = {
  on: number
  to: number
  total: number
}
const ListViewInfo: React.FC<Props> = ({on, to, total}) => {
  const intl = useIntl()

  return <>
    {intl.formatMessage({id: 'Showing'})} <strong>{NumberFormat(on)} - {NumberFormat(to)}</strong> {intl.formatMessage({id: 'to'})} <strong>{NumberFormat(total)}</strong> {intl.formatMessage({id: 'in total'})}
  </>
}
export default ListViewInfo