import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {NumberFormat} from "../../global/helpers/ToHumansString";
import {Link, useNavigate} from "react-router-dom";
import {Truncate} from "../../global/helpers/StringHelper";
import {bazaars} from "../redux/bazaarCRUD";
import ImageView from '../../global/components/ImageView'
import {useLocationData} from '../../../layouts/core/HomeData'
import {BazaarModel} from '../models/BazaarModel'


const Bazaars: React.FC = () => {
  const intl = useIntl()
  // const navigate = useNavigate()
  const {districtId} = useLocationData()

  const [actualPriceCount, setActualPriceCount] = useState(0)
  const [list, setList] = useState<BazaarModel[]>([])

  const loadData = () => {
    bazaars({lan: intl.locale, perPage: 6, districtId: districtId}).then(response=>{
      if (response.status === 200) {
        const list = response.data.items.map((e: any)=>{
          const m: BazaarModel = {
            id: e.id,
            name: e.name,
            location: `${e.district.city.name}, ${e.district.name}`,
            imageId: e.image_id,
            actualPriceCount: e.actual_price_count,
            minPrice: e.min_price,
            maxPrice: e.max_price,
            link: `/bazaar/${e.id}`,
            mon: (e.mon ?? '') === 'true',
            tue: (e.tue ?? '') === 'true',
            wed: (e.wed ?? '') === 'true',
            thu: (e.thu ?? '') === 'true',
            fri: (e.fri ?? '') === 'true',
            sat: (e.sat ?? '') === 'true',
            sun: (e.sun ?? '') === 'true',
          }
          return m
        })
        setList(list)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [districtId])

  return <section className="job-categories at-home18" id='bazaars-section'>
    <div className="auto-container">
      <div className="d-sm-flex align-items-center justify-content-sm-between wow fadeInUp mb-4 mb-sm-0">
        <div className="sec-title">
          <h2>{intl.formatMessage({id: 'Bazaars'})}</h2>
          <div className="text">{intl.formatMessage({id: 'actual price count'}, {count: NumberFormat(actualPriceCount)})}</div>
        </div>
        <Link to={'/bazaar'} className="ud-btn-border-theme at-home18 mb-4 dark-style">
          {intl.formatMessage({id: 'View all'})} <i className="fal fa-long-arrow-right"></i>
        </Link>
      </div>
      <div className="row wow fadeInUp">
        {list.map(e=><div
          key={e.id}
          className="col-xl-4 col-sm-6"
          style={{cursor: 'pointer'}}
          // onClick={_=>navigate(e.link)}
        >
          <div className="job-block at-jlv17 at-home20 at-home18">
            <div className="inner-box">
              <div className="content ps-0">
                <h4 className="fz22 mb-2" style={{height: 60}}>
                  <span className='hover-text-white'>{Truncate(e.name, 50)}</span>
                </h4>
                <ul className="job-other-info at-jsv6 at-jsv17 mt15 ms-0">
                  <li className="time2 fz15 border-0 ps-0 me-0 mb-0">
                    <i className="flaticon-map-locator pe-2"></i>{Truncate(e.location, 150)}
                  </li>
                </ul>
                <ul className="job-other-info at-jsv6 at-jsv17 mt-0 ms-0 mb-5">
                  <li className="time2 fz15 border-0 ps-0 me-0 mb-0">
                    <i className="flaticon-money-2 pe-2"></i>{`${NumberFormat(e.minPrice)} - ${NumberFormat(e.maxPrice)}`} {intl.formatMessage({id: 'Min and max prices'})}
                  </li>
                </ul>
                <div className="d-md-flex align-items-center justify-content-md-between mt30">
                  <div className="d-flex align-items-center mb-3 mb-md-0">
                    <span className="company-logo position-relative mr15">
                      <ImageView id={e.imageId} screen={'small'} className={"wa w60 rounded-circle"}/>
                    </span>
                    <div className="text-start">
                      <h5 className="fz18 fw500">{intl.formatMessage({id: 'Work days'})}</h5>
                      <p className="text">
                        {e.mon ? intl.formatMessage({id: 'Mon'}) : null}
                        {e.tue ? `, ${intl.formatMessage({id: 'Mon'})}` : null}
                        {e.wed ? `, ${intl.formatMessage({id: 'Wed'})}` : null}
                        {e.thu ? `, ${intl.formatMessage({id: 'Thu'})}` : null}
                        {e.fri ? `, ${intl.formatMessage({id: 'Fri'})}` : null}
                        {e.sat ? `, ${intl.formatMessage({id: 'Sat'})}` : null}
                        {e.sun ? `, ${intl.formatMessage({id: 'Sun'})}` : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
    </div>
  </section>
}

export default Bazaars