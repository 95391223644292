import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  pers?: number[]
  setPer(v: number): void
}

const ListPerPageSelect: React.FC<Props> = ({pers, setPer}) => {
  const intl = useIntl()

  return <select
    className="chosen-select form-control"
    onChange={({target})=>setPer(Number(target.value))}
  >
      {[10, 50, 100, 500].map(e=><option key={e} value={e}>{intl.formatMessage({id: 'Show'})} {e}</option>)}
  </select>
}
export default ListPerPageSelect