import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ReactPaginate from "react-paginate"
import { Link } from "react-router-dom";
import { InputGroup } from "react-bootstrap-v5";
import { downloadFile, downloadFileDelete, downloadFileList } from "../redux/downloadFileCRUD";
import { DownloadFileModel } from "../models/DownloadFileModel"
import {Me} from "../../global/helpers/UserHelpers";
import {Notify, usePageData} from "../../../layouts/core/PageData";
import {IDataFilter} from "../../global/models/DataFilter";
import IListData, {InitialIListData} from "../../global/models/ListModel";
import {DateFormat, PeriodToDate} from "../../global/helpers/DateHelpers";
import SVG from "react-inlinesvg";
import TablePaginate from "../../global/components/TablePaginate";
import {FileCategoryModel} from "../models/FileCategoryModel";
import {CapFirstLet} from "../../global/helpers/StringHelper";
import {NumberFormat} from "../../global/helpers/ToHumansString";
import {deliveryDelete} from "../../delivery-ads/redux/deliveryCRUD";
import ModalConfirmDelete from "../../global/components/ModalConfirmDelete";
import {useModal} from "../../../AppContext";

const DownloadFileTable: React.FC = () => {
  const user = Me()
  const intl = useIntl()
  const {setNotifyPush} = usePageData()
  const {setModal} = useModal()

  const [filter, setFilter] = useState({} as IDataFilter)
  const [listData, setListData] = useState<IListData<DownloadFileModel>>(InitialIListData)
  const [deleteId, setDeleteId] = useState('')

  // const deleteHandler = () => {
  //   downloadFileDelete(checkedIds).then(response => {
  //     if (response.status === 200) {
  //       const msg: Notify = {
  //         title: intl.formatMessage({id: 'DELETE'}),
  //         message: intl.formatMessage({id: 'ERROR'}),
  //         danger: true,
  //       }
  //       if (response.status === 200) {
  //         msg.danger = false
  //         msg.message = intl.formatMessage({id: 'SUCCESS'})
  //         loadData()
  //       }
  //       setNotifyPush(msg)
  //     }
  //   })
  // }

  const deleteCallback = () => {
    if (deleteId.length === 36) {
      downloadFileDelete([deleteId]).then(response => {
        const msg: Notify = {
          title: intl.formatMessage({id: 'Delete'}),
          message: intl.formatMessage({id: 'Error'}),
          danger: true,
        }
        if (response.status === 200) {
          msg.danger = false
          msg.message = intl.formatMessage({id: 'Success'})
          loadData()
        }
        setNotifyPush(msg)
      })
    }
  }

  const deleteHandler = () => {
    if (deleteId.length === 36) {
      setModal(<ModalConfirmDelete callback={deleteCallback}/>)
    } else {
      setModal(null)
    }
  }

  const loadData = () => {
    downloadFileList(filter).then(response => {
      let result: IListData<DownloadFileModel>  = {totalItems: 0, page: 0, totalPages: 0, items: []}
      if (response.status === 200) {
        result.items = response.data.items ? response.data.items.map((e: any) => {
          return {
            id: e.id,
            price: e.price,
            periodEnd: e.period_end,
            bazaarIds: e.bazaars,
            fileProducts: e.fileProducts,
            createdAt: e.created_at,
            updatedAt: e.updated_at,
            fileCategory: {
              id: e.file_category.id,
              price: e.file_category.price,
              period: e.file_category.period,
            } as FileCategoryModel
          } as DownloadFileModel
        }) : []
        result.totalItems = response.data.paginate.total_items
        result.page = response.data.paginate.page
        result.totalPages = response.data.paginate.total_pages

        setListData(result)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [filter])

  // eslint-disable-next-line
  useEffect(() => deleteHandler(), [deleteId])

  return <div className="row">
    <div className="col-lg-12">
      {/* Ls widget */}
      <div className="ls-widget">
        <div className="tabs-box">
          <div className="widget-title">
            <h4>{intl.formatMessage({id: 'Download files'})}</h4>

            <div className="chosen-outer">
              <Link
                className='btn btn-success mx-2'
                to={'/dashboard/file-download/add'}
              >
                {intl.formatMessage({id: 'New file download'})}
              </Link>
              <Link
                className='btn btn-secondary mx-2'
                to={'/dashboard/bazaar-calc'}
              >
                {intl.formatMessage({id: 'Bazaar calc'})}
              </Link>
            </div>
          </div>

          <div className="widget-content">
            <div className="table-outer">
              <table className="default-table manage-job-table">
                <thead>
                <tr>
                  <th>{intl.formatMessage({id: 'Period'})}</th>
                  <th>{intl.formatMessage({id: 'Price'})}</th>
                  <th>{intl.formatMessage({id: 'Period end'})}</th>
                  <th>{intl.formatMessage({id: 'Time'})}</th>
                  <th>{intl.formatMessage({id: 'Action'})}</th>
                </tr>
                </thead>

                <tbody>
                {listData.items.map(e=><tr key={e.id}>
                  <td className='text-muted fw-bold'>{intl.formatMessage({id: CapFirstLet(e.fileCategory.period)})}</td>
                  <td className='text-muted fw-bold'>{NumberFormat(e.price)}</td>
                  <td className='text-muted fw-bold'>{DateFormat(e.periodEnd)}</td>
                  <td className='text-muted fw-bold'>{DateFormat(e.updatedAt)}</td>
                  <td>
                    <div className="option-box">
                      <ul className="option-list">
                        <li>
                          <button
                            data-text={intl.formatMessage({id: 'Delete'})}
                            onClick={_=>setDeleteId(e.id)}
                          >
                            <span className="fa fa-trash text-red"></span>
                          </button>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/file-download/edit/${e.id}`}
                            target={'_blank'}
                            data-text={intl.formatMessage({id: 'Download'})}
                          >
                            <span className="fa fa-file-download"></span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>)}
                </tbody>
              </table>
              {/* begin::Paginate */}
              <TablePaginate
                total={listData.items.length}
                pageCount={listData.totalPages}
                currentPage={listData.page}
                setPage={page=>setFilter({...filter, page})}
              />
              {/* end::Paginate */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export {DownloadFileTable}
