import axios, { AxiosResponse } from "axios";
import * as querystring from "querystring";
import {IDataFilter} from "../../global/models/DataFilter";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'bazaar'
const LIST = `${API_URL}/${_RESOURCE}/list`
const GET = `${API_URL}/${_RESOURCE}/get`
const COUNT = `${API_URL}/${_RESOURCE}/count`
const RANDOM = `${API_URL}/${_RESOURCE}/random`

export const bazaarCount = () => axios.post(COUNT)

export const bazaarRandom = (limit: number) => axios.post(RANDOM, querystring.stringify({limit}))

export function bazaars(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    search: filter.search,
    city_id: filter.cityId,
    district_id: filter.districtId,
    bazaar_type_id: filter.bazaarTypeId,
    per: filter.perPage,
    page: filter.page,
  }))
}

export function bazaarGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}
