import axios, { AxiosResponse } from "axios"
import * as querystring from "querystring"
import {IDataFilter} from "../../global/models/DataFilter";
const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'bazaar'
const LIST = `${API_URL}/${_RESOURCE}/list`
const IMAGES = `${API_URL}/${_RESOURCE}/images`
const GET = `${API_URL}/${_RESOURCE}/get`

export function bazaarList(filter: IDataFilter) {
  const products = (filter.fileProducts ?? []).filter(e=>e.id.length === 36).map(e=>{
    return {id: e.id, type_id: e.typeId, quantity: e.quantity.toString()}
  })
  return axios.post(LIST, querystring.stringify({
    search: filter.search,
    city_id: filter.cityId,
    district_id: filter.districtId,
    bazaar_type_id: filter.bazaarTypeId,
    file_products: JSON.stringify(products),
    period_start: filter.periodStart,
    period_end: filter.periodEnd,
    per: filter.perPage,
    page: filter.page,
  }))
}

export function bazaarImages(id: string) {
  return axios.post(IMAGES, querystring.stringify({id}))
}

export function bazaarGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}