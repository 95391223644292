import { CategoryModel, InitialCategory } from "../../categories/models/CategoryModel";
import { DimensionModel, InitialDimension } from "../../dimensions/models/DimensionModel"
import {InitialProductType, ProductTypeModel} from "../../product-types/models/ProductTypeModel";
import { DistrictModel, InitialDistrict } from "../../districts/models/DistrictModel";
import { InitialSubscriber, SubscriberModel } from "../../subscribers/models/SubscriberModel";
import {GlobalProductModel, InitialGlobalProduct} from "../../global-products/models/GlobalProductModel";

export interface ProductModel {
  id: string
  offer: boolean
  status: string
  slug: string
  price: number
  home: boolean
  phone: string
  payMethod: string
  payAmount: string
  payTime: string
  quantity: number
  description: string
  message: string
  lon: number
  lat: number
  metaKeys: string
  metaDesc: string
  createdAt: string
  updatedAt: string
  product: GlobalProductModel
  district: DistrictModel
  subscriber: SubscriberModel
  type: ProductTypeModel
}

export interface ProductDataModel {
  id: string
  offer: boolean
  subscriberId: string
  productId: string
  districtId: string
  typeId: string
  status: string
  price: string
  home: boolean
  phone: string
  payMethod: string
  payAmount: string
  payTime: string
  quantity: number
  description: string
  message: string
  lon: number
  lat: number
  metaKeys: string
  metaDesc: string
  createdAt: string
  updatedAt: string
  bazaars?: string
  imageDeleteIds?: string[],
  images?: string,
  files?: Map<string, File>
}

export const InitialProduct:ProductModel = {
  id: '',
  offer: false,
  status: '',
  slug: '',
  price: 0,
  home: false,
  phone: '',
  payMethod: '',
  payAmount: '',
  payTime: '',
  quantity: 0,
  description: '',
  message: '',
  lon: 0,
  lat: 0,
  createdAt: '',
  updatedAt: '',
  metaKeys: '',
  metaDesc: '',
  product: InitialGlobalProduct,
  district: InitialDistrict,
  subscriber: InitialSubscriber,
  type: InitialProductType,
}

export const InitialProductData:ProductDataModel = {
  id: '',
  offer: false,
  subscriberId: '',
  productId: '',
  districtId: '',
  typeId: '',
  status: '',
  price: '',
  home: false,
  phone: '',
  payMethod: '',
  payAmount: '',
  payTime: '',
  quantity: 0,
  description: '',
  message: '',
  lon: 0,
  lat: 0,
  metaKeys: '',
  metaDesc: '',
  createdAt: '',
  updatedAt: '',
}