import React from "react"
import {Route, Routes} from "react-router-dom";
import {DownloadFileTable} from "./components/DownloadFileTable";
import DownloadFileAdd from "./components/DownloadFileAdd";
import DownloadFileCheckPayment from "./components/DownloadFileCheckPayment";


const DownloadFilePage: React.FC = () => {

  return <Routes>
    <Route index element={<DownloadFileTable/>}/>
    <Route path='/list' element={<DownloadFileTable/>}/>
    <Route path='/edit/:id' element={<DownloadFileCheckPayment/>}/>
    <Route path='/add' element={<DownloadFileAdd/>}/>
  </Routes>
}
export default DownloadFilePage;