import React, {useEffect, useState} from 'react'
import MainHeader from '../../global/components/MainHeader'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CityModel} from '../../cities/models/CityModel'
import {DistrictModel} from '../../districts/models/DistrictModel'
import {DateFormat} from '../../global/helpers/DateHelpers'
import {deliveryGet} from '../redux/deliveryCRUD'
import {DeliveryModel, InitialDelivery} from '../models/DeliveryModel'
import {UserModel} from '../../subscribers/models/UserModel'
import {SubscriberModel} from '../../subscribers/models/SubscriberModel'
import {deliveryAdsDetail} from '../redux/deliveryAdsCRUD'
import {DeliveryAdsModel, InitialDeliveryAds} from '../models/DeliveryAdsModel'
import ShareLinks from '../../global/components/ShareLinks'
import {NumberFormat} from '../../global/helpers/ToHumansString'

type Props = {
  id?: string
}

const SellerAdsDetail: React.FC<Props> = ({id}) => {
  const intl = useIntl()
  const [delivery, setDelivery] = useState<DeliveryAdsModel>(InitialDeliveryAds)

  const loadData = () => {
    if (id && id.length === 36) {
      deliveryAdsDetail(id, intl.locale).then(response => {
        if (response.status === 200) {
          const data = response.data
          const m: DeliveryAdsModel = {
            id: data.id,
            vehicle: data.vehicle,
            weight: data.weight,
            disDate: data.dis_date,
            desDate: data.des_date,
            phone: data.phone,
            description: data.description,
            offer: data.offer,
            createdAt: data.created_at,
            updatedAt: data.updated_at,
            curDistrict: {
              id: data.cur_district.id,
              name: data.cur_district.name,
              slug: data.cur_district.slug,
              city: {
                id: data.cur_district.city.id,
                name: data.cur_district.city.name,
                slug: data.cur_district.city.slug
              } as CityModel
            } as DistrictModel,
            desDistrict: {
              id: data.des_district.id,
              name: data.des_district.name,
              slug: data.des_district.slug,
              city: {
                id: data.des_district.city.id,
                name: data.des_district.city.name,
                slug: data.des_district.city.slug
              } as CityModel
            } as DistrictModel,
          }
          setDelivery(m)
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [id])

  return <>
    {/* Header Span */}
    <span className="header-span"></span>

    {/* Main Header*/}
    <MainHeader/>
    {/*End Main Header */}

    {/* Job Detail Section */}
    <section className="job-detail-section">
      <div className="upper-box">
        <div className="auto-container">
          <div className="btn-box">
            <Link to={`/delivery-${delivery.offer ? 'cargo' : 'goods'}-ads`} className="theme-btn btn-style-one">{intl.formatMessage({id: 'Back to list'})}</Link>
          </div>
        </div>
      </div>

      <div className="job-detail-outer">
        <div className="auto-container">
          <div className="row">
            <div className="content-column col-lg-8 col-md-12 col-sm-12">
              <div className="job-detail">
                <h4>{delivery.vehicle}</h4>
                <p>{delivery.description}</p>
              </div>
              <hr/>

              {/* Share buttons */}
              <div className="other-options">
                <div className="social-share">
                  <h5>{intl.formatMessage({id: 'Share'})}</h5>
                  <ShareLinks title={delivery.vehicle} />
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
              <aside className="sidebar">
                <div className="sidebar-widget">
                  {/* Job Overview */}
                  <h4 className="widget-title">{intl.formatMessage({id: 'Additional'})}</h4>
                  <div className="widget-content">
                    <ul className="job-overview">
                      <li>
                        <i className="icon flaticon-phone"></i>
                        <h5>{intl.formatMessage({id: 'Phone'})}:</h5>
                        <span>{delivery.phone}</span>
                      </li>
                      <li>
                        <i className="icon flaticon-layers"></i>
                        <h5>{intl.formatMessage({id: 'Weight'})}:</h5>
                        <span>{NumberFormat(delivery.weight)}</span>
                      </li>
                      <li>
                        <i className="icon flaticon-time"></i>
                        <h5>{intl.formatMessage({id: 'Dispatch date'})}:</h5>
                        <span>{DateFormat(delivery.disDate)}</span>
                      </li>
                      <li>
                        <i className="icon flaticon-map-locator"></i>
                        <h5>{intl.formatMessage({id: 'Current location'})}:</h5>
                        <span>{delivery.curDistrict.city.name}, {delivery.curDistrict.name}</span>
                      </li>
                      <li>
                        <i className="icon flaticon-map-locator"></i>
                        <h5>{intl.formatMessage({id: 'Destination location'})}:</h5>
                          <span>{delivery.desDistrict.id && delivery.desDistrict.id.length === 36 ?
                            `${delivery.desDistrict.city.name}, ${delivery.desDistrict.name}` : '-'}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* End Job Detail Section */}
  </>
}
export default SellerAdsDetail