import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {InitialSelectOptionData, SelectOptionModel} from '../../global/models/SelectOptionModel'
import ListViewInfo from '../../global/components/ListViewInfo'
import ListPerPageSelect from '../../global/components/ListPerPageSelect'
import {NumberFormat} from '../../global/helpers/ToHumansString'
import ListPaginate from '../../global/components/ListPaginate'
import {useIntl} from 'react-intl'
import {IDataFilter} from '../../global/models/DataFilter'
import {cityList} from '../../cities/redux/cityCRUD'
import {districtList} from '../../districts/redux/districtCRUD'
import {sellerAds} from '../redux/sellerAdsCRUD'
import {ProductTypeModel} from '../../product-types/models/ProductTypeModel'
import {DistrictModel} from '../../districts/models/DistrictModel'
import {CityModel} from '../../cities/models/CityModel'
import {productTypeList} from '../redux/productTypeCRUD'
import {productCategoryList} from '../redux/productCategoryCRUD'
import {SellerAdsModel} from '../models/SellerAdsModel'
import {SellerAdsProductCategoryModel} from '../models/SellerAdsProductCategoryModel'
import {SellerAdsProductDimensionModel} from '../models/SellerAdsProductDimensionModel'
import {SellerAdsProductModel} from '../models/SellerAdsProductModel'
import ListFilterLocationSelect from '../../global/components/ListFilterLocationSelect'
import {useLocationData} from '../../../layouts/core/HomeData'
import ImageView from '../../global/components/ImageView'

const SellerAdsList: React.FC = () => {
  const intl = useIntl()
  const {cityId: searchCityId, districtId: searchDistrictId} = useLocationData()

  const [search, setSearch] = useState('')
  const [cityId, setCityId] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [productTypeId, setProductTypeId] = useState('')
  const [productCategoryId, setProductCategoryId] = useState('')
  const [list, setList] = useState<SellerAdsModel[]>([])
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  // type
  const [productTypeSearch, setProductTypeSearch] = useState('')
  const [productTypeHasNextPage, setProductTypeHasNextPage] = useState(false)
  const [productTypeMenuOpen, setProductTypeMenuOpen] = useState(false)
  const [productTypeOptions, setProductTypeOptions] = useState([InitialSelectOptionData])
  const [productTypePage, setProductTypePage] = useState(1)
  const [productTypeValue, setProductTypeValue] = useState<SelectOptionModel>(InitialSelectOptionData)
  // category
  const [productCategorySearch, setProductCategorySearch] = useState('')
  const [productCategoryHasNextPage, setProductCategoryHasNextPage] = useState(false)
  const [productCategoryMenuOpen, setProductCategoryMenuOpen] = useState(false)
  const [productCategoryOptions, setProductCategoryOptions] = useState([InitialSelectOptionData])
  const [productCategoryPage, setProductCategoryPage] = useState(1)
  const [productCategoryValue, setProductCategoryValue] = useState<SelectOptionModel>(InitialSelectOptionData)

  const loadProductTypeOptions = () => {
    if (productTypeMenuOpen && productTypeHasNextPage) {
      const filter = {'page': productTypePage, search: productTypeSearch, lan: intl.locale} as IDataFilter
      productTypeList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setProductTypeHasNextPage(productTypePage < totalPages)
          if ((productTypePage === 1 || totalItems > productTypeOptions.length) && response.data.items) {
            const newItems = [
              ...(productTypePage > 1 ? productTypeOptions : []),
              ...response.data.items.map((e: any)=>{
                return {value: e.id, label: e.name, color: ''}
              })
            ]

            setProductTypeOptions(newItems)
          }
        }
      })
    }
  }
  
  const loadProductCategoryOptions = () => {
    if (productCategoryMenuOpen && productCategoryHasNextPage) {
      const filter = {'page': productCategoryPage, search: productCategorySearch, lan: intl.locale} as IDataFilter
      productCategoryList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setProductCategoryHasNextPage(productCategoryPage < totalPages)
          if ((productCategoryPage === 1 || totalItems > productCategoryOptions.length) && response.data.items) {
            const newItems = [
              ...(productCategoryPage > 1 ? productCategoryOptions : []),
              ...response.data.items.map((e: any)=>{
                return {value: e.id, label: e.title, color: ''}
              })
            ]

            setProductCategoryOptions(newItems)
          }
        }
      })
    }
  }

  const loadData = () => {
    const filter: IDataFilter = {
      lan: intl.locale,
      perPage,
      page,
      search,
      districtId,
      cityId,
      productTypeId,
      productCategoryId,
    }
    sellerAds(filter).then(r => {
      if (r.status === 200) {
        const items = (r.data.items ?? []).map((e: any) => {
          const m: SellerAdsModel = {
            id: e.id,
            price: Number(e.price),
            quantity: Number(e.quantity),
            phone: e.phone,
            imageId: e.image_id,
            createdAt: e.created_at,
            updatedAt: e.updated_at,
            product: {
              id: e.product.id,
              name: e.product.name,
              slug: e.product.slug,
              category: {
                id: e.product.category.id,
                title: e.product.category.title,
                slug: e.product.category.slug,
              } as SellerAdsProductCategoryModel,
              dimension: {
                id: e.product.dimension.id,
                name: e.product.dimension.name,
                slug: e.product.dimension.slug,
              } as SellerAdsProductDimensionModel,
            } as SellerAdsProductModel,
            productType: {
              id: e.product_type.id,
              name: e.product_type.name,
              slug: e.product_type.slug,
            } as ProductTypeModel,
            district: {
              id: e.district.id,
              name: e.district.name,
              slug: e.district.slug,
              city: {
                id: e.district.city.id,
                name: e.district.city.name,
                slug: e.district.city.slug,
              } as CityModel,
            } as DistrictModel,
          }
          return m
        })
        setPage(Number(r.data.paginate.page))
        setTotal(Number(r.data.paginate.total_items))
        setList([...(page > 1 ? list : []), ...items])
      }
    })
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [page, perPage, search, cityId, districtId, productTypeId, productCategoryId])

  // eslint-disable-next-line
  useEffect(()=>loadProductTypeOptions(), [productTypeMenuOpen, productTypePage, productTypeSearch])

  // eslint-disable-next-line
  useEffect(()=>loadProductCategoryOptions(), [productCategoryMenuOpen, productCategoryPage, productCategorySearch])

  return <>
    {/*Header Span*/}
    <span className="header-span"></span>
    {/* End Header Span*/}
    {/*Page Title*/}
    <section className="page-title">
      <div className="auto-container">
        <div className="title-outer">
          <h1>{intl.formatMessage({id: 'Seller advertisements'})}</h1>
          <ul className="page-breadcrumb">
            <li><Link to={'/'}>{intl.formatMessage({id: 'Home'})}</Link></li>
            <li>{intl.formatMessage({id: 'Seller advertisements'})}</li>
          </ul>
        </div>
      </div>
    </section>
    {/*End Page Title*/}
    {/* List section */}
    <section className="ls-section">
      <div className="auto-container">
        <div className="filters-backdrop"></div>

        <div className="row">

          {/* Filters Column */}
          <div className="filters-column col-lg-4 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="filters-outer">
                <button type="button" className="theme-btn close-filters">X</button>

                {/* Filter by keywords */}
                <div className="filter-block">
                  <h4>{intl.formatMessage({id: 'Search by keywords'})}</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      name="listing-search"
                      placeholder={intl.formatMessage({id: 'Enter keywords'})}
                      value={search}
                      onChange={({target})=>setSearch(target.value)}
                    />
                    <span className="icon flaticon-search-3"></span>
                  </div>
                </div>

                {/*  Filter by location */}
                <ListFilterLocationSelect
                  cityId={searchCityId}
                  setCityId={setCityId}
                  districtId={searchDistrictId}
                  setDistrictId={setDistrictId}
                />

                {/* Filter product type */}
                <div className="filter-block">
                  <h4>{intl.formatMessage({id: 'Product type'})}</h4>
                  <div className="form-group">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      value={productTypeValue}
                      menuIsOpen={productTypeMenuOpen}
                      onChange={(s)=>{
                        if (!s) { s = {value: '', label: '', color: ''} }
                        setProductTypeValue(s)
                        setProductTypeId(s.value)
                      }}
                      onMenuScrollToBottom={()=>{
                        setProductTypePage(1 + productTypePage)
                      }}
                      onInputChange={(s, m)=> {
                        if (m.action === "input-change"){
                          setProductTypeSearch(s)
                        }
                      }}
                      onMenuClose={()=>setProductTypeMenuOpen(false)}
                      onMenuOpen={()=>{
                        setProductTypeMenuOpen(true)
                        setProductTypeHasNextPage(true)
                        setProductTypeOptions([])
                        setProductTypePage(1)
                        setProductTypeSearch('')
                      }}
                      options={productTypeOptions}
                      styles={{control: (provided: any) => ({
                          ...provided,
                          flexWrap: 'nowrap',
                          height: '60px',
                        })}}
                    />
                    {/*<span className="icon flaticon-briefcase"></span>*/}
                  </div>
                </div>

                {/* Filter product category */}
                <div className="filter-block">
                  <h4>{intl.formatMessage({id: 'Product category'})}</h4>
                  <div className="form-group">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      value={productCategoryValue}
                      menuIsOpen={productCategoryMenuOpen}
                      onChange={(s)=>{
                        if (!s) { s = {value: '', label: '', color: ''} }
                        setProductCategoryValue(s)
                        setProductCategoryId(s.value)
                      }}
                      onMenuScrollToBottom={()=>{
                        setProductCategoryPage(1 + productTypePage)
                      }}
                      onInputChange={(s, m)=> {
                        if (m.action === "input-change"){
                          setProductCategorySearch(s)
                        }
                      }}
                      onMenuClose={()=>setProductCategoryMenuOpen(false)}
                      onMenuOpen={()=>{
                        setProductCategoryMenuOpen(true)
                        setProductCategoryHasNextPage(true)
                        setProductCategoryOptions([])
                        setProductCategoryPage(1)
                        setProductCategorySearch('')
                      }}
                      options={productCategoryOptions}
                      styles={{control: (provided: any) => ({
                          ...provided,
                          flexWrap: 'nowrap',
                          height: '60px',
                        })}}
                    />
                    {/*<span className="icon flaticon-briefcase"></span>*/}
                  </div>
                </div>
                
              </div>

              {/* Call To Action */}
              <div className="call-to-action-four">
                <h5>{intl.formatMessage({id: 'Add advertisement'})}</h5>
                <p>{intl.formatMessage({id: 'Add seller advertisement description'})}</p>
                <Link to={'/dashboard/product-seller-ads/add'} className="btn btn-primary"><span
                  className="btn-title">{intl.formatMessage({id: 'Download now'})}</span></Link>
                <div className="image" style={{backgroundImage: 'url(/images/resource/ads-bg-4.png)'}}></div>
              </div>
              {/* End Call To Action */}
            </div>
          </div>

          {/* Content Column */}
          <div className="content-column col-lg-8 col-md-12 col-sm-12">
            <div className="ls-outer">
              <button type="button" className="theme-btn btn-style-two toggle-filters">Show Filters
              </button>

              {/* ls Switcher */}
              <div className="ls-switcher">
                <div className="showing-result">
                  <div className="text">
                    <ListViewInfo on={list.length > 0 ? 1 : 0} to={list.length} total={total}/>
                  </div>
                </div>
                <div className="sort-by">
                  <ListPerPageSelect setPer={v=>setPerPage(v)}/>
                </div>
              </div>

              {list.map(e => <div key={e.id} className="job-block">
                <div className="inner-box d-flex align-items-center">
                  <span className="item-image" style={{width: 107}}>
                    <ImageView screen={'small'} id={e.imageId}/>
                  </span>
                  <div className="content">
                    <h4><Link to={`/seller-ads/detail/${e.id}`}>{e.product.name}</Link></h4>
                    <ul className="job-info">
                      <li><i className="icon fa fa-map-location"></i> {`${e.district.city.name}, ${e.district.name}`}</li>
                      <li><i className="icon fa fa-scale-balanced"></i> {NumberFormat(e.quantity)} {e.product.dimension.name}</li>
                      <li><i className="icon fa fa-money-bill"></i> {`${NumberFormat(e.price)} ${intl.formatMessage({id: 'CurUz'})} / ${e.product.dimension.name}`}
                      </li>
                    </ul>
                    <ul className="job-other-info">
                      <li className="time">{e.product.category.title}</li>
                      <li className="privacy">{e.productType.name}</li>
                      <li className="time">{intl.formatMessage({id: 'Phone'})}: {e.phone}</li>
                    </ul>
                  </div>
                </div>
              </div>)}

              {/* Listing Show More */}
              <ListPaginate showing={list.length} total={total} loadMore={() => setPage(1 + page)}/>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* List section */}
  </>
}
export default SellerAdsList