import * as React from "react";
import {useState} from "react";
import {Link, Outlet, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {ModalContext} from "../AppContext";
import InitFooterJS from "./InitFooterJS";
import MainHeader from "../modules/global/components/MainHeader";
import Modal from "../modules/global/components/Modal";
import {checkIsActive} from "../modules/global/helpers/RouteHelpers";
import {logout} from "../modules/auth/redux/AuthCRUD";
import {Notify, usePageData} from "./core/PageData";
import * as auth from "../modules/auth/redux/AuthRedux";
import {useDispatch} from "react-redux";
import NotifyMessage from "./components/notify/NotifyMessage";

type MenuItem = {
  icon: string
  link: string
  title: string
}

const DashboardLayout: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const {setNotifyPush} = usePageData()
  const navigate = useNavigate()
  const [modalNode, setModalNode] = useState<React.ReactNode>(null)
  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    {icon: 'home', link: 'home', title: 'Dashboard'} as MenuItem,
    {icon: 'user', link: 'profile', title: 'Profile'} as MenuItem,
    {icon: 'shop', link: 'product-seller-ads', title: 'Seller'} as MenuItem,
    {icon: 'cart-shopping', link: 'product-buyer-ads', title: 'Buyer'} as MenuItem,
    {icon: 'shipping-fast', link: 'delivery-cargo-ads', title: 'Delivery cargo advertisement'} as MenuItem,
    {icon: 'truck-ramp-box', link: 'delivery-goods-ads', title: 'Delivery goods advertisement'} as MenuItem,
    {icon: 'calculator', link: 'bazaar-calc', title: 'Bazaar calc'} as MenuItem,
    {icon: 'file-excel', link: 'file-download', title: 'Files'} as MenuItem,
  ])

  const logoutHandler = () => {
    logout().then(response=>{
      const msg: Notify = {
        title: intl.formatMessage({id: 'Logout'}),
        message: intl.formatMessage({id: 'Error'}),
        danger: true,
      }
      if ([200, 201].includes(response.status)) {
        msg.danger = false
        msg.message = intl.formatMessage({id: 'Success'})
        dispatch(auth.actions.logout())
        navigate('/')
      }
      setNotifyPush(msg)
    })
  }

  return <>
    <ModalContext.Provider value={{open: false, childNode: null, setModal(value: React.ReactNode) {setModalNode(value)}}}>
      {/* begin::Modal */}
      <Modal>{modalNode}</Modal>
      {/* end::Modal */}
      {/* begin::NotifyMessage */}
      <NotifyMessage/>
      {/* end::NotifyMessage */}
      <div className={`${modalNode ? 'overlay-page' : ''} page-wrapper dashboard mm-page mm-slideout`} id="mm-0">
        {/* begin::HeaderSpan */}
        <span className="header-span"></span>
        {/* end::HeaderSpan */}
        {/* Main Header*/}
        <MainHeader className={'header-shaddow'}/>
        {/*End Main Header */}
        {/* Preloader */}
        {/*<div className="preloader"></div>*/}
        {/* End Preloader */}
        {/* begin::SidebarBackdrop */}
        <div className="sidebar-backdrop"></div>
        {/* end::SidebarBackdrop */}
        {/* begin::UserSidebar */}
        <div className="user-sidebar">
          <div className="sidebar-inner">
            <ul className="navigation">
              {menuItems.map(e=><li key={e.link} className={`${checkIsActive(`/dashboard/${e.link}`) ? 'active' : ''}`}>
                <Link to={`/dashboard/${e.link}`}> <i className={`fa fa-${e.icon}`}></i> {intl.formatMessage({id: e.title})}</Link>
              </li>)}
              <li>
                <Link to={'#'} onClick={logoutHandler}>
                  <i className={`fa fa-arrow-right-from-bracket`}></i> {intl.formatMessage({id: 'Logout'})}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* end::UserSidebar */}

        {/*Placeholder*/}
        <section className="user-dashboard">
          <Outlet/>
        </section>
        {/*End Placeholder*/}

        {/* begin::Copyright */}
        <div className="copyright-text">
          <p>© 2024 InfoBozor.uz. All Right Reserved.</p>
        </div>
        {/* end::Copyright */}

        <InitFooterJS/>
      </div>
    </ModalContext.Provider>
  </>
}

export default DashboardLayout