import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import HomePage from "../modules/home/HomePage";
import DashboardLayout from "../layouts/DashboardLayout";
import DashboardPage from "../modules/home/DashboardPage";
import ManageProfile from "../modules/subscribers/components/ManageProfile";
import {Me} from "../modules/global/helpers/UserHelpers";
import ProductAdsPage from "../modules/product-ads/ProductAdsPage";
import PaymentPage from "../modules/Payment/PaymentPage";
import DeliveryAdsPage from "../modules/delivery-ads/DeliveryAdsPage";
import DownloadFilePage from "../modules/download-files/DownloadFilePage";
import DownloadFileAdd from "../modules/download-files/components/DownloadFileAdd";
import BazaarPage from '../modules/bazaars/BazaarPage'
import {AuthPage} from '../modules/auth'
import SellerAdsPage from '../modules/product-ads/SellerAdsPage'
import BuyerAdsPage from '../modules/product-ads/BuyerAdsPage'
import DeliveryAdsList from '../modules/delivery-ads/components/DeliveryAdsList'
import DeliveryAdsDetailPage from '../modules/delivery-ads/DeliveryAdsDetailPage'

const MainRoutes: React.FC = () => {
  const user = Me()
  return <BrowserRouter>
    <Routes>
      {/* begin::MainRoutes */}
      <Route path="/" element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="bazaar/*" element={<BazaarPage />} />
        <Route path="seller-ads/*" element={<SellerAdsPage />} />
        <Route path="buyer-ads/*" element={<BuyerAdsPage />} />
        <Route path="delivery-cargo-ads" element={<DeliveryAdsList offer={true} />} />
        <Route path="delivery-goods-ads" element={<DeliveryAdsList offer={false} />} />
        <Route path="delivery-ads/detail/:id" element={<DeliveryAdsDetailPage />} />
        <Route path="auth/*" element={<AuthPage/>} />
        <Route path="*" element={<HomePage />} />
      </Route>
      {/* end::MainRoutes */}

      {/* begin::DashboardRoutes */}
      {user.status === 'subscriber' ? <Route path="/dashboard" element={<DashboardLayout />}>
        <Route index element={<DashboardPage/>} />
        <Route path="home" element={<DashboardPage />} />
        <Route path="choice-pay-method/:name?/:id?" element={<PaymentPage />} />
        <Route path="profile" element={<ManageProfile />} />
        <Route path="product-seller-ads/*" element={<ProductAdsPage offer={true} />} />
        <Route path="product-buyer-ads/*" element={<ProductAdsPage offer={false} />} />
        <Route path="delivery-cargo-ads/*" element={<DeliveryAdsPage offer={true} />} />
        <Route path="delivery-goods-ads/*" element={<DeliveryAdsPage offer={false} />} />
        <Route path="file-download/*" element={<DownloadFilePage />} />
        <Route path='bazaar-calc' element={<DownloadFileAdd isCalc={true}/>}/>
      </Route> : <Route path="/dashboard/*" element={<Navigate to="/auth/login" replace />}/>}
      {/* end::DashboardRoutes */}
    </Routes>
  </BrowserRouter>
}

export default MainRoutes