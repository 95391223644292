import React, {useEffect} from "react";
import * as Yup from "yup";
import {deliveryDelete, deliveryOne, deliverySave} from "../redux/deliveryCRUD";
import {DeliveryDataModel, InitialDeliveryData} from "../models/DeliveryModel";
import {useIntl} from "react-intl";
import {useFormik} from "formik";
import clsx from "clsx";
import DistrictSelectForm from "../../districts/components/DistrictSelectForm";
import {useNavigate} from "react-router-dom";
import {Notify, usePageData} from "../../../layouts/core/PageData";
import {DateFormat} from "../../global/helpers/DateHelpers";
import {useModal} from "../../../AppContext";
import ModalConfirmDelete from "../../global/components/ModalConfirmDelete";
import PayButton from "../../Payment/components/PayButton";

interface Params {
  id?: string
  offer: boolean
}

const DeliveryAdsForm: React.FC<Params> = ({id, offer}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {setNotifyPush} = usePageData()
  const {setModal} = useModal()

  const validationScheme = Yup.object().shape({
    offer: Yup.bool().required(),
    vehicle: Yup.string().required()
      .min(3, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Vehicle'}), len: 3}))
      .max(50, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Vehicle'}), len: 50})),
    weight: Yup.number().required()
      .min(1, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Weight'}), len: 1}))
      .max(100000000, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Weight'}), len: 100000000})),
    curDistrictId: Yup.string().required(intl.formatMessage({'id': 'Required field'}, {name: intl.formatMessage({id: 'Current location'})})),
    disDate: Yup.date().optional(),
    desDate: Yup.date().optional(),
    adsDays: Yup.number().required()
      .min(1, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Ads days'}), len: 1}))
      .max(4, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Ads days'}), len: 4})),
  })

  const formik = useFormik({
    initialValues: InitialDeliveryData,
    validationSchema: validationScheme,
    onSubmit: (values, {setSubmitting}) =>{
      setTimeout(()=>{
        deliverySave(values).then(response =>{
          setSubmitting(false)
          const msg: Notify = {
            title: intl.formatMessage({id: 'Save'}),
            message: intl.formatMessage({id: 'Error'}),
            danger: true,
          }
          if ([200, 201].includes(response.status)) {
            msg.danger = false
            msg.message = intl.formatMessage({id: 'Success'})
            navigate(`/dashboard/delivery-${offer ? 'cargo' : 'goods'}-ads/edit/${response.data.id}`)
            loadData()
          }
          setNotifyPush(msg)
        })
      }, 1000)
    }
  })

  const deleteCallback = () => {
    if (id && id.length === 36) {
      deliveryDelete([id]).then(response => {
        const msg: Notify = {
          title: intl.formatMessage({id: 'Delete'}),
          message: intl.formatMessage({id: 'Error'}),
          danger: true,
        }
        if (response.status === 200) {
          msg.danger = false
          msg.message = intl.formatMessage({id: 'Success'})
          navigate(`/dashboard/delivery-${offer ? 'cargo' : 'goods'}-ads`)
        }
        setNotifyPush(msg)
      })
    }
  }

  const deleteHandler = () => {
    setModal(<ModalConfirmDelete callback={deleteCallback}/>)
  }

  const loadData = () => {
    if (id && id.length === 36) {
      deliveryOne(id).then(response => {
        if (response.status === 200) {
          const data = response.data;
          const m: DeliveryDataModel = {
            id: data.id,
            subscriberId: data.subscriber_id,
            curDistrictId: data.cur_district_id,
            desDistrictId: data.des_district_id,
            vehicle: data.vehicle,
            weight: data.weight,
            disDate: DateFormat(data.dis_date),
            desDate: DateFormat(data.des_date),
            offer: data.offer === 'true',
            adsDays: data.ads_days,
            adsPrice: data.ads_price,
            description: data.description,
            createdAt: data.created_at,
            updatedAt: data.updated_at,
          }
          formik.setValues(m)
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(() => loadData(),[id])

  return <>
    <div className="row">
      <div className="col-lg-12">
        {/* Ls widget */}
        <div className="ls-widget">
          <div className="tabs-box">
            <div className="widget-title">
              <h4>{intl.formatMessage({id: `Delivery ${offer ? 'cargo' : 'goods'} advertisement`})}</h4>
            </div>
            {/* begin::PaymentButton */}
            <div style={{position: 'absolute', top: 40, right: 10, zIndex: 1000}}>
              <PayButton id={formik.values.id} name='delivery-ads' />
            </div>
            {/* end::PaymentButton */}
            {/* begin::Form */}
            <div className='widget-content'>
              {/* begin::Form */}
              <form onSubmit={formik.handleSubmit} className='default-form'>
                {/* begin::Body */}
                <div className='row'>
                  {/* begin::Vehicle */}
                  <div className='form-group col-lg-6 col-md-12'>
                    <label>{intl.formatMessage({id: 'Vehicle'})}:</label>
                    <input
                      className={clsx('me-2 text-center form-control form-control-solid ',
                        { 'is-invalid': formik.touched.vehicle && formik.errors.vehicle },
                        { 'is-valid': formik.touched.vehicle && !formik.errors.vehicle }
                      )}
                      type='text'
                      {...formik.getFieldProps('vehicle')}
                    />
                    {formik.touched.vehicle && formik.errors.vehicle && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.vehicle}</span>
                      </div>
                    )}
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                  {/* end::Vehicle */}
                  {/* begin::Weight */}
                  <div className='form-group col-lg-6 col-md-12'>
                    <label>{intl.formatMessage({id: 'Weight'})}:</label>
                    <input
                      className={clsx(
                        'me-2 text-center form-control form-control-solid ',
                        { 'is-invalid': formik.touched.weight && formik.errors.weight },
                        { 'is-valid': formik.touched.weight && !formik.errors.weight }
                      )}
                      type='number'
                      {...formik.getFieldProps('weight')}
                    />
                    {formik.touched.weight && formik.errors.weight && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.weight}</span>
                      </div>
                    )}
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                  {/* end::Weight */}
                  {/* begin::CurrentDistrict */}
                  <label className='form-label text-muted fw-bolder text-center'>
                    {intl.formatMessage({id: 'Current location'})}
                  </label>
                  <DistrictSelectForm
                    id={formik.values.curDistrictId}
                    callback={id=>formik.setFieldValue('curDistrictId', id)}
                  />
                  {/* end::CurrentDistrict */}
                  {/* begin::DestinationDistrict */}
                  <label className='form-label text-muted fw-bolder text-center'>
                    {intl.formatMessage({id: 'Destination location'})}
                  </label>
                  <DistrictSelectForm
                    id={formik.values.desDistrictId}
                    callback={id=>formik.setFieldValue('desDistrictId', id)}
                  />
                  {/* end::DestinationDistrict */}
                  {/* begin::ChoiceDate */}
                  <div className='form-group col-lg-6 col-md-12'>
                    <label className='w-100'>{intl.formatMessage({id: 'Date'})}:</label>
                    <div className="row ">
                      <div className="col-6">
                        <label>{intl.formatMessage({id: 'Dispatch date'})}:</label>
                        <input
                          className={clsx('me-2 text-center form-control form-control-solid ',
                            {'is-invalid': formik.touched.disDate && formik.errors.disDate},
                            {'is-valid': formik.touched.disDate && !formik.errors.disDate}
                          )}
                          type='date'
                          {...formik.getFieldProps('disDate')}
                        />
                        {formik.touched.disDate && formik.errors.disDate && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.disDate}</span>
                          </div>
                        )}
                        <div className='form-text text-muted'>
                          {intl.formatMessage({id: 'Required'})}
                        </div>
                      </div>
                      <div className="col-6">
                        <label>{intl.formatMessage({id: 'Destination date'})}:</label>
                        <input className={clsx('me-2 text-center form-control form-control-solid ',
                            {'is-invalid': formik.touched.desDate && formik.errors.desDate},
                            {'is-valid': formik.touched.desDate && !formik.errors.desDate}
                          )}
                          type='date'
                          {...formik.getFieldProps('desDate')}
                        />
                        {formik.touched.desDate && formik.errors.desDate && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.desDate}</span>
                          </div>
                        )}
                        <div className='form-text text-muted'>
                          {intl.formatMessage({id: 'Optional'})}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end::ChoiceDate */}
                  {/* begin::AdsDays */}
                  <div className='form-group col-lg-6 col-md-12'>
                    <label>{intl.formatMessage({id: 'Ads days'})}:</label>
                    <input
                      className={clsx('',
                        { 'is-invalid': formik.touched.adsDays && formik.errors.adsDays },
                        { 'is-valid': formik.touched.adsDays && !formik.errors.adsDays }
                      )}
                      type='number'
                      {...formik.getFieldProps('adsDays')}
                    />
                    {formik.touched.adsDays && formik.errors.adsDays && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.adsDays}</span>
                      </div>
                    )}
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                  {/* end::AdsDays */}
                  {/* begin::Description */}
                  <div className='form-group col-lg-12 col-md-12'>
                    <label>{intl.formatMessage({id: 'Description'})}:</label>
                    <textarea className={clsx('',
                        { 'is-invalid': formik.touched.description && formik.errors.description },
                        { 'is-valid': formik.touched.description && !formik.errors.description }
                      )}
                      rows={4}
                      {...formik.getFieldProps('description')}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Optional'})}
                    </div>
                  </div>
                  {/* end::Description */}
                  {/* begin::SubmitButton */}
                  <div className="form-group col-lg-6 col-md-12">
                    <button
                      type='button'
                      className='btn btn-danger mx-4'
                      onClick={deleteHandler}
                    >
                        <span className='indicator-label'>
                          {intl.formatMessage({id: 'Delete'})}
                        </span>
                    </button>
                    <button
                      type='button'
                      className="theme-btn btn btn-primary"
                      onClick={_=>formik.submitForm()}
                      disabled={!formik.isValid}
                    >
                      {intl.formatMessage({id: 'Save'})}
                    </button>
                  </div>
                  {/* begin::SubmitButton */}
                </div>
                {/* end::Body */}
              </form>
              {/* end::Form */}
            </div>
            {/* end::Form */}
          </div>
        </div>
      </div>
    </div>
  </>
}
export default DeliveryAdsForm