import './HomePage.css'
import React from "react";
import {useIntl} from "react-intl";
import SectionTree from "./components/SectionTree";
import Regions from "./components/Regions";
import Bazaars from "./components/Bazaars";
import SellerAdsList from "./components/SellerAdsList";
import BuyerAdsList from './components/BuyerAdsList'
import DeliveryAds from './components/DeliveryAds'

const HomePage: React.FC = () => {
  const intl = useIntl()

  return <>
    {/* Banner Section Three*/}
    <SectionTree/>
    {/* End Banner Section Three*/}

    {/* Job Categories */}
    <Regions/>
    {/* End Job Categories */}

    {/* Registration Banners */}
    {/*<RegistrationBanners/>*/}
    {/* End Registration Banners */}

    {/* Latest jobs */}
    <Bazaars/>
    {/* End Latest jobs */}

    {/* Seller ads */}
    <SellerAdsList/>
    {/* End Seller ads */}

    {/* Buyer ads */}
    <BuyerAdsList/>
    {/* End Buyer ads */}

    {/* Delivery cargo ads */}
    <DeliveryAds offer={true}/>
    {/* End Delivery cargo ads */}

    {/* Delivery goods ads */}
    <DeliveryAds offer={false}/>
    {/* End Delivery goods ads */}

  </>
}

export default HomePage