import React from 'react'
import {useParams} from 'react-router-dom'
import SellerAdsDetail from './componenets/SellerAdsDetail'

type Params = {
  id: string
}

const SellerAdsDetailPage: React.FC = () => {
  const {id} = useParams<Params>()

  return <SellerAdsDetail id={id}/>
}
export default SellerAdsDetailPage