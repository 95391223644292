import React, {useState} from "react"
import SVG from "react-inlinesvg";
import {useIntl} from "react-intl";
import {ImageModel} from "../models/ImageModel";
import {useModal} from "../../../AppContext";

type Props = {
  initImage: ImageModel
  setImages(value: ImageModel[]): void
  setDeletes(value: string[]): void
  initImages: ImageModel[]
  initDeletes?: string[]
}

const ModelImageForm: React.FC<Props> = ({initImage, setImages, setDeletes, initImages, initDeletes}) => {
  const {setModal} = useModal()
  const intl = useIntl()

  const[image, setImage] = useState<ImageModel>(initImage)
  const[error, setError] = useState<string>()

  const deleteHandler = () => {
    const updates = [...initImages.filter(e => e.id !== image.id)]
    for (let i = 0; i < updates.length; i++) {
      updates[i].pos = i + 1
    }

    setImages(updates)
    if (image.id.length === 36) {
      setDeletes([image.id, ...initDeletes ?? []])
    }
    setModal(null)
  }

  const openChoiceFileHandler = () => {
    const inputFile = document.getElementById('input-file') as HTMLInputElement
    // setImage({id: '', pos: 0, path: ''} as ImageModel)
    inputFile.click()
  }

  const changeFileHandler = (e: { target: { files: FileList | null } }) => {
    if (e.target.files) {
      const file = e.target.files[0]
      if (['image/png', 'image/jpeg'].includes(file.type)) {
        const path = URL.createObjectURL(file)
        let updates = [...initImages]

        image.path = path
        image.file = file
        if (image.pos === 0) {
          image.pos = 1
          updates = [image, ...updates]
        }

        for (let i = 0; i < updates.length; i++) {
          if (updates[i].id === image.id) {
            updates[i].path = image.path
          }
          updates[i].pos = i + 1
          if (updates[i].id.length !== 36) {
            updates[i].id = (i + 1).toString()
          }
        }

        setImage(image)
        setImages(updates)
        setError('')
      } else {
        setError(intl.formatMessage({id: 'Invalid'}, {name: intl.formatMessage({id: 'Image'}).toLowerCase}))
      }
    }
  }

  return <div style={{width: 500}}>
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{`${intl.formatMessage({id: 'Image'})} ${intl.formatMessage({id: 'Edit'}).toLowerCase()}`}</h5>
        </div>
        <div>
          <input type="file" style={{display: 'none'}} id='input-file' onChange={changeFileHandler}/>
          {image.path.length === 0 ?
            <div className='w-100 d-flex flex-column text-center justify-content-center' style={{height: 300}}>
              <button
                type='button'
                className='w-100 h-100'
                onClick={openChoiceFileHandler}
              >
                <i className='fa fa-upload fs-2 text-muted'></i>
              </button>
            </div> :
            <img src={image.path} alt={image.pos.toString()} className='w-auto h-auto' style={{maxHeight: 200}}/>}
        </div>
        <div className="modal-footer">
          {image.path ? <button
            type="button"
            className="btn btn-danger"
            onClick={deleteHandler}
          >
            {intl.formatMessage({id: 'Delete'})}
          </button> : null}
          {image.file || image.id.length > 0 ? <button
            type="button"
            className="btn btn-primary"
            onClick={openChoiceFileHandler}
          >
            {intl.formatMessage({id: 'Replace'})}
          </button> : null}
        </div>
      </div>
    </div>
    {error && error.length > 0 ? <div className='fv-plugins-message-container'>
      <span role='alert'>{error}</span>
    </div> : null}
  </div>
}
export default ModelImageForm;