import React, {useEffect} from "react";
const jsFiles = [
  'jquery',
  'popper.min',
  'chosen.min',
  'bootstrap.min',
  'jquery.fancybox',
  'jquery.modal.min',
  'mmenu.polyfills',
  'mmenu',
  'appear',
  'anm.min',
  'ScrollMagic.min',
  'rellax.min',
  'owl',
  'wow',
  'script',
]

const InitFooterJS: React.FC = () => {
  useEffect(()=>{
    document.addEventListener('DOMContentLoaded', () => {
      const scripts: Array<HTMLScriptElement> = []

      for (const k in jsFiles) {
        const script = document.createElement('script');
        script.src = `/js/${jsFiles[k]}.js`
        script.async = true;
        document.body.appendChild(script);
        scripts.push(script)
      }
      for (const k in jsFiles) {
        document.body.removeChild(scripts[k]);
      }})
  }, [])

  return <></>
}

export default InitFooterJS