import React, {createContext, useContext, useState} from "react"


export interface LocationDataContextModel {
  cityId: string
  setCityId(v: string): void
  districtId: string
  setDistrictId(v: string): void
}

const LocationDataContext = createContext<LocationDataContextModel>({
  cityId: '',
  setCityId(v: string) {},
  districtId: '',
  setDistrictId(v: string) {}
})

const LocationDataProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [cityId, setCityId] = useState('')
  const [districtId, setDistrictId] = useState('')
  const value: LocationDataContextModel = {
    cityId,
    setCityId,
    districtId,
    setDistrictId,
  }

  return <LocationDataContext.Provider value={value}>{children}</LocationDataContext.Provider>
}

const useLocationData = () => {
  return useContext(LocationDataContext)
}

export {useLocationData, LocationDataProvider}