import { InitialUser, UserModel } from "./UserModel";
import { DistrictModel, InitialDistrict } from "../../districts/models/DistrictModel";

export interface SubscriberModel {
  id: string
  balance: string
  birth: string
  lon: number
  lat: number
  user: UserModel
  district: DistrictModel
}

export interface SubscriberDataModel {
  id: string
  userId: string
  districtId: string
  fullName: string
  phone: string
  balance: string
  birth: string
  lon: number
  lat: number
  password?: string
}

export const InitialSubscriber:SubscriberModel = {
  id: '',
  balance: '',
  birth: '',
  lon: 0,
  lat: 0,
  user: InitialUser,
  district: InitialDistrict,
}

export const InitialSubscriberData:SubscriberDataModel = {
  id: '',
  userId: '',
  districtId: '',
  fullName: '',
  phone: '',
  balance: '',
  birth: '',
  lon: 0,
  lat: 0,
}