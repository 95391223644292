import React, { useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import { DistrictModel } from "../../districts/models/DistrictModel"
import { InitialSelectOptionData, SelectOptionModel } from "../../global/models/SelectOptionModel";
import { useIntl } from "react-intl";
import {productBazaars} from "../../product-ads/redux/productCRUD";
import {BazaarModel} from "../models/BazaarModel";
import {BazaarTypeModel} from "../../bazaar-types/models/BazaarTypeModel";
import {bazaarList} from "../redux/bazaarCRUD";
import {IDataFilter} from "../../global/models/DataFilter";

type Params = {
  productId: string
  districtId: string
  setValue(value: MultiValue<SelectOptionModel>): void
}
const BazaarsSelect: React.FC<Params> = ({productId, districtId, setValue}) => {
  const intl = useIntl()

  const [bazaarSearch, setBazaarSearch] = useState('')
  const [bazaarValue, setBazaarValue] = useState<MultiValue<SelectOptionModel>>([])
  const [bazaarMenuOpen, setBazaarMenuOpen] = useState(false)
  const [bazaarHasNextPage, setBazaarHasNextPage] = useState(false)
  const [bazaarOptions, setBazaarOptions] = useState([InitialSelectOptionData,])
  const [bazaarPage, setBazaarPage] = useState(1)

  const loadData = () => {
    if (productId && productId.length === 36) {
      productBazaars({productId, perPage: 1000}).then(response => {
        if (response.status === 200) {
          const res = response.data.items ? response.data.items.map((e: any) => {
            const m: BazaarModel = {
              id: e.id,
              name: e.name,
              slug: e.slug,
              openDays: e.open_days,
              district: {id: e.district.id, name: e.district.name, slug: e.district.slug} as DistrictModel,
              bazaarType: {id: e.type.id, name: e.type.name, slug: e.type.slug} as BazaarTypeModel,
            }
            return {value: m.id, label: `${m.district.name} - ${m.name} - ${m.bazaarType.name}`, color: ''}
          }) : []
          setBazaarValue(res)
        }
      })
    }
  }

  const loadBazaarOptions = () => {
    if (bazaarMenuOpen && bazaarHasNextPage) {
      const filter: IDataFilter = {districtId, page: bazaarPage, search: bazaarSearch, lan: intl.locale}
      bazaarList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setBazaarHasNextPage(bazaarPage <= totalPages)
          if ((bazaarPage === 1 || totalItems > bazaarOptions.length) && response.data.items) {
            const newItems = [
              ...(bazaarPage > 1 ? bazaarOptions : []),
              ...response.data.items.map((e: any)=>{
                return {value: e.id, label: `${e.district.name} - ${e.name} - ${e.type.name}`}
              })
            ]

            setBazaarOptions(newItems)
          }
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [productId])

  // eslint-disable-next-line
  useEffect(()=>loadBazaarOptions(), [bazaarMenuOpen, bazaarPage, bazaarSearch, districtId])

  return <div className='form-group col-lg-12'>
    <label>{intl.formatMessage({id: 'Bazaars'})}:</label>
    <Select
      className='basic-single'
      classNamePrefix="select"
      isClearable={true}
      isSearchable={true}
      isMulti={true}
      value={bazaarValue}
      menuIsOpen={bazaarMenuOpen}
      onChange={(s)=>{
        if (!s) { s = [] }
        setBazaarValue(s)
        setValue(s)
      }}
      onMenuScrollToBottom={()=>{
        setBazaarPage(1 + bazaarPage)
      }}
      onInputChange={(s, m)=> {
        if (m.action === "input-change"){
          setBazaarSearch(s)
        }
      }}
      onMenuClose={()=>setBazaarMenuOpen(false)}
      onMenuOpen={()=> {
        setBazaarMenuOpen(true)
        setBazaarHasNextPage(true)
        setBazaarOptions([])
        setBazaarPage(1)
        setBazaarSearch('')
      }}
      options={bazaarOptions}
    />
    <div className='form-text text-muted'>
      {intl.formatMessage({id: 'Required'})}
    </div>
  </div>
}

export default BazaarsSelect