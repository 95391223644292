export const DateFormat = (date: string|undefined, full: boolean=false) => {
  if (date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = '' + d.getHours(),
      min = '' + d.getMinutes()

    if (month.length < 2)
      month = '0' + month
    if (day.length < 2)
      day = '0' + day
    if (hour.length < 2)
      hour = '0' + hour
    if (min.length < 2)
      min = '0' + min

    return full ? `${[year, month, day].join('-')} ${[hour, min].join(':')}` : [year, month, day].join('-')
  }
  return ''
}

export const PeriodToDate = (period: string, end: string=DateFormat(new Date().toDateString())) => {
  const date = new Date(end)
  switch (period) {
    case 'week': return DateFormat(new Date(date.setDate(date.getDate()-7)).toDateString())
    case 'month': return DateFormat(new Date(date.setMonth(date.getMonth()-1)).toDateString())
    case 'year': return DateFormat(new Date(date.setFullYear(date.getFullYear()-1)).toDateString())
    default: return DateFormat(new Date(date.setDate(date.getDate())).toDateString())
  }
}