import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {bazaarList} from '../redux/bazaarCRUD'
import ImageView from '../../global/components/ImageView'
import ListPaginate from '../../global/components/ListPaginate'
import ListViewInfo from '../../global/components/ListViewInfo'
import {IDataFilter} from '../../global/models/DataFilter'
import ListPerPageSelect from '../../global/components/ListPerPageSelect'
import {BazaarItemModel} from '../models/BazaarItemModel'
import {useLocationData} from '../../../layouts/core/HomeData'
import ListFilterLocationSelect from '../../global/components/ListFilterLocationSelect'


const BazaarList: React.FC = () => {
  const intl = useIntl()
  const {cityId: searchCityId, districtId: searchDistrictId} = useLocationData()

  const [search, setSearch] = useState('')
  const [cityId, setCityId] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [list, setList] = useState<BazaarItemModel[]>([])
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const loadData = () => {
    const filter: IDataFilter = {lan: intl.locale, perPage, page, search, districtId, cityId}
    bazaarList(filter).then(response => {
      if (response.status === 200) {
        const items = (response.data.items ?? []).map((e: any) => {
          const m: BazaarItemModel = {
            id: e.id,
            name: e.name,
            typeName: e.type.name,
            location: `${e.district.city.name}, ${e.district.name}`,
            imageId: e.image_id,
            actualPriceCount: e.actual_price_count,
            minPrice: e.min_price,
            maxPrice: e.max_price,
            link: `/bazaar/${e.id}`,
            mon: (e.mon ?? '') === 'true',
            tue: (e.tue ?? '') === 'true',
            wed: (e.wed ?? '') === 'true',
            thu: (e.thu ?? '') === 'true',
            fri: (e.fri ?? '') === 'true',
            sat: (e.sat ?? '') === 'true',
            sun: (e.sun ?? '') === 'true',
          }
          return m
        })
        setPage(Number(response.data.paginate.page))
        setTotal(Number(response.data.paginate.total_items))
        setList([...(page > 1 ? list : []), ...items])
      }
    })
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [page, perPage, search, cityId, districtId])

  return <>
    {/*Header Span*/}
    <span className="header-span"></span>
    {/* End Header Span*/}
    {/*Page Title*/}
    <section className="page-title">
      <div className="auto-container">
        <div className="title-outer">
          <h1>{intl.formatMessage({id: 'Bazaars'})}</h1>
          <ul className="page-breadcrumb">
            <li><Link to={'/'}>{intl.formatMessage({id: 'Home'})}</Link></li>
            <li>{intl.formatMessage({id: 'Bazaars'})}</li>
          </ul>
        </div>
      </div>
    </section>
    {/*End Page Title*/}
    {/* List section */}
    <section className="ls-section">
      <div className="auto-container">
        <div className="filters-backdrop"></div>

        <div className="row">

          {/* Filters Column */}
          <div className="filters-column col-lg-4 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="filters-outer">
                <button type="button" className="theme-btn close-filters">X</button>

                {/* Filter by keywords */}
                <div className="filter-block">
                  <h4>{intl.formatMessage({id: 'Search by keywords'})}</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      name="listing-search"
                      placeholder={intl.formatMessage({id: 'Enter keywords'})}
                      value={search}
                      onChange={({target})=>setSearch(target.value)}
                    />
                    <span className="icon flaticon-search-3"></span>
                  </div>
                </div>

                {/*  Filter by location */}
                <ListFilterLocationSelect
                  cityId={searchCityId}
                  setCityId={setCityId}
                  districtId={searchDistrictId}
                  setDistrictId={setDistrictId}
                />

              </div>

              {/* Call To Action */}
              <div className="call-to-action-four">
                <h5>{intl.formatMessage({id: 'Download file'})}</h5>
                <p>{intl.formatMessage({id: 'Download file description'})}</p>
                <Link to={'/dashboard/file-download/add'} className="theme-btn btn-style-one bg-blue"><span
                  className="btn-title">{intl.formatMessage({id: 'Download now'})}</span></Link>
                <div className="image" style={{backgroundImage: 'url(/images/banner-1.png)'}}></div>
              </div>
              {/* End Call To Action */}
            </div>
          </div>

          {/* Content Column */}
          <div className="content-column col-lg-8 col-md-12 col-sm-12">
            <div className="ls-outer">
              <button type="button" className="theme-btn btn-style-two toggle-filters">Show Filters
              </button>

              {/* ls Switcher */}
              <div className="ls-switcher">
                <div className="showing-result">
                  <div className="text">
                    <ListViewInfo on={list.length > 0 ? 1 : 0} to={list.length} total={total}/>
                  </div>
                </div>
                <div className="sort-by">
                  <ListPerPageSelect setPer={v=>setPerPage(v)}/>
                </div>
              </div>

              {list.map(e => <div key={e.id} className="job-block">
                <div className="inner-box">
                  <div className="content">
                    <span className="company-logo">
                      <ImageView screen={'small'} className={e.imageId}/>
                    </span>
                    <h4>{e.name}</h4>
                    <ul className="job-info">
                      <li><span className="icon flaticon-briefcase"></span> {e.typeName}</li>
                      <li><span className="icon flaticon-map-locator"></span> {e.location}</li>
                    </ul>
                    <ul className="job-other-info">
                      {e.sun ? <li className="time">{intl.formatMessage({id: 'Sun'})}</li> : null}
                      {e.tue ? <li className="time">{intl.formatMessage({id: 'Tue'})}</li> : null}
                      {e.wed ? <li className="time">{intl.formatMessage({id: 'Wed'})}</li> : null}
                      {e.thu ? <li className="time">{intl.formatMessage({id: 'Thu'})}</li> : null}
                      {e.fri ? <li className="time">{intl.formatMessage({id: 'Fri'})}</li> : null}
                      {e.sat ? <li className="time">{intl.formatMessage({id: 'Sat'})}</li> : null}
                      {e.sun ? <li className="time">{intl.formatMessage({id: 'Sun'})}</li> : null}
                    </ul>
                  </div>
                </div>
              </div>)}

              {/* Listing Show More */}
              <ListPaginate showing={list.length} total={total} loadMore={() => setPage(1 + page)}/>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* List section */}
  </>
}
export default BazaarList