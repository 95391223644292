import React, {useEffect} from "react"
import {useNavigate, useParams} from "react-router-dom";
import {paymentCheck} from "../../Payment/redux/paymentCRUD";
import {downloadFile} from "../redux/downloadFileCRUD";
import {useIntl} from "react-intl";

type Params = {
  id: string
}

const DownloadFileCheckPayment: React.FC = () => {
  const {id} = useParams<Params>()
  const navigate = useNavigate()
  const intl = useIntl()

  const download = (id: string) => {
    downloadFile(id, intl.locale).then(response=>{
      if (response.data) {
        const filename =  response.headers['content-disposition'].split('filename=')[1]
        const fileURL = window.URL.createObjectURL(response.data)
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = filename

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    })
  }

  const loadData = () => {
    if (id && id.length === 36) {
      paymentCheck({id, name: 'file-download'}).then(response=>{
        if (response.data.result?.toString() === 'true') {
          download(id)
          navigate(`/dashboard/file-download`, {replace: true})
        } else {
          navigate(`/dashboard/choice-pay-method/file-download/${id}`)
        }
      })
    } else {
      navigate('/dashboard/file-download')
    }
  }

  useEffect(()=>loadData(), [id])

  return <></>
}
export default DownloadFileCheckPayment