import axios, { AxiosResponse } from "axios";
import * as querystring from "querystring";
import {IDataFilter} from "../../global/models/DataFilter";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'image'
const THUMB = `${API_URL}/${_RESOURCE}/thumb`

export function imageThumb(id: string, screen: 'small'|'mobile'|'desktop') {
  if (id && id.length === 36) {
    return axios.post(THUMB, querystring.stringify({id, screen}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}