export interface DimensionModel {
  id: string
  name: string
  slug: string
}

export const InitialDimension:DimensionModel = {
  id: "",
  name: "",
  slug: "",
}