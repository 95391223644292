import React from 'react'
import {useParams} from 'react-router-dom'
import DeliveryAdsDetail from './components/DeliveryAdsDetail'

type Props = {
    id: string
}

const DeliveryAdsDetailPage: React.FC = () => {
    const {id} = useParams<Props>()

  return <DeliveryAdsDetail id={id}/>
}
export default DeliveryAdsDetailPage