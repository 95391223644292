import axios, { AxiosResponse } from "axios"
import * as querystring from "querystring"
import {DownloadFileDataModel} from "../models/DownloadFileModel"
import {IDataFilter} from "../../global/models/DataFilter";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'subscriber-file'
const LIST = `${API_URL}/private/${_RESOURCE}/list`
const PRODUCTS = `${API_URL}/private/${_RESOURCE}/products`
const BAZAARS = `${API_URL}/private/${_RESOURCE}/bazaars`
const BAZAARS_FOR_REPORT = `${API_URL}/private/${_RESOURCE}/bazaars-for-report`
const CHECK_PRODUCTS = `${API_URL}/private/${_RESOURCE}/check-products`
const FILE = `${API_URL}/private/${_RESOURCE}/file`
const GET = `${API_URL}/private/${_RESOURCE}/get`
const ONE = `${API_URL}/private/${_RESOURCE}/one`
const SAVE = `${API_URL}/private/${_RESOURCE}/save`
const DELETE = `${API_URL}/private/${_RESOURCE}/delete`

export function downloadFile(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(FILE, JSON.stringify({id, lan}), {responseType: 'blob'})
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function downloadFileList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    search: filter.search,
    page: filter.page,
  }))
}

export function downloadFileProducts(id: string, filter: IDataFilter) {
  return axios.post(PRODUCTS, querystring.stringify({
    id,
    search: filter.search,
    page: filter.page,
  }))
}

export function downloadFileBazaars(id: string, lan: string) {
  return axios.post(BAZAARS, querystring.stringify({file_id: id, lan}))
}

export function downloadFileBazaarsForReport(filter: IDataFilter) {
  const products = (filter.fileProducts ?? []).filter(e=>e.id.length === 36).map(e=>{
    return {id: e.id, type_id: e.typeId, quantity: e.quantity.toString()}
  })
  return axios.post(BAZAARS_FOR_REPORT, querystring.stringify({
    search: filter.search,
    file_products: JSON.stringify(products),
    period_start: filter.periodStart,
    period_end: filter.periodEnd,
    per: filter.perPage,
    page: filter.page,
  }))
}

export function downloadFileCheckBazaarsProducts(filter: IDataFilter) {
  const products = (filter.fileProducts ?? []).filter(e=>e.id.length === 36).map(e=>{
    return {id: e.id, type_id: e.typeId, quantity: e.quantity.toString()}
  })
  return axios.post(CHECK_PRODUCTS, querystring.stringify({
    ids: JSON.stringify(filter.ids),
    file_products: JSON.stringify(products),
    period_start: filter.periodStart,
    period_end: filter.periodEnd,
  }))
}

export function downloadFileGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function downloadFileOne(id: string) {
  if (id && id.length === 36) {
    return axios.post(ONE, querystring.stringify({id}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function downloadFileSave(model: DownloadFileDataModel) {
  const products = (model.fileProducts ?? []).filter(e=>e.id.length === 36).map(e=>{
    return {id: e.id, type_id: e.typeId, quantity: e.quantity.toString()}
  })
  return axios.post(SAVE, querystring.stringify({
    id: model.id,
    subscriber_id: model.subscriberId,
    file_category_id: model.fileCategoryId,
    period_end: model.periodEnd,
    bazaar_ids: JSON.stringify(model.bazaarIds),
    file_products: JSON.stringify(products),
  }))
}

export function downloadFileDelete(ids: Array<string>) {
  return axios.post(DELETE, querystring.stringify({ ids: JSON.stringify(ids) }))
}
