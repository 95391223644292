import React from "react"
import {Route, Routes} from "react-router-dom";
import DeliveryAdsFormPage from "./DeliveryAdsFormPage";
import {DeliveryAdsTable} from "./components/DeliveryAdsTable";

type Props = {
  offer: boolean
}

const DeliveryAdsPage: React.FC<Props> = ({offer}) => {

  return <Routes>
    <Route index element={<DeliveryAdsTable offer={offer}/>}/>
    <Route path='/list' element={<DeliveryAdsTable offer={offer}/>}/>
    <Route path='/add' element={<DeliveryAdsFormPage offer={offer}/>}/>
    <Route path='/edit/:id' element={<DeliveryAdsFormPage offer={offer}/>}/>
  </Routes>
}
export default DeliveryAdsPage;