import IModel from "./Model";
export const InitialIListData = {totalItems: 0, page: 0, totalPages: 0, items: []}

export default interface IListData<T> {
  totalItems: number
  page: number
  totalPages: number
  items: Array<T>
}

