export type SellerAdsProductDimensionModel = {
  id: string
  name: string
  slug: string
}

export const InitialSellerAdsProductDimension: SellerAdsProductDimensionModel = {
  id: '',
  name: '',
  slug: '',
}