import React, {useEffect} from "react";
import * as Yup from "yup"
import './LoginForm.css'
import {useIntl} from "react-intl";
import {useFormik} from "formik";
import {login} from "../../redux/AuthCRUD";
import {Notify, usePageData} from "../../../../layouts/core/PageData";
import {Link, useNavigate} from "react-router-dom";
import clsx from "clsx";
import {useModal} from "../../../../AppContext";
import RegisterForm from "../register/RegisterForm";
import {useDispatch} from "react-redux";
import * as auth from "../../redux/AuthRedux";
import {Me} from '../../../global/helpers/UserHelpers'

type UserData = {
  phone: string
  password: string
}

type Props = {
  className: string
}

const LoginForm:React.FC<Props> = ({className}) => {
  const user = Me()
  const dispatch = useDispatch()
  const intl = useIntl()
  const {setModal} = useModal()
  const navigate = useNavigate()
  const {setNotifyPush} = usePageData()

  const validationScheme = Yup.object().shape({
    phone: Yup.string().required().matches(
      /^\+[0-9\-().\s]{12}$/,
      intl.formatMessage({'id': 'Length'}, {name: intl.formatMessage({'id': 'Phone'}), len: 13})
    ),
    fullName: Yup.string()
      .min(3, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Full name'}), len: 3}))
      .max(150, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Full name'}), len: 150})),
    password: Yup.string().required()
      .min(4, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Password'}), len: 4}))
      .max(20, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Password'}), len: 20})),
  })

  const formik = useFormik({
    initialValues: {phone: '', password: ''} as UserData,
    validationSchema: validationScheme,
    onSubmit: (values, {setSubmitting}) =>{
      setTimeout(()=>{
        login(values.phone, values.password).then(response =>{
          setSubmitting(false)
          const msg: Notify = {
            title: intl.formatMessage({id: 'Login'}),
            message: intl.formatMessage({id: 'Error'}),
            danger: true,
          }
          if ([200, 201].includes(response.status)) {
            msg.danger = false
            msg.message = intl.formatMessage({id: 'Success'})
            dispatch(auth.actions.login(response.data.token))
          }
          setNotifyPush(msg)
        })
      }, 1000)
    }
  })

  const loadData = () => {
    if (user.id.length === 36 && user.status === 'subscriber') {
      navigate('/dashboard/home')
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [user])

  return <div className={className}>
    {/* Login Form */}
    <div className="login-form default-form">
      <div className="form-inner">
        <h3 className='text-center'>{intl.formatMessage({id: 'Login'})}</h3>
        {/*Login Form*/}
        <form onSubmit={formik.handleSubmit}>
          {/* begin::Phone */}
          <div className="form-group">
            <label>{intl.formatMessage({id: 'Phone'})}</label>
            <input
              className={clsx('me-2 text-center form-control form-control-solid ',
                { 'is-invalid': formik.touched.phone && formik.errors.phone },
                { 'is-valid': formik.touched.phone && !formik.errors.phone }
              )}
              type='text'
              {...formik.getFieldProps('phone')}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            )}
          </div>
          {/* end::Phone */}
          {/* begin::Password */}
          <div className="form-group">
            <label>{intl.formatMessage({id: 'Password'})}</label>
            <input
              className={clsx('me-2 text-center form-control form-control-solid',
                { 'is-invalid': formik.touched.password && formik.errors.password },
                { 'is-valid': formik.touched.password && !formik.errors.password }
              )}
              type='password'
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            )}
          </div>
          {/* end::Password */}
          <div className="form-group">
            <button
              type='button'
              className="theme-btn btn btn-primary"
              disabled={formik.isSubmitting || !formik.isValid}
              onClick={_=>formik.submitForm()}
            >
              {intl.formatMessage({id: 'Login'})}
            </button>
          </div>
        </form>

        <div className="bottom-box">
          <div className="text">
            {intl.formatMessage({id: "Don't have an account?"})} {className === 'login-modal' ? <button
              type='button'
              className="signup"
              onClick={_=> setModal(<RegisterForm className={className}/>)}
            >
              {intl.formatMessage({id: 'Signup'})}
            </button> : <Link to={'/auth/register'}>
              {intl.formatMessage({id: 'Signup'})}
            </Link>}
          </div>
        </div>
      </div>
    </div>
    {/*End Login Form */}
  </div>
}

export default LoginForm