import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import * as Yup from "yup"
import {globalProductCount} from "../redux/globalProductCRUD";
import {districtGet, districtList} from "../../districts/redux/districtCRUD";
import {DistrictModel} from "../../districts/models/DistrictModel";
import {InitialSelectOptionData, SelectOptionModel} from "../../global/models/SelectOptionModel";
import {CityModel} from "../../cities/models/CityModel";
import {cityGet, cityList} from "../../cities/redux/cityCRUD";
import {IDataFilter} from "../../global/models/DataFilter";
import Select from "react-select";
import {useFormik} from "formik";
import {useLocationData} from "../../../layouts/core/HomeData";
import {Link, useNavigate} from "react-router-dom";
import {bazaarRandom} from "../redux/bazaarCRUD";
import {Truncate} from "../../global/helpers/StringHelper";
import ImageView from '../../global/components/ImageView'
import {RandomBazaarModel} from '../models/RandomBazaarModel'

const SectionTree: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {setCityId, districtId, setDistrictId} = useLocationData()

  const validationSchema = Yup.object().shape({})

  const [productCount, setProductCount] = useState(0)
  const [popularSearches, setPopularSearches] = useState<string[]>([])
  const [randomBazaars, setRandomBazaars] = useState<RandomBazaarModel[]>([])
  // city
  const [citySearch, setCitySearch] = useState('')
  const [cityMenuOpen, setCityMenuOpen] = useState(false)
  const [cityHasNextPage, setCityHasNextPage] = useState(false)
  const [cityOptions, setCityOptions] = useState([InitialSelectOptionData,])
  const [cityPage, setCityPage] = useState(1)
  const [cityValue, setCityValue] = useState<SelectOptionModel>(InitialSelectOptionData)
  // district
  const [districtSearch, setDistrictSearch] = useState('')
  const [districtMenuOpen, setDistrictMenuOpen] = useState(false)
  const [districtHasNextPage, setDistrictHasNextPage] = useState(false)
  const [districtOptions, setDistrictOptions] = useState<SelectOptionModel[]>([])
  const [districtPage, setDistrictPage] = useState(1)
  const [districtValue, setDistrictValue] = useState<SelectOptionModel>(InitialSelectOptionData)

  const formik = useFormik({
    validationSchema,
    initialValues: {cityId: '', districtId: ''},
    onSubmit: (values, {setSubmitting}) => {
      setTimeout(()=>{
        setCityId(values.cityId)
        setDistrictId(values.districtId)
        setSubmitting(false)
        navigate('/bazaar')
      }, 1000)
    }
  })

  const loadCity = (id: string) => {
    if (id && id.length === 36) {
      cityGet(id, intl.locale).then(response => {
        if (response.status === 200) {
          const data = response.data
          const m: CityModel = {
            id: data.id,
            name: data.name,
            slug: data.slug,
          }
          setCityValue({value: m.id, label: m.name, color: ''} as SelectOptionModel)
        }
      })
    }
  }

  const loadDistrict = () => {
    if (districtId && districtId.length === 36) {
      districtGet(districtId, intl.locale).then(response => {
        if (response.status === 200) {
          const data = response.data
          const m: DistrictModel = {
            id: data.id,
            name: data.name,
            slug: data.slug,
            city: {id: data.city.id, name: data.city.name, slug: data.city.slug} as CityModel,
          }
          setDistrictValue({value: m.id, label: m.name, color: ''} as SelectOptionModel)
          if (m.city.id !== cityValue.value) {
            loadCity(m.city.id)
          }
        }
      })
    }
  }

  const loadCityOptions = () => {
    if (cityMenuOpen && cityHasNextPage) {
      const filter = {'page': cityPage, search: citySearch, lan: intl.locale} as IDataFilter
      cityList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setCityHasNextPage(cityPage <= totalPages)
          if ((cityPage === 1 || totalItems > cityOptions.length) && response.data.items) {
            const newItems = [
              ...(cityPage > 1 ? cityOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setCityOptions(newItems)
          }
        }
      })
    }
  }

  const loadDistrictOptions = () => {
    if (districtMenuOpen && districtHasNextPage) {
      const filter: IDataFilter = {'page': districtPage, search: districtSearch, lan: intl.locale, cityId: cityValue.value}
      districtList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setDistrictHasNextPage(districtPage <= totalPages)
          if ((districtPage === 1 || totalItems > districtOptions.length) && response.data.items) {
            const newItems = [
              ...(districtPage > 1 ? districtOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setDistrictOptions(newItems)
          }
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadCityOptions(), [cityMenuOpen, cityPage, citySearch])

  // eslint-disable-next-line
  useEffect(()=>loadDistrictOptions(), [districtMenuOpen, districtPage, districtSearch, cityOptions])

  const loadData = () => {
    globalProductCount().then(response=>{
      if (response.status === 200) {
        setProductCount(response.data)
      }
    })

    bazaarRandom(3).then(response=>{
      if (response.status === 200) {
        const list: RandomBazaarModel[] = response.data.map((e: any)=>{
          const m: RandomBazaarModel = {
            id: e.id,
            link: `/bazaar/${e.id}`,
            name: Truncate(e.name, 25),
            location: e.location,
            imageId: e.image_id,
            productCount: e.product_count,
            openDay: e.openDay,
          }
          return m
        })
        setRandomBazaars(list)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [])

  // eslint-disable-next-line
  useEffect(()=>loadDistrict(), [districtId])

  return <>
    <section className="banner-section-three at-home18">
      <div className="auto-container">
        <div className="row align-items-center">
          {/* begin::Search */}
          <div className="content-column col-lg-7">
            <div className="inner-column">
              <div className="title-box wow fadeInUp">
                <h3>{intl.formatMessage({id: 'Bazaar prices from one place'})}</h3>
                <div className="text">{intl.formatMessage({id: 'products price review on every day'}, {count: productCount})}</div>
              </div>
              {/* begin::BazaarSearch */}
              <div className="job-search-form-two wow fadeInUp" data-wow-delay="200ms">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-lg-4 col-md-12 col-sm-12 location">
                      <label className="title">{intl.formatMessage({id: 'City'})}</label>
                      <Select
                        className='basic-single'
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        value={cityValue}
                        menuIsOpen={cityMenuOpen}
                        onChange={(s)=>{
                          if (!s) { s = InitialSelectOptionData }
                          setCityValue(s)
                          formik.setFieldValue('cityId', s.value)
                        }}
                        onMenuScrollToBottom={()=>{
                          setCityPage(1 + cityPage)
                        }}
                        onInputChange={(s, m)=> {
                          if (m.action === "input-change"){
                            setCitySearch(s)
                          }
                        }}
                        onMenuClose={()=>setCityMenuOpen(false)}
                        onMenuOpen={()=>{
                          setCityMenuOpen(true)
                          setCityHasNextPage(true)
                          setCityPage(1)
                          setCitySearch('')
                        }}
                        options={cityOptions}
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            flexWrap: 'nowrap',
                            height: '40px',
                          })
                        }}
                        placeholder={intl.formatMessage({id: "Toshket, Andijon, Farg'ona, ..."})}
                      />
                    </div>
                    {/* Form Group */}
                    <div className="form-group col-lg-5 col-md-12 col-sm-12">
                      <label className="title">{intl.formatMessage({id: 'District'})}</label>
                      <Select
                        className='basic-single'
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        value={districtValue}
                        menuIsOpen={districtMenuOpen}
                        onChange={(s)=>{
                          if (!s) { s = InitialSelectOptionData }
                          setDistrictValue(s)
                          formik.setFieldValue('districtId', s.value)
                        }}
                        onMenuScrollToBottom={()=>{
                          setDistrictPage(1 + districtPage)
                        }}
                        onInputChange={(s, m)=> {
                          if (m.action === "input-change"){
                            setDistrictSearch(s)
                          }
                        }}
                        onMenuClose={()=>setDistrictMenuOpen(false)}
                        onMenuOpen={()=>{
                          setDistrictMenuOpen(true)
                          setDistrictHasNextPage(true)
                          setDistrictPage(1)
                          setDistrictSearch('')
                        }}
                        options={districtOptions}
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            flexWrap: 'nowrap',
                            height: '40px',
                          })
                        }}
                      />
                    </div>
                    {/* Form Group */}
                    <div className="form-group col-lg-3 col-md-12 col-sm-12 btn-box">
                      <button
                        type="button"
                        className="theme-btn btn-style-one"
                        onClick={_=>formik.submitForm()}
                      >
                        <span className="btn-title">{intl.formatMessage({id: 'Find bazaar'})}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* end::BazaarSearch */}
            </div>
          </div>
          {/* end::Search */}
          {/* begin::RandomBazaars */}
          <div className="image-column col-lg-4 offset-lg-1">
            {/* Job Block */}
            {randomBazaars.map((e, i)=><div key={e.id} className={`job-block mb30${i % 2 ? ' mr-30 ml30 mt30' : ''}`}>
              <div className="inner-box">
                <div className="content">
                  <span className="company-logo">
                    <ImageView id={e.imageId} screen={'small'} className={"rounded-circle w50"}/>
                  </span>
                  <h4><Link to={e.link}>{e.name}</Link></h4>
                  <ul className="job-info">
                    <li><span className="icon flaticon-map-locator"></span>{e.location}</li>
                  </ul>
                  <ul className="job-other-info">
                    <li className="required">
                      {intl.formatMessage({id: 'products'}, {count: e.productCount})}
                    </li>
                  </ul>
                  <button type='button' className="bookmark-btn">
                    <span className="flaticon-bookmark"></span>
                  </button>
                </div>
              </div>
            </div>)}
          </div>
          {/* end::RandomBazaars */}
        </div>
      </div>
    </section>
  </>
}

export default SectionTree