import React, {useEffect, useState} from "react"
import {useIntl} from "react-intl";
import {Notify, usePageData} from "../../../layouts/core/PageData";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup"
import {useFormik} from "formik";
import {InitialSubscriberData, SubscriberDataModel} from "../models/SubscriberModel";
import {DateFormat} from "../../global/helpers/DateHelpers";
import clsx from "clsx";
import {subscriberDelete, subscriberOne, subscriberSave} from "../redux/subscriberCRUD";
import {Me} from "../../global/helpers/UserHelpers";

const ManageProfile: React.FC = () => {
  const user = Me()
  const intl = useIntl()
  const navigate = useNavigate()
  const {setNotifyPush} = usePageData()

  const validationScheme = Yup.object().shape({
    phone: Yup.string().required().matches(
      /^\+[0-9\-().\s]{12}$/,
      intl.formatMessage({'id': 'Length'}, {name: intl.formatMessage({'id': 'Phone'}), len: 13})
    ),
    fullName: Yup.string().optional()
      .min(3, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Full name'}), len: 3}))
      .max(150, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Full name'}), len: 150})),
    birth: Yup.date().required(),
    balance: Yup.number().required(),
    password: Yup.string().optional()
      .min(4, intl.formatMessage({'id': 'Min length'}, {name: intl.formatMessage({'id': 'Password'}), len: 4}))
      .max(20, intl.formatMessage({'id': 'Max length'}, {name: intl.formatMessage({'id': 'Password'}), len: 20})),
  })

  const [confirmPassword, setConfirmPassword  ] = useState('')
  const [confirmPasswordError, setConfirmPasswordError  ] = useState('')

  const formik = useFormik({
    initialValues: InitialSubscriberData,
    validationSchema: validationScheme,
    onSubmit: (values, {setSubmitting}) =>{
      setTimeout(()=>{
        console.log('save', values)
        subscriberSave(values).then(response =>{
          setSubmitting(false)
          const msg: Notify = {
            title: intl.formatMessage({id: 'Save'}),
            message: intl.formatMessage({id: 'Error'}),
            danger: true,
          }
          if ([200, 201].includes(response.status)) {
            msg.danger = false
            msg.message = intl.formatMessage({id: 'Success'})
            loadData()
          }
          setNotifyPush(msg)
        })
      }, 1000)
    }
  })

  const deleteHandler = () => {
    subscriberDelete([user.id]).then(response => {
      const msg: Notify = {
        title: intl.formatMessage({id: 'DELETE'}),
        message: intl.formatMessage({id: 'ERROR'}),
        danger: true,
      }
      if (response.status === 200) {
        msg.danger = false
        msg.message = intl.formatMessage({id: 'SUCCESS'})
        navigate('/')
      }
      setNotifyPush(msg)
    })
  }

  const loadData = () => {
    subscriberOne(user.modelId).then(response => {
      if (response.status === 200) {
        const data = response.data;
        const m: SubscriberDataModel = {
          id: data.id,
          userId: data.user_id,
          districtId: data.district_id,
          fullName: data.full_name ?? '',
          phone: data.phone,
          balance: data.balance,
          lon: parseFloat(data.lon.toString()),
          lat: parseFloat(data.lat.toString()),
          birth: DateFormat(data.birth),
        }
        formik.setValues(m)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(() => loadData(),[user])

  // eslint-disable-next-line
  useEffect(()=>{
    if (formik.values.password !== confirmPassword) {
      setConfirmPasswordError(intl.formatMessage({id: 'Password and confirm password has ben equals!'}))
    } else {
      setConfirmPasswordError('')
    }
  }, [formik.values.password, confirmPassword])

  return <section className="user-dashboard">
    <div className="dashboard-outer">
      <div className="row">
        <div className="col-lg-12">
          {/* Ls widget */}
          <div className="ls-widget">
            <div className="tabs-box">
              <div className="widget-title">
                <h4>{intl.formatMessage({id: 'My Profile'})}</h4>
              </div>

              <div className="widget-content">
                {/* begin::UploadFile */}
                {/*<div className="uploading-outer">
                  <div className="uploadButton">
                    <input className="uploadButton-input" type="file" name="attachments[]" accept="image/*, application/pdf" id="upload" multiple />
                    <label className="uploadButton-button ripple-effect" form="upload">Browse Logo</label>
                    <span className="uploadButton-file-name"></span>
                  </div>
                  <div className="text">Max file size is 1MB, Minimum dimension: 330x300 And Suitable files are .jpg & .png</div>
                </div>*/}
                {/* end::UploadFile */}
                <form onSubmit={formik.handleSubmit} className="default-form">
                  <div className="row">
                    {/* begin::Phone */}
                    <div className="form-group col-lg-6 col-md-12">
                      <label>{intl.formatMessage({id: 'Phone'})}</label>
                      <input
                        className={clsx('',
                          { 'is-invalid': formik.touched.phone && formik.errors.phone },
                          { 'is-valid': formik.touched.phone && !formik.errors.phone }
                        )}
                        type='text'
                        {...formik.getFieldProps('phone')}
                        readOnly={true}
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.phone}</span>
                        </div>
                      )}
                    </div>
                    {/* end::Phone */}
                    {/* begin::FullName */}
                    <div className="form-group col-lg-6 col-md-12">
                      <label>{intl.formatMessage({id: 'Full name'})}</label>
                      <input
                        className={clsx('',
                          { 'is-invalid': formik.touched.fullName && formik.errors.fullName },
                          { 'is-valid': formik.touched.fullName && !formik.errors.fullName }
                        )}
                        type='text'
                        {...formik.getFieldProps('fullName')}
                      />
                      {formik.touched.fullName && formik.errors.fullName && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.fullName}</span>
                        </div>
                      )}
                    </div>
                    {/* end::FullName */}
                    {/* begin::Birth */}
                    <div className="form-group col-lg-6 col-md-12">
                      <label>{intl.formatMessage({id: 'Birth'})}</label>
                      <input
                        className={clsx('form-control ',
                          { 'is-invalid': formik.touched.birth && formik.errors.birth },
                          { 'is-valid': formik.touched.birth && !formik.errors.birth }
                        )}
                        type='date'
                        {...formik.getFieldProps('birth')}
                      />
                      {formik.touched.birth && formik.errors.birth && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.birth}</span>
                        </div>
                      )}
                    </div>
                    {/* end::Birth */}
                    <div className="col-lg-6"></div>
                    {/* begin::Password */}
                    <div className="form-group col-lg-6 col-md-12">
                      <label>{intl.formatMessage({id: 'Password'})}</label>
                      <input
                        className={clsx('',
                          { 'is-invalid': formik.touched.password && formik.errors.password },
                          { 'is-valid': formik.touched.password && !formik.errors.password }
                        )}
                        type='password'
                        {...formik.getFieldProps('password')}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      )}
                    </div>
                    {/* end::Password */}
                    {/* begin::ConfirmPassword */}
                    <div className="form-group col-lg-6 col-md-12">
                      <label>{intl.formatMessage({id: 'Confirm password'})}</label>
                      <input
                        className={confirmPasswordError.length > 0 ? 'is-invalid' : 'is-valid'}
                        type='password'
                        value={confirmPassword}
                        onChange={({target})=>setConfirmPassword(target.value)}
                      />
                      {confirmPassword.length > 0 && confirmPasswordError.length > 0 ? (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{confirmPasswordError}</span>
                        </div>
                      ) : null}
                    </div>
                    {/* end::Password */}
                    {/* begin::SaveButton */}
                    <div className="form-group col-lg-6 col-md-12">
                      <button
                        type='submit'
                        className="theme-btn btn btn-primary"
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {intl.formatMessage({id: 'Save'})}
                      </button>
                    </div>
                    {/* end::SaveButton */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}

export default ManageProfile