import React from "react"
import {useIntl} from "react-intl"
import SVG from "react-inlinesvg"
import {useModal} from "../../../AppContext";

type Props = {
  callback(): void
}

const ModalConfirmDelete: React.FC<Props> = ({callback}) => {
  const intl = useIntl()
  const {setModal} = useModal()

  const closeHandler = (confirm: boolean) => {
    setModal(null)
    if (confirm) {
      callback()
    }
  }

  return <div style={{width: 300}}>
    <div className="form-group mb-3">
      <h5 className="fs-4">
        {intl.formatMessage({id: 'Are you sure'}, {action: intl.formatMessage({id: 'Delete'})})}
      </h5>
    </div>
    <span className='text-center text-muted mt-5'>
      {intl.formatMessage({id: 'Are you sure'}, {action: intl.formatMessage({id: 'Delete'}).toLowerCase()}
    )}?
    </span>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-light"
        data-bs-dismiss="modal"
        onClick={_=>closeHandler(false)}
      >
        {intl.formatMessage({id: 'Cancel'})}
      </button>
      <button
        type="button"
        className="btn btn-primary"
        onClick={_=>closeHandler(true)}
      >
        {intl.formatMessage({id: 'Confirm'})}
      </button>
    </div>
  </div>
}
export default ModalConfirmDelete;