import {IDataFilter} from "../../global/models/DataFilter";
import axios, {AxiosResponse} from "axios";
import * as querystring from "querystring";
import {ProductDataModel} from "../models/ProductModel";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'subscriber-product'
const LIST = `${API_URL}/private/${_RESOURCE}/list`
const IMAGES = `${API_URL}/private/${_RESOURCE}/images`
const BAZAARS = `${API_URL}/private/${_RESOURCE}/bazaars`
const ONE = `${API_URL}/private/${_RESOURCE}/one`
const GET = `${API_URL}/private/${_RESOURCE}/get`
const SAVE = `${API_URL}/private/${_RESOURCE}/save`
const DELETE = `${API_URL}/private/${_RESOURCE}/delete`

export function productList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    offer: filter.offer,
    search: filter.search,
    page: filter.page,
  }))
}

export function productImages(id: string) {
  return axios.post(IMAGES, querystring.stringify({id, screen: 'mobile'}))
}

export function productBazaars(filter: IDataFilter) {
  return axios.post(BAZAARS, querystring.stringify({
    district_id: filter.districtId,
    product_id: filter.productId,
    lan: filter.lan
  }))
}

export function productGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function productOne(id: string) {
  if (id && id.length === 36) {
    return axios.post(ONE, querystring.stringify({id}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function productSave(model: ProductDataModel) {
  let data: string|FormData
  if (model.files?.size && model.images) {
    data = new FormData()
    data.append('id', model.id)
    data.append('product_id', model.productId)
    data.append('district_id', model.districtId)
    data.append('type_id', model.typeId)
    data.append('status', model.status)
    data.append('offer', model.offer.toString())
    data.append('price', model.price)
    data.append('home', model.home.toString())
    data.append('phone', model.phone)
    data.append('pay_method', model.payMethod)
    data.append('quantity', model.quantity.toString())
    data.append('description', model.description)
    data.append('message', model.message)
    data.append('lon', model.lon.toString())
    data.append('lat', model.lat.toString())
    data.append('meta_keys', model.metaKeys)
    data.append('meta_desc', model.metaDesc)
    if (model.bazaars) {
      data.append('bazaar_ids', model.bazaars)
    }
    if (model.imageDeleteIds) {
      data.append('delete_images', JSON.stringify(model.imageDeleteIds))
    }
    data.append('images', model.images)
    model.files.forEach((v, k)=> {
      if (v) {
        (data as FormData).append(k, v)
      }
    })
  } else {
    data = querystring.stringify({
      id: model.id,
      product_id: model.productId,
      district_id: model.districtId,
      type_id: model.typeId,
      status: model.status,
      offer: model.offer,
      price: model.price,
      home: model.home,
      phone: model.phone,
      pay_method: model.payMethod,
      quantity: model.quantity,
      description: model.description,
      message: model.message,
      lon: model.lon,
      lat: model.lat,
      meta_keys: model.metaKeys,
      meta_desc: model.metaDesc,
      bazaar_ids: model.bazaars,
      delete_images: model.imageDeleteIds ? JSON.stringify(model.imageDeleteIds) : null,
    })
  }
  return axios.post(SAVE, data)
}

export function productDelete(ids: Array<string>) {
  return axios.post(`${DELETE}`, querystring.stringify({ ids: JSON.stringify(ids) }))
}
