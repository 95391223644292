import React, {useEffect, useState} from 'react'
import {imageThumb} from '../../home/redux/imageCRUD'

type Props = {
  id?: string
  screen: 'small'|'mobile'|'desktop'
  className?: string
}

type ImgModel = {
  src: string,
  title: string
}
const ImageView: React.FC<Props> = ({id, screen, className}) => {
  const [img, setImg] = useState<ImgModel>({src: '', title: ''})

  const loadData = () => {
    let src = '/images/no-image-1.png'
    if (id && id.length === 36) {
      imageThumb(id, screen).then(response=>{
        if (response.status === 200 && response.data.path.length > 36) {
          setImg({
            src: `${process.env.REACT_APP_API_URL}/uploads/images/${response.data.path}`,
            title: response.data.title
          })
        }
      })
    }
    setImg({src, title: 'no photo'})
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [id])

  return <img className={className} src={img.src} alt={img.title}/>
}
export default ImageView