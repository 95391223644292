import React, { useEffect, useState } from "react";
import { cityGet, cityList } from "../../cities/redux/cityCRUD";
import { CityModel } from "../../cities/models/CityModel";
import { InitialSelectOptionData, SelectOptionModel } from "../../global/models/SelectOptionModel";
import { districtGet, districtList } from "../redux/districtCRUD";
import { DistrictModel } from "../models/DistrictModel";
import Select from "react-select";
import { useIntl } from "react-intl"
import {IDataFilter} from "../../global/models/DataFilter";

interface Props {
  id: string
  callback(id: string): void
}

const DistrictSelectForm: React.FC<Props> = ({id, callback}) => {
  const intl = useIntl()

  // city
  const [citySearch, setCitySearch] = useState('')
  const [cityMenuOpen, setCityMenuOpen] = useState(false)
  const [cityHasNextPage, setCityHasNextPage] = useState(false)
  const [cityOptions, setCityOptions] = useState([InitialSelectOptionData,])
  const [cityPage, setCityPage] = useState(1)
  const [cityValue, setCityValue] = useState<SelectOptionModel>(InitialSelectOptionData)
  // district
  const [districtSearch, setDistrictSearch] = useState('')
  const [districtMenuOpen, setDistrictMenuOpen] = useState(false)
  const [districtHasNextPage, setDistrictHasNextPage] = useState(false)
  const [districtOptions, setDistrictOptions] = useState<SelectOptionModel[]>([])
  const [districtPage, setDistrictPage] = useState(1)
  const [districtValue, setDistrictValue] = useState<SelectOptionModel>(InitialSelectOptionData)

  const loadCity = (id: string) => {
    if (id && id.length === 36) {
      cityGet(id, intl.locale).then(response => {
        if (response.status === 200) {
          const data = response.data
          const m: CityModel = {
            id: data.id,
            name: data.name,
            slug: data.slug,
          }
          setCityValue({value: m.id, label: m.name, color: ''} as SelectOptionModel)
        }
      })
    }
  }

  const loadData = () => {
    if (id && id.length === 36) {
      districtGet(id, intl.locale).then(response => {
        if (response.status === 200) {
          const data = response.data
          const m: DistrictModel = {
            id: data.id,
            name: data.name,
            slug: data.slug,
            city: {id: data.city.id, name: data.city.name, slug: data.city.slug} as CityModel,
          }
          setDistrictValue({value: m.id, label: m.name, color: ''} as SelectOptionModel)
          if (m.city.id !== cityValue.value) {
            loadCity(m.city.id)
          }
        }
      })
    }
  }

  const loadCityOptions = () => {
    if (cityMenuOpen && cityHasNextPage) {
      const filter = {'page': cityPage, search: citySearch, lan: intl.locale} as IDataFilter
      cityList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setCityHasNextPage(cityPage <= totalPages)
          if ((cityPage === 1 || totalItems > cityOptions.length) && response.data.items) {
            const newItems = [
              ...(cityPage > 1 ? cityOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setCityOptions(newItems)
          }
        }
      })
    }
  }

  const loadDistrictOptions = () => {
    if (districtMenuOpen && districtHasNextPage) {
      const filter: IDataFilter = {'page': districtPage, search: districtSearch, lan: intl.locale, cityId: cityValue.value}
      districtList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setDistrictHasNextPage(districtPage <= totalPages)
          if ((districtPage === 1 || totalItems > districtOptions.length) && response.data.items) {
            const newItems = [
              ...(districtPage > 1 ? districtOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setDistrictOptions(newItems)
          }
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [id])

  // eslint-disable-next-line
  useEffect(()=>loadCityOptions(), [cityMenuOpen, cityPage, citySearch])

  // eslint-disable-next-line
  useEffect(()=>loadDistrictOptions(), [districtMenuOpen, districtPage, districtSearch, cityOptions])

  return <>
    {/* begin::SelectCity */}
    <div className='form-group col-lg-6 col-md-12'>
      <label>{intl.formatMessage({id: 'City'})}:</label>
      <Select
        className='basic-single'
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        value={cityValue}
        menuIsOpen={cityMenuOpen}
        onChange={(s)=>{
          if (!s) { s = InitialSelectOptionData }
          setCityValue(s)
        }}
        onMenuScrollToBottom={()=>{
          setCityPage(1 + cityPage)
        }}
        onInputChange={(s, m)=> {
          if (m.action === "input-change"){
            setCitySearch(s)
          }
        }}
        onMenuClose={()=>setCityMenuOpen(false)}
        onMenuOpen={()=>{
          setCityMenuOpen(true)
          setCityHasNextPage(true)
          setCityPage(1)
          setCitySearch('')
        }}
        options={cityOptions}
        styles={{
          control: (provided: any) => ({
            ...provided,
            flexWrap: 'nowrap',
            height: '60px',
          })
        }}
      />
      <div className='form-text text-muted'>
        {intl.formatMessage({id: 'Required'})}
      </div>
    </div>
    {/* end::SelectCity */}
    {/* begin::SelectDistrict */}
    <div className='form-group col-lg-6 col-md-12'>
      <label>{intl.formatMessage({id: 'District'})}:</label>
      <Select
        className='basic-single'
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        value={districtValue}
        menuIsOpen={districtMenuOpen}
        onChange={(s)=>{
          if (!s) { s = InitialSelectOptionData }
          setDistrictValue(s)
          callback(s.value)
        }}
        onMenuScrollToBottom={()=>{
          setDistrictPage(1 + districtPage)
        }}
        onInputChange={(s, m)=> {
          if (m.action === "input-change"){
            setDistrictSearch(s)
          }
        }}
        onMenuClose={()=>setDistrictMenuOpen(false)}
        onMenuOpen={()=>{
          setDistrictMenuOpen(true)
          setDistrictHasNextPage(true)
          setDistrictPage(1)
          setDistrictSearch('')
        }}
        options={districtOptions}
        styles={{
          control: (provided: any) => ({
            ...provided,
            flexWrap: 'nowrap',
            height: '60px',
          })
        }}
      />
      <div className='form-text text-muted'>
        {intl.formatMessage({id: 'Required'})}
      </div>
    </div>
    {/* end::SelectDistrict */}
  </>
}

export default DistrictSelectForm