import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {NumberFormat} from "../../global/helpers/ToHumansString";
import {Link, useNavigate} from "react-router-dom";
import {Truncate} from "../../global/helpers/StringHelper";
import {DateFormat} from '../../global/helpers/DateHelpers'
import {deliveryAds} from '../redux/deliveryAdsCRUD'

type GoodsAdsModel = {
  id: string
  vehicle: string
  curLocation: string
  desLocation: string
  link: string
  weight: number
  disDate: string
  desDate: string
}

type Props = {
  offer: boolean
}

const DeliveryAds: React.FC<Props> = ({offer}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [actualAdsCount, setActualAdsCount] = useState(0)
  const [list, setList] = useState<GoodsAdsModel[]>([])

  const loadData = () => {
    deliveryAds({offer, lan: intl.locale, perPage: 6}).then(response=>{
      if (response.status === 200) {
        const list = (response.data.items ?? []).map((e: any)=>{
          const m: GoodsAdsModel = {
            id: e.id,
            vehicle: e.vehicle,
            curLocation: `${e.cur_district.city.name}, ${e.cur_district.name}`,
            desLocation: e.des_district.length > 36 ? `${e.des_district.city.name}, ${e.des_district.name}` : '-',
            link: `/delivery-${offer ? 'cargo' : 'goods'}-ads/detail/${e.id}`,
            weight: Number(e.weight),
            disDate: DateFormat(e.dis_date),
            desDate: e.des_date.length === 20 ? DateFormat(e.des_date) : '-',
          }
          return m
        })
        setList(list)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [])
  return <section className="job-categories at-home18" style={{background: offer ? 'rgb(190, 199, 196)' : 'rgb(244, 231, 231)'}}>
    <div className="auto-container">
      <div className="d-sm-flex align-items-center justify-content-sm-between wow fadeInUp mb-4 mb-sm-0">
        <div className="sec-title">
          <h2>{intl.formatMessage({id: `Delivery ${offer ? 'cargo' : 'goods'} advertisements`})}</h2>
          <div className="text">{intl.formatMessage({id: 'actual ads count'}, {count: NumberFormat(actualAdsCount)})}</div>
        </div>
        <Link to={`/delivery-${offer ? 'cargo' : 'goods'}-ads`} className="ud-btn-border-theme at-home18 mb-4 dark-style">
          {intl.formatMessage({id: 'View all'})} <i className="fal fa-long-arrow-right"></i>
        </Link>
      </div>
      <div className="row wow fadeInUp">
        {list.map(e=><div key={e.id} className="col-xl-4 col-sm-6" onClick={_=>navigate(`/delivery-ads/detail/${e.id}`)}>
          <div className="job-block at-jlv17 at-home20 at-home18">
            <div className="inner-box">
              <div className="content ps-0">
                <h4 className="fz22 mb-2" style={{height: 60}}>
                  <Link to={`/delivery-ads/detail/${e.id}`}>{Truncate(e.vehicle, 50)}</Link>
                </h4>
                <ul className="job-other-info at-jsv6 at-jsv17 mt15 ms-0">
                  <li className="time2 fz15 border-0 ps-0 me-0 mb-0">
                    <i className="flaticon-pin pe-2"></i>{intl.formatMessage({id: 'Current location'})}: {Truncate(e.curLocation, 150)}
                  </li>
                </ul>
                <ul className="job-other-info at-jsv6 at-jsv17 mt-0 ms-0">
                  <li className="time2 fz15 border-0 ps-0 me-0 mb-0">
                    <i className="flaticon-time pe-2"></i>{intl.formatMessage({id: 'Dispatch date'})}: {e.disDate}
                  </li>
                </ul>
                <ul className="job-other-info at-jsv6 at-jsv17 ms-0">
                  <li className="time2 fz15 border-0 ps-0 me-0 mb-0">
                    <i className="flaticon-map-locator pe-2"></i>{intl.formatMessage({id: 'Destination location'})}: {Truncate(e.desLocation, 150)}
                  </li>
                </ul>
                {offer ? <ul className="job-other-info at-jsv6 at-jsv17 mt-0 ms-0 mb-5">
                  <li className="time2 fz15 border-0 ps-0 me-0 mb-0">
                    <i className="flaticon-time pe-2"></i>{intl.formatMessage({id: 'Destination date'})}: {e.desDate}
                  </li>
                </ul> : null}
                <div className="d-md-flex align-items-center justify-content-md-between mt30">
                  <div className="d-flex align-items-center mb-3 mb-md-0">
                    <span className="company-logo position-relative mr15">
                      <img className={'wa w60 rounded-circle'} src={'/images/delivery-goods-2.png'} title={e.vehicle}/>
                    </span>
                    <div className="text-start">
                      <h5 className="fz18 fw500">{NumberFormat(e.weight)}: {intl.formatMessage({id: 'Kg'})}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
    </div>
  </section>
}

export default DeliveryAds