import React from "react"
import {useIntl} from "react-intl";
import {useModal} from "../../../AppContext";
import {Link} from "react-router-dom";

const ChoiceAdsAdd: React.FC = () => {
  const intl = useIntl()
  const {setModal} = useModal()

  const closeModal = () => setModal(null)

  return <div style={{width: 200, height: 300}} className='pt-4 d-flex flex-column justify-content-around'>
    <span className='text-center text-muted'>
      {intl.formatMessage({id: 'Add advertisement'})}
    </span>
    <Link
      to={'/dashboard/product-seller-ads/add'}
      className='btn btn-primary w-100'
      onClick={closeModal}
    >
      {intl.formatMessage({id: 'Seller advertisement'})}
    </Link>
    <Link
      to={'/dashboard/product-buyer-ads/add'}
      className='btn btn-primary w-100'
      onClick={closeModal}
    >
      {intl.formatMessage({id: 'Buyer advertisement'})}
    </Link>
    <Link
      to={'/dashboard/delivery-cargo-ads/add'}
      className='btn btn-primary w-100'
      onClick={closeModal}
    >
      {intl.formatMessage({id: 'Delivery cargo advertisement'})}
    </Link>
    <Link
      to={'/dashboard/delivery-goods-ads/add'}
      className='btn btn-primary w-100'
      onClick={closeModal}
    >
      {intl.formatMessage({id: 'Delivery goods advertisement'})}
    </Link>
  </div>
}
export default ChoiceAdsAdd;