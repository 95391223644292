import {InitialProductType, ProductTypeModel} from '../../product-types/models/ProductTypeModel'
import {DistrictModel, InitialDistrict} from '../../districts/models/DistrictModel'
import {InitialSellerAdsProduct, SellerAdsProductModel} from './SellerAdsProductModel'

export type SellerAdsModel = {
  id: string
  price: number
  quantity: number
  phone: string
  imageId?: string
  createdAt: string
  updatedAt: string
  description?: string
  product: SellerAdsProductModel
  productType: ProductTypeModel
  district: DistrictModel
}

export const InitialSellerAds: SellerAdsModel = {
  id: '',
  price: 0,
  quantity: 0,
  phone: '',
  createdAt: '',
  updatedAt: '',
  product: InitialSellerAdsProduct,
  productType: InitialProductType,
  district: InitialDistrict,
}