
import React, {useEffect} from 'react'
import {toAbsoluteUrl} from "../global/helpers/AssetHelper";
import {Route, Routes} from 'react-router-dom'
import LoginForm from './components/login/LoginForm'
import RegisterForm from './components/register/RegisterForm'
import LoginPage from './LoginPage'
import RegisterPage from './RegisterPage'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return <Routes>
    <Route index element={<LoginPage/>}/>
    <Route path={'/login'} element={<LoginPage/>}/>
    <Route path={'/register'} element={<RegisterPage/>}/>
  </Routes>
}
