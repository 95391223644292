import React, {Component, createContext, JSX, ReactComponentElement, ReactElement, useContext} from 'react'

// language context
export const InitialLang = 'uz'

type LanguageProps =  { lang: 'uz' |'oz'|'ru'; setLang: React.Dispatch<React.SetStateAction<'uz'|'oz'|'ru'>>; }
export const LanguageContext = createContext<LanguageProps>({
  lang: InitialLang,
  setLang: value => {}
})

// search context
type SearchProps = { search: string }

// modal context
type ModalProps = {
  open: boolean,
  childNode: React.ReactNode,
  setModal(value: React.ReactNode): void
}
export const ModalContext = createContext<ModalProps>({
  open: false,
  childNode: null,
  setModal: (value) => {},
})

export const useModal = (): ModalProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}


export const useLang = (): LanguageProps => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLang must be used within a LangProvider');
  }
  return context
}


export const SearchContext = createContext<SearchProps>({search:''})
