import React, {ReactNode, useContext, useEffect} from 'react';
import './Modal.css';
import {useModal} from "../../../AppContext";


const Modal: React.FC<{ children: ReactNode }> = ({children}) => {
  const {setModal} = useModal()

  if (!children) return null;

  return (
    <div className="dialog-modal-overlay">
      <div className="dialog-modal-content">
        <button
          type='button'
          style={{position: 'absolute', right: 20, top: 10, zIndex: 1}}
          onClick={_=>setModal(null)}
        >
          <i className='fa fa-close'></i>
        </button>
        {children}
      </div>
    </div>
  )
};

export default Modal;
