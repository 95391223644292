import React from "react";
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import ShareLinks from './ShareLinks'

const MainFooter: React.FC = () => {
  const intl = useIntl()

  return <>
    <footer className="main-footer style-seven">
      <div className="auto-container">
        {/*Widgets Section*/}
        <div className="widgets-section">
          <div className="row">
            <div className="big-column col-xl-3 col-lg-3 col-md-12">
              <div className="footer-column about-widget">
                <div className="logo">
                  <Link to={'/'}>
                    <img src="/images/logo.svg" alt="logo" title="infobozor.uz" style={{height: 50}}/>
                  </Link>
                </div>
                <p className="phone-num"><span>{intl.formatMessage({id: 'Contact us'})} </span><a href="www.infobozor.uz">123 456 7890</a></p>
                <p className="address">{intl.formatMessage({id: 'Address full'})} <br/>
                  <a href="mailto:info@infobozor.uz" className="email">info@infobozor.uz</a>
                </p>
              </div>
            </div>
            <div className="big-column col-xl-9 col-lg-9 col-md-12">
              <div className="row">
                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">For Candidates</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li><a href="#">Browse Jobs</a></li>
                        <li><a href="#">Browse Categories</a></li>
                        <li><a href="#">Candidate Dashboard</a></li>
                        <li><a href="#">Job Alerts</a></li>
                        <li><a href="#">My Bookmarks</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">For Employers</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li><a href="#">Browse Candidates</a></li>
                        <li><a href="#">Employer Dashboard</a></li>
                        <li><a href="#">Add Job</a></li>
                        <li><a href="#">Job Packages</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-2 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">About Us</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li><a href="#">Job Page</a></li>
                        <li><a href="#">Job Page Alternative</a></li>
                        <li><a href="#">Resume Page</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Contact</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-4 col-md-12 col-sm-12">
                  <div className="footer-widget">
                    <h4 className="widget-title">Join Us On</h4>
                    <div className="widget-content">
                      <div className="newsletter-form">
                        <div className="text-white">We don’t send spam so don’t worry.</div>
                        {/*<form method="post" action="#" id="subscribe-form">*/}
                        {/*  <div className="form-group">*/}
                        {/*    <div className="response"></div>*/}
                        {/*  </div>*/}
                        {/*  <div className="form-group">*/}
                        {/*    <input type="email" name="email" className="email" value="" placeholder="Email" required/>*/}
                        {/*    <button type="button" onClick={()=>{}} id="subscribe-newslatters" className="theme-btn bg-dark"><i className="flaticon-envelope"></i></button>*/}
                        {/*  </div>*/}
                        {/*</form>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Bottom*/}
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="outer-box">
            <div className="copyright-text">© 2024 <a href="www.infobozor.uz">InfoBozor.uz</a>. All Right Reserved.</div>
            <div className="social-links">
              <ShareLinks title={'infobozor.uz'}/>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-to-top scroll-to-target" data-target="html"><span className="fa fa-angle-up"></span></div>
    </footer>
  </>
}

export default MainFooter