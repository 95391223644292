export interface CategoryModel {
  id: string
  title: string
  slug: string
}

export const InitialCategory:CategoryModel = {
  id: "",
  title: "",
  slug: "",
}