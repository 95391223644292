import React, {useEffect, useState} from 'react'
import MainHeader from '../../global/components/MainHeader'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {sellerAdsGet, sellerAdsImages} from '../redux/sellerAdsCRUD'
import {InitialSellerAds, SellerAdsModel} from '../models/SellerAdsModel'
import {SellerAdsProductCategoryModel} from '../models/SellerAdsProductCategoryModel'
import {SellerAdsProductDimensionModel} from '../models/SellerAdsProductDimensionModel'
import {SellerAdsProductModel} from '../models/SellerAdsProductModel'
import {ProductTypeModel} from '../../product-types/models/ProductTypeModel'
import {CityModel} from '../../cities/models/CityModel'
import {DistrictModel} from '../../districts/models/DistrictModel'
import {DateFormat} from '../../global/helpers/DateHelpers'
import {toImageUrl} from '../../global/helpers/AssetHelper'
import {ImageModel} from '../../model-images/models/ImageModel'

type Props = {
  id?: string
}

const SellerAdsDetail: React.FC<Props> = ({id}) => {
  const intl = useIntl()

  const [sellerAds, setSellerAds] = useState<SellerAdsModel>(InitialSellerAds)
  const [images, setImages] = useState<ImageModel[]>([])

  const loadData = () => {
    if (id && id.length === 36) {
      sellerAdsGet(id, intl.locale).then(response => {
        if (response.status === 200) {
          const data = response.data
          const m: SellerAdsModel = {
            id: data.id,
            price: Number(data.price),
            quantity: Number(data.quantity),
            phone: data.phone,
            createdAt: data.created_at,
            updatedAt: data.updated_at,
            description: data.description,
            product: {
              id: data.product.id,
              name: data.product.name,
              slug: data.product.slug,
              category: {
                id: data.product.category.id,
                title: data.product.category.title,
                slug: data.product.category.slug,
              } as SellerAdsProductCategoryModel,
              dimension: {
                id: data.product.dimension.id,
                name: data.product.dimension.name,
                slug: data.product.dimension.slug,
              } as SellerAdsProductDimensionModel,
            } as SellerAdsProductModel,
            productType: {
              id: data.product_type.id,
              name: data.product_type.name,
              slug: data.product_type.slug,
            } as ProductTypeModel,
            district: {
              id: data.district.id,
              name: data.district.name,
              slug: data.district.slug,
              city: {
                id: data.district.city.id,
                name: data.district.city.name,
                slug: data.district.city.slug,
              } as CityModel,
            } as DistrictModel,
          }
          setSellerAds(m)
        }
      })
      sellerAdsImages(id).then(response=>{
        let items: ImageModel[] = []
        if (response.status === 200) {
          items = response.data.items ? response.data.items.map((e: any) => {
            return {
              id: e.id,
              path: toImageUrl(e.path),
              pos: e.pos,
            } as ImageModel
          }) : []
        }
        setImages(items)
      })
    }
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [id])

  return <>
    {/* Header Span */}
    <span className="header-span"></span>

    {/* Main Header*/}
    <MainHeader/>
    {/*End Main Header */}

    {/* Detail Section */}
    <section className="job-detail-section">
      <div className="upper-box">
        <div className="auto-container">
          <div className="btn-box">
            <Link to={'/seller-ads'} className="theme-btn btn-style-one">{intl.formatMessage({id: 'Back to list'})}</Link>
          </div>
        </div>
      </div>

      <div className="job-detail-outer">
        <div className="auto-container">
          <div className="row">
            <div className="content-column col-lg-8 col-md-12 col-sm-12">
              <div className="job-detail">
                <h4>{sellerAds.product.name}</h4>
                <div className="gallery">
                  {images.map(e=><div className="gallery-item">
                    <img src={e.path} alt={e.pos.toString()}/>
                  </div>)}
                </div>
                <p>{sellerAds.description}</p>
              </div>
              <hr/>

              {/* Share buttons */}
              <div className="other-options">
                <div className="social-share">
                  <h5>{intl.formatMessage({id: 'Share'})}</h5>
                  <Link
                    className="facebook"
                    target='_blank'
                    to={`https://www.facebook.com/sharer/sharer.php?u=#${window.location}`}
                  >
                    <i className="fab fa-facebook-f"></i> Facebook
                  </Link>
                  <Link
                    to={`https://t.me/share/url?url=${window.location}&text=${sellerAds.product.name}`}
                    className="twitter"
                  >
                    <i className="fab fa-telegram"></i> Telegram
                  </Link>
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
              <aside className="sidebar">
                <div className="sidebar-widget">
                  {/* Job Overview */}
                  <h4 className="widget-title">{intl.formatMessage({id: 'Additional'})}</h4>
                  <div className="widget-content">
                    <ul className="job-overview">
                      <li>
                        <i className="icon flaticon-phone"></i>
                        <h5>{intl.formatMessage({id: 'Phone'})}:</h5>
                        <span>{sellerAds.phone}</span>
                      </li>
                      <li>
                        <i className="icon flaticon-time"></i>
                        <h5>{intl.formatMessage({id: 'Date'})}:</h5>
                        <span>{DateFormat(sellerAds.updatedAt)}</span>
                      </li>
                      <li>
                        <i className="icon flaticon-map-locator"></i>
                        <h5>{intl.formatMessage({id: 'Location'})}:</h5>
                        <span>{sellerAds.district.city.name}, {sellerAds.district.name}</span>
                      </li>
                    </ul>
                  </div>

                  {/* Map Widget */}
                  {/*<h4 className="widget-title">{intl.formatMessage({id: 'Location'})}</h4>*/}
                  {/*<div className="widget-content">*/}
                  {/*  <div className="map-outer">*/}
                  {/*    <div className="map-canvas" data-zoom="12" data-lat="-37.817085" data-lng="144.955631"*/}
                  {/*         data-type="roadmap" data-hue="#ffc400" data-title="Envato"*/}
                  {/*         data-icon-path="images/resource/map-marker.png"*/}
                  {/*         data-content="Melbourne VIC 3000, Australia<br><a href='mailto:info@youremail.com'>info@youremail.com</a>">*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* End Detail Section */}
  </>
}
export default SellerAdsDetail