export interface ProductTypeModel {
  id: string
  name: string
  slug: string
}

export const InitialProductType:ProductTypeModel = {
  id: "",
  name: "",
  slug: "",
}