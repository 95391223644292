import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  showing: number
  total: number
  loadMore(): void
}
const ListPaginate: React.FC<Props> = ({showing, total, loadMore}) => {
  const intl = useIntl()

  return <div className="ls-show-more">
    <p>{intl.formatMessage({id: 'showing of items'}, {showing, total})}</p>
    <div className="bar"><span className="bar-inner" style={{width: `${showing/(total/100) }%`}}></span></div>
    {showing < total ? <button
      className="show-more"
      onClick={loadMore}
    >{intl.formatMessage({id: 'Show more'})}</button> : null}
  </div>
}
export default ListPaginate