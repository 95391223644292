import { CityModel, InitialCity } from "../../cities/models/CityModel";

export interface DistrictModel {
  id: string
  name: string
  slug: string
  city: CityModel
}

export interface DistrictDataModel {
  id: string
  cityId: string
  nameUz: string
  nameOz: string
  nameRu: string
  slugUz: string
  slugOz: string
  slugRu: string
}

export const InitialDistrict:DistrictModel = {
  id: "",
  name: "",
  slug: "",
  city: InitialCity
}

export const InitialDistrictData:DistrictDataModel = {
  id: '',
  cityId: '',
  nameUz: '',
  nameOz: '',
  nameRu: '',
  slugUz: '',
  slugOz: '',
  slugRu: '',
}