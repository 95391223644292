import React from "react"
import {useParams} from "react-router-dom";
import DeliveryAdsForm from "./components/DeliveryAdsForm";

type Props = {
  offer: boolean
}

const DeliveryAdsFormPage: React.FC<Props> = ({offer}) => {
  const {id} = useParams<{id: string}>()

  return <DeliveryAdsForm id={id} offer={offer} />
}
export default DeliveryAdsFormPage;