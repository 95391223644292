import axios, { AxiosResponse } from "axios"
import * as querystring from "querystring"
import {IDataFilter} from "../../global/models/DataFilter";
const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'district'
const LIST = `${API_URL}/${_RESOURCE}/list`
const GET = `${API_URL}/${_RESOURCE}/get`


export function districtList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    search: filter.search,
    page: filter.page,
    city_id: filter.cityId,
  }))
}

export function districtGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}
