import {InitialSellerAdsProductCategory, SellerAdsProductCategoryModel} from './SellerAdsProductCategoryModel'
import {InitialSellerAdsProductDimension, SellerAdsProductDimensionModel} from './SellerAdsProductDimensionModel'

export type SellerAdsProductModel = {
  id: string
  name: string
  slug: string
  category: SellerAdsProductCategoryModel
  dimension: SellerAdsProductDimensionModel
}

export const InitialSellerAdsProduct: SellerAdsProductModel = {
  id: '',
  name: '',
  slug: '',
  category: InitialSellerAdsProductCategory,
  dimension: InitialSellerAdsProductDimension,
}