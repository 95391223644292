import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {deliveryList} from "../redux/deliveryCRUD";
import {DeliveryModel} from "../models/DeliveryModel";
import {SubscriberModel} from "../../subscribers/models/SubscriberModel";
import {CityModel} from "../../cities/models/CityModel";
import {DistrictModel} from "../../districts/models/DistrictModel";
import {UserModel} from "../../subscribers/models/UserModel";
import {usePageData} from "../../../layouts/core/PageData";
import {IDataFilter} from "../../global/models/DataFilter";
import IListData, {InitialIListData} from "../../global/models/ListModel";
import TablePaginate from "../../global/components/TablePaginate";
import {NumberFormat} from "../../global/helpers/ToHumansString";

type Props = {
  offer: boolean
}

const DeliveryAdsTable: React.FC<Props> = ({offer}) => {
  const intl = useIntl()
  const {setNotifyPush} = usePageData()

  const [filter, setFilter] = useState({} as IDataFilter)
  const [listData, setListData] = useState<IListData<DeliveryModel>>(InitialIListData)

  const loadData = () => {
    filter.offer = offer
    deliveryList(filter).then(response => {
      let result: IListData<DeliveryModel>  = {totalItems: 0, page: 0, totalPages: 0, items: []}
      if (response.status === 200) {
        result.items = response.data.items ? response.data.items.map((e: any) => {
          const res: DeliveryModel = {
            id: e.id,
            vehicle: e.vehicle,
            weight: e.weight,
            disDate: e.dis_date,
            desDate: e.des_date,
            phone: e.phone,
            offer: e.offer,
            approved: e.approved === 'true',
            description: e.description,
            createdAt: e.created_at,
            updatedAt: e.updated_at,
            subscriber: {
              id : e.subscriber.id,
              balance : e.subscriber.balance,
              birth : e.subscriber.birth,
              lon : e.subscriber.lon,
              lat : e.subscriber.lat,
              user: {
                id: e.subscriber.user.id,
                fullName: e.subscriber.user.full_name,
                phone: e.subscriber.user.phone,
              } as UserModel
            } as SubscriberModel,
            curDistrict: {
              id: e.cur_district.id,
              name: e.cur_district.name,
              slug: e.cur_district.slug,
              city: {
                id: e.cur_district.city.id,
                name: e.cur_district.city.name,
                slug: e.cur_district.city.slug
              } as CityModel
            } as DistrictModel,
            desDistrict: {
              id: e.cur_district.id,
              name: e.cur_district.name,
              slug: e.cur_district.slug,
              city: {
                id: e.cur_district.city.id,
                name: e.cur_district.city.name,
                slug: e.cur_district.city.slug
              } as CityModel
            } as DistrictModel,
          }
          return res
        }) : []
        result.totalItems = response.data.paginate.total_items
        result.page = response.data.paginate.page
        result.totalPages = response.data.paginate.total_pages

        setListData(result)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [filter, offer])

  return (
    <>
      <div className="col-lg-12">
        {/* Ls widget */}
        <div className="ls-widget">
          <div className="tabs-box">
            <div className="widget-title">
              <h4>{intl.formatMessage({id: `Delivery ${offer ? 'cargo' : 'goods'} advertisements`})}</h4>

              <div className="chosen-outer">
                <Link
                  className='btn btn-primary mx-2'
                  to={`/dashboard/delivery-${offer ? 'cargo' : 'goods'}-ads/add`}
                >
                  {intl.formatMessage({id: 'Add'})}
                </Link>
              </div>
            </div>

            <div className="widget-content">
              <div className="table-outer">
                <table className="default-table manage-job-table">
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({id: 'Vehicle'})}</th>
                      <th>{intl.formatMessage({id: 'Status'})}</th>
                      <th>{intl.formatMessage({id: 'Type'})}</th>
                      <th>{intl.formatMessage({id: 'Weight'})}</th>
                      <th>{intl.formatMessage({id: 'Action'})}</th>
                    </tr>
                  </thead>

                  <tbody>
                  {listData.items.map(e=><tr key={e.id}>
                    <td>
                      <h6>{e.vehicle}</h6>
                      <span className="info"><i className="icon flaticon-map-locator"></i> {`${e.curDistrict.city.name}, ${e.curDistrict.name}`}</span>
                    </td>
                    <td>{intl.formatMessage({id: e.approved ? 'Active' : 'Consideration'})}</td>
                    <td>{intl.formatMessage({id: e.offer ? 'Sale' : 'Need'})}</td>
                    <td>{NumberFormat(e.weight)}</td>
                    <td>
                      <div className="option-box">
                        <ul className="option-list">
                          <li>
                            <Link to={`/dashboard/delivery-${offer ? 'cargo' : 'goods'}-ads/edit/${e.id}`} data-text={intl.formatMessage({id: 'Edit'})}>
                              <span className="fa fa-edit"></span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>)}
                  </tbody>
                </table>
                {/* begin::Paginate */}
                <TablePaginate
                  total={listData.items.length}
                  pageCount={listData.totalPages}
                  currentPage={listData.page}
                  setPage={page=>setFilter({...filter, page})}
                />
                {/* end::Paginate */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {DeliveryAdsTable}
