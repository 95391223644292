import axios, { AxiosResponse } from "axios"
import * as querystring from "querystring"
import { DeliveryDataModel } from "../models/DeliveryModel"
import {DateFormat} from "../../global/helpers/DateHelpers";
import {IDataFilter} from "../../global/models/DataFilter";
const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'delivery'
const LIST = `${API_URL}/private/${_RESOURCE}/list`
const GET = `${API_URL}/private/${_RESOURCE}/get`
const ONE = `${API_URL}/private/${_RESOURCE}/one`
const SAVE = `${API_URL}/private/${_RESOURCE}/save`
const DELETE = `${API_URL}/private/${_RESOURCE}/delete`


export function deliveryList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    offer: filter.offer,
    search: filter.search,
    page: filter.page,
  }))
}

export function deliveryGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function deliveryOne(id: string) {
  if (id && id.length === 36) {
    return axios.post(ONE, querystring.stringify({id}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function deliverySave(model: DeliveryDataModel) {
  return axios.post(SAVE, querystring.stringify({
    id: model.id,
    subscriber_id: model.subscriberId,
    cur_district_id: model.curDistrictId,
    des_district_id: model.desDistrictId,
    vehicle: model.vehicle,
    weight: model.weight,
    dis_date: DateFormat(model.disDate),
    des_date: DateFormat(model.desDate),
    offer: model.offer,
    ads_days: model.adsDays,
    description: model.description,
  }))
}

export function deliveryDelete(ids: Array<string>) {
  return axios.post(DELETE, querystring.stringify({ ids: JSON.stringify(ids) }))
}
