import React from "react"
import ChoicePayMethod from "./components/ChoicePayMethod";
import {useParams} from "react-router-dom";
import {PaymentModel} from "./models/PaymentModel";

type RouteParams = {
  id?: string
  name?: string
}

const PaymentPage: React.FC = () =>{
  const {id, name} = useParams<RouteParams>()

  const model: PaymentModel|undefined = id && name ? {id, name} as PaymentModel : undefined

  return <ChoicePayMethod model={model}/>
}

export default PaymentPage