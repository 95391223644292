import React from 'react'
import {Route, Routes} from 'react-router-dom'
import BazaarList from './components/BazaarList'

const BazaarPage: React.FC = () => {

  return <Routes>
      <Route index element={<BazaarList/>}/>
      <Route path='/list' element={<BazaarList/>}/>
  </Routes>
}
export default BazaarPage