import React, {useEffect, useState} from "react"
import {PaymentModel} from "../models/PaymentModel"
import {useIntl} from "react-intl"
import {paymentInitModel, paymentInitPay, paymentWithBalance} from "../redux/paymentCRUD"
import SVG from "react-inlinesvg"
import {Notify, usePageData} from "../../../layouts/core/PageData"
import {useNavigate} from "react-router-dom"
import {NumberFormat} from "../../global/helpers/ToHumansString"

type Props = {
  model?: PaymentModel
}

const ChoicePayMethod: React.FC<Props> = ({model}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const {setNotifyPush} = usePageData()

  const [amount, setAmount] = useState('1000')
  const [loading, setLoading] = useState(true)

  const payInitHandler = (provider: 'PayMe'|'Click') => {
    paymentInitPay(provider, model, Number(amount)).then(response=>{
      if (response.status === 200) {
        switch (response.data.payment_provider) {
          case 'PayMe':
            window.open(`https://checkout.paycom.uz/${response.data.hash}`)
            break
          case 'Click':
            const data = response.data
            window.open(`https://my.click.uz/services/pay?service_id=${data.service_id}&merchant_id=${data.merchant_id}&amount=${data.amount}&transaction_param=${data.transaction_param}&return_url=${data.return_url}`)
            break
        }
      }
    })
  }

  const payWithBalanceHandler = () => {
    if (model) {
      paymentWithBalance(model).then(response=>{
        const notify: Notify = {
          title: intl.formatMessage({id: 'Pay'}),
          message: intl.formatMessage({id: 'Error'}),
          danger: true,
        }
        if (response.status === 200) {
          notify.danger = false
          notify.message = intl.formatMessage({id: 'Success'})
          setNotifyPush(notify)
          toBack()
        }
      })
    }
  }

  const toBack = () => {
    if (model) {
      navigate(`/dashboard/${model.name}/edit/${model.id}`)
    } else {
      navigate(`/dashboard/`)
    }
  }

  const loadData = () => {
    setLoading(true)
    if (model) {
      paymentInitModel(model).then(response=>{
        if (response.status === 200 && response.data.id?.length === 36) {
          setAmount(response.data.amount?.toString())
          setLoading(false)
        } else {
          toBack()
        }
      })
    }
  }

  useEffect(()=>loadData(), [model])

  return <div className='row' style={{minHeight: 400}}>
    {!loading ? <>
      <span className='w-100 text-center fw-bolder fs-2 my-5 text-muted'>{intl.formatMessage({id: 'Choice pay method'})}</span>
      <div className="d-flex justify-content-center flex-column align-items-center">
        {/* begin::Amount */}
        {model ? <>
          <span className='w-100 text-center fw-bolder fs-3 my-5 text-muted'>
            {NumberFormat(Number(amount))} {intl.formatMessage({id: 'CurUz'})}
          </span>
        </> : null}
        {/*</div>*/}
        {/* end::Amount */}
        {/* begin::PayMeButton */}
        <button
          className='btn btn-secondary fw-bold mb-5'
          style={{width: 200, height: 50}}
          onClick={_=>payInitHandler('PayMe')}
        >
          <SVG src={'/icons/payme_color.svg'} height={30} />
        </button>
        {/* end::PayMeButton */}
        {/* begin::ClickButton */}
        <button
          className='btn btn-dark fw-bold mb-5'
          style={{width: 200, height: 50}}
          onClick={_=>payInitHandler('Click')}
        >
          <SVG src={'/icons/click-logo.svg'} height={30} />
        </button>
        {/* end::ClickButton */}
      </div>
    </> : null}
  </div>
}
export default ChoicePayMethod;