export type SellerAdsProductCategoryModel = {
  id: string
  title: string
  slug: string
}

export const InitialSellerAdsProductCategory: SellerAdsProductCategoryModel = {
  id: '',
  title: '',
  slug: '',
}