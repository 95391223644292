import React, {useEffect, useState} from "react";
import Select from "react-select";
import {InitialSelectOptionData, SelectOptionModel} from "../../global/models/SelectOptionModel";
import {useIntl} from "react-intl";
import {globalProductGet, globalProductList} from "../../global-products/redux/globalProductCRUD";
import {GlobalProductModel, InitialGlobalProduct} from "../../global-products/models/GlobalProductModel"
import {CategoryModel, InitialCategory} from "../../categories/models/CategoryModel";
import {DimensionModel, InitialDimension} from "../../dimensions/models/DimensionModel";
import {FileProduct} from "../models/DownloadFileModel"
import {IDataFilter} from '../../global/models/DataFilter'
import {productGet} from '../../products/redux/productCRUD'
import {productTypeList} from '../../product-types/redux/productTypeCRUD'
import {productTypeGet} from '../../product-ads/redux/productTypeCRUD'

type Props = {
  index: number
  readonly: boolean
  withQuantity: boolean
  items: FileProduct[]
  setItem(i: number, v: FileProduct): void
  setAllowAdd(value: boolean): void
}

const DownloadFileProductItem:React.FC<Props> = ({index, readonly, setAllowAdd, withQuantity, setItem, items}) => {
  const intl = useIntl()
  // product
  const [product, setProduct] = useState(InitialGlobalProduct)
  const [search, setSearch] = useState('')
  const [hasNextPage, setHasNextPage] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [options, setOptions] = useState([InitialSelectOptionData])
  const [page, setPage] = useState(1)
  const [value, setValue] = useState(InitialSelectOptionData)
  // product type
  const [typeSearch, setTypeSearch] = useState<string>('')
  const [typeHasNextPage, setTypeHasNextPage] = useState<boolean>(false)
  const [typeMenuOpen, setTypeMenuOpen] = useState<boolean>(false)
  const [typeOptions, setTypeOptions] = useState<SelectOptionModel[]>([])
  const [typePage, setTypePage] = useState<number>(1)
  const [typeValue, setTypeValue] = useState<SelectOptionModel>(InitialSelectOptionData)
  //
  const [id, setId] = useState('')
  const [typeId, setTypeId] = useState('')
  const [quantity, setQuantity] = useState('')
  const [has, setHas] = useState(true)

  const loadProduct = (id: string) => {
    globalProductGet(id, intl.locale).then(r=>{
      if (r.status === 200) {
        const dimension = r.data.dimension ?? InitialDimension
        const category = r.data.category ?? InitialCategory
        const m: GlobalProductModel = {
          id: r.data.id,
          name: r.data.name,
          slug: r.data.slug,
          dimension: {id: dimension.id, name: dimension.name, slug: dimension.slug} as DimensionModel,
          category: { id: category.id, title: category.title, slug: category.slug } as CategoryModel
        }
        setProduct(m)
        setValue({value: m.id, label: m.name, color: ''})
      }
    })
  }

  const loadType = (id: string) => {
    productTypeGet(id, intl.locale).then(r=>{
      if (r.status === 200) {
        const dimension = r.data.dimension ?? InitialDimension
        const category = r.data.category ?? InitialCategory
        const m: GlobalProductModel = {
          id: r.data.id,
          name: r.data.name,
          slug: r.data.slug,
          dimension: {id: dimension.id, name: dimension.name, slug: dimension.slug} as DimensionModel,
          category: { id: category.id, title: category.title, slug: category.slug } as CategoryModel
        }
        setTypeValue({value: m.id, label: m.name, color: ''})
      }
    })
  }

  const loadProductOptions = () => {
    if (menuOpen && hasNextPage) {
      const filter = {page: page, search, except: items.map(e=>e.id)} as IDataFilter
      globalProductList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setHasNextPage(page < totalPages)
          if ((page === 1 || totalItems > options.length) && response.data.items) {
            const newItems = [
              ...(page > 1 ? options : []),
              ...response.data.items.map((e: any) => {
                return {value: e.id, label: e.name, color: ''}
              })
            ]

            setOptions(newItems)
          }
        }
      })
    }
  }

  const loadTypeOptions = () => {
    if (typeMenuOpen && typeHasNextPage) {
      const filter = {page: typePage, search: typeSearch} as IDataFilter
      productTypeList(filter).then(r => {
        if (r.status === 200) {
          const totalItems: Number = r.data.paginate.total_items
          const totalPages: Number = r.data.paginate.total_pages
          setTypeHasNextPage(typePage < totalPages)
          if ((typePage === 1 || totalItems > options.length) && r.data.items) {
            const newItems = [
              ...(typePage > 1 ? typeOptions : []),
              ...r.data.items.map((e: any) => {
                return {value: e.id, label: e.name, color: ''}
              })
            ]

            setTypeOptions(newItems)
          }
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadProductOptions(), [menuOpen, page, search])

  // eslint-disable-next-line
  useEffect(()=>loadTypeOptions(), [typeMenuOpen, typePage, typeSearch])

  // eslint-disable-next-line
  useEffect(()=>loadProduct(id), [id])

  // eslint-disable-next-line
  useEffect(()=>loadType(typeId), [typeId])

  useEffect(()=>setAllowAdd((!withQuantity || Number(quantity) > 0) && typeId.length === 36), [quantity, typeId])

  useEffect(()=>{
    const item = items[index]
    setId(item.id)
    setTypeId(item.typeId)
    setQuantity(item.quantity.toString())
    setHas(item.has)
  }, [index])

  useEffect(()=>{
    const item = items[index]
    const q = Number(quantity)
    if (id.length === 36 && typeId.length === 36 && (!withQuantity || (q > 0 && item.quantity !== q))) {
      setItem(index, {id, typeId, quantity: q, has: true})
    }
  }, [id, typeId, quantity])

  return <div className='col-lg-12 col-xl-12 mb-4'>
    {/* being::SelectProduct */}
    <div className='row mb-10'>
      {/* begin::SelectProduct */}
      <div className='col-lg-5 col-xl-5'>
        <Select
          className='basic-single'
          classNamePrefix="select"
          isDisabled={readonly}
          isClearable={true}
          isSearchable={true}
          value={value}
          menuIsOpen={menuOpen}
          onChange={(s, {action})=>{
            if (!s) { s = InitialSelectOptionData }
            setValue(s)
            setId(s.value)
          }}
          onMenuScrollToBottom={()=>{
            setPage(1 + page)
          }}
          onInputChange={(s, m)=> {
            if (m.action === "input-change"){
              setSearch(s)
            }
          }}
          onMenuClose={()=> {
            setAllowAdd(options.length > 1)
            setMenuOpen(false)
            setTypeValue({value: '', label: '', color: ''})
            setQuantity('0')
            setItem(index, {id, typeId: '', quantity: 0, has: true})
          }}
          onMenuOpen={()=>{
            setMenuOpen(true)
            setHasNextPage(true)
            setOptions([])
            setPage(1)
            setSearch('')
          }}
          options={options}
        />
      </div>
      {/* end::SelectProduct */}
      {/* begin::SelectProductType */}
      <div className='col-lg-3 col-xl-3'>
        <Select
          className='basic-single'
          classNamePrefix="select"
          isDisabled={readonly}
          isClearable={true}
          isSearchable={true}
          value={typeValue}
          menuIsOpen={typeMenuOpen}
          onChange={(s, {action})=>{
            if (!s) { s = InitialSelectOptionData }
            setTypeValue(s)
            setTypeId(s.value)
          }}
          onMenuScrollToBottom={()=>{
            setPage(1 + page)
          }}
          onInputChange={(s, m)=> {
            if (m.action === "input-change"){
              setTypeSearch(s)
            }
          }}
          onMenuClose={()=> {
            setAllowAdd(options.length > 1)
            setTypeMenuOpen(false)
          }}
          onMenuOpen={()=>{
            setTypeMenuOpen(true)
            setTypeHasNextPage(true)
            setTypeOptions([])
            setTypePage(1)
            setTypeSearch('')
          }}
          options={typeOptions}
        />
      </div>
      {/* end::SelectProductType */}

      {/* begin::Quantity */}
      {withQuantity && id.length === 36 ? <>
        <label className='col-lg-2 col-form-label text-lg-end'>
          {`${intl.formatMessage({id: `Quantity`})} (${product.dimension.name})`}:
        </label>
        <div className='col-lg-2 col-xl-2'>
          <input
            className='me-2 text-center form-control'
            type='number'
            readOnly={readonly}
            disabled={value.value?.length !== 36 || readonly}
            value={quantity}
            onChange={({target})=>setQuantity(target.value)}
          />
        </div>
      </>: null}
      {/* end::Quantity */}
    </div>
    {/* end::SelectProduct */}
    {!has ? <div className='form-text text-danger'>
      {intl.formatMessage({id: 'This product is not available in one or more bazaars'})}
    </div> : null}
  </div>
}

export default DownloadFileProductItem