import React, {useEffect, useState} from "react"
import * as Yup from "yup"
import ModalConfirmDelete from "../../global/components/ModalConfirmDelete"
import {useIntl} from "react-intl";
import ModelImages from "../../model-images/components/ModelImages";
import {ImageModel} from "../../model-images/models/ImageModel";
import {Notify, usePageData} from "../../../layouts/core/PageData";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {InitialProductData, ProductDataModel} from "../models/ProductModel";
import {productDelete, productImages, productOne, productSave} from "../redux/productCRUD";
import {globalProductGet, globalProductList} from "../../global-products/redux/globalProductCRUD";
import {InitialSelectOptionData, SelectOptionModel} from "../../global/models/SelectOptionModel";
import {productTypeGet, productTypeList} from "../../product-types/redux/productTypeCRUD";
import {ProductTypeModel} from "../../product-types/models/ProductTypeModel";
import {toImageUrl} from "../../global/helpers/AssetHelper";
import {IDataFilter} from "../../global/models/DataFilter";
import Select, {MultiValue} from "react-select";
import DistrictSelectForm from "../../districts/components/DistrictSelectForm";
import clsx from "clsx";
import BazaarsSelect from "../../bazaars/components/BazaarsSelect";
import {GlobalProductModel, InitialGlobalProduct} from "../../global-products/models/GlobalProductModel";
import PayButton from "../../Payment/components/PayButton";
import {useModal} from "../../../AppContext";

type Props = {
  id?: string
  offer: boolean
}

const ProductAdsForm: React.FC<Props> = ({id, offer}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {setNotifyPush} = usePageData()
  const {setModal} = useModal()

  const validationScheme = Yup.object().shape({
    id: Yup.string().length(36),
    productId: Yup.string().required().length(36),
    districtId: Yup.string().required().length(36),
    typeId: Yup.string().required().length(36),
    price: Yup.number().required(),
    quantity: Yup.number().required()
      .min(1, intl.formatMessage({id: 'Min length'}, {name: intl.formatMessage({id: 'Quantity'}), len: 1})),
    metaKeys: Yup.string().min(3),
    metaDesc: Yup.string().min(3),
    lon: Yup.number().optional(),
    lan: Yup.number().optional(),
    phone: Yup.string().optional(),
    payMethod: Yup.string().optional(),
    description: Yup.string().optional(),
    message: Yup.string().optional(),
  })

  const [images, setImages] = useState<Array<ImageModel>>([])
  // bazaar
  const [selectedBazaars, setSelectedBazaars] = useState<MultiValue<SelectOptionModel>>([])
  // product
  const [productSearch, setProductSearch] = useState('')
  const [productMenuOpen, setProductMenuOpen] = useState(false)
  const [productHasNextPage, setProductHasNextPage] = useState(false)
  const [productOptions, setProductOptions] = useState([InitialSelectOptionData])
  const [productPage, setProductPage] = useState(1)
  const [productValue, setProductValue] = useState<SelectOptionModel>(InitialSelectOptionData)
  // product type
  const [typeSearch, setTypeSearch] = useState('')
  const [typeMenuOpen, setTypeMenuOpen] = useState(false)
  const [typeHasNextPage, setTypeHasNextPage] = useState(false)
  const [typeOptions, setTypeOptions] = useState([InitialSelectOptionData,])
  const [typePage, setTypePage] = useState(1)
  const [typeValue, setTypeValue] = useState<SelectOptionModel>(InitialSelectOptionData)
  // pay method
  const [payMethodMenuOpen, setPayMethodMenuOpen] = useState(false)
  const [payMethodOptions, setPayMethodOptions] = useState([InitialSelectOptionData,])
  const [payMethodValue, setPayMethodValue] = useState<SelectOptionModel>(InitialSelectOptionData)

  const formik = useFormik({
    initialValues: InitialProductData,
    validationSchema: validationScheme,
    onSubmit: (values, {setSubmitting}) =>{
      setTimeout(()=>{
        // update images
        const imageFiles = new Map<string, File>()
        let imagesStr  = '{'
        for (let i = 0; i < images.length; i++) {
          const image = images[i]
          const pos = image.pos.toString()
          imagesStr = `${imagesStr}"${pos}":${JSON.stringify({id: image.id})},`
          if (image.file) {
            imageFiles.set(`file-${pos}`, image.file)
          }
        }
        if (imagesStr.length > 2) {
          values.images = `${imagesStr.substring(0, imagesStr.length-1)}}`
          values.files = imageFiles
        }
        // update bazaars
        if (selectedBazaars.length > 0) {
          values.bazaars = JSON.stringify(selectedBazaars.map<string>(e=>e.value))
        }
        //
        values.offer = offer
        productSave(values).then(response =>{
          setSubmitting(false)
          const msg: Notify = {
            title: intl.formatMessage({id: 'Save'}),
            message: intl.formatMessage({id: 'Error'}),
            danger: true,
          }
          if ([200, 201].includes(response.status)) {
            msg.danger = false
            msg.message = intl.formatMessage({id: 'Success'})
            navigate(`/dashboard/product-${offer ? 'seller' : 'buyer'}-ads/edit/${response.data.id}`)
          }
          setNotifyPush(msg)
        })
      }, 1000)
    }
  })

  const deleteCallback = () => {
    if (id && id.length === 36) {
      productDelete([id]).then(response => {
        const msg: Notify = {
          title: intl.formatMessage({id: 'Delete'}),
          message: intl.formatMessage({id: 'Error'}),
          danger: true,
        }
        if (response.status === 200) {
          msg.danger = false
          msg.message = intl.formatMessage({id: 'Success'})
          navigate('/dashboard/product-ads')
        }
        setNotifyPush(msg)
      })
    }

  }

  const deleteHandler = () => {
    setModal(<ModalConfirmDelete callback={deleteCallback}/>)
  }

  const loadProduct = (id: string) => {
    if (id && id.length === 36) {
      globalProductGet(id, intl.locale).then(response => {
        if (response.status === 200) {
          const data = response.data
          const m: GlobalProductModel = {
            ...InitialGlobalProduct,
            id: data.id,
            name: data.name,
            slug: data.slug,
          }
          setProductValue({value: m.id, label: m.name, color: ''} as SelectOptionModel)
        }
      })
    }
  }

  const loadType = (id: string) => {
    if (id.length === 36) {
      productTypeGet(id, intl.locale).then(response => {
        if (response.status === 200) {
          const data = response.data
          const m: ProductTypeModel = {
            id: data.id,
            name: data.name,
            slug: data.slug,
          }
          setTypeValue({value: m.id, label: m.name, color: ''} as SelectOptionModel)
        }
      })
    }
  }

  const loadImages = (id: string) => {
    if (id.length === 36) {
      productImages(id).then(response=>{
        let items: ImageModel[] = []
        if (response.status === 200) {
          items = response.data.items ? response.data.items.map((e: any) => {
            return {
              id: e.id,
              path: toImageUrl(e.path),
              pos: e.pos,
            } as ImageModel
          }) : []
        }
        setImages(items)
      })
    }
  }

  const loadProductOptions = () => {
    if (productMenuOpen && productHasNextPage) {
      const filter: IDataFilter = {'page': productPage, search: productSearch, lan: intl.locale}
      globalProductList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setTypeHasNextPage(productPage <= totalPages)
          if ((productPage === 1 || totalItems > productOptions.length) && response.data.items) {
            const newItems = [
              ...(productPage > 1 ? productOptions : []),
              ...response.data.items.map((e: any)=>{
                return { value: e.id, label: e.name, color: '' }
              })
            ]

            setProductOptions(newItems)
          }
        }
      })
    }
  }

  const loadTypeOptions = () => {
    if (typeMenuOpen && typeHasNextPage) {
      const filter: IDataFilter = {'page': typePage, search: typeSearch, lan: intl.locale,}
      productTypeList(filter).then(response => {
        if (response.status === 200) {
          const totalItems: Number = response.data.paginate.total_items
          const totalPages: Number = response.data.paginate.total_pages
          setTypeHasNextPage(typePage <= totalPages)
          if ((typePage === 1 || totalItems > typeOptions.length) && response.data.items) {
            {
              const newItems = [
                ...(typePage > 1 ? typeOptions : []),
                ...response.data.items.map((e: any) => {
                  return {value: e.id, label: e.name, color: ''}
                })
              ]

              setTypeOptions(newItems)

            }
          }
        }
      })
    }
  }

  const loadPayMethodOptions = () => {
    const newItems: Array<SelectOptionModel> = [
      {value: 'cash', label: intl.formatMessage({id: 'Cash'}), color: ''},
      {value: 'card', label: intl.formatMessage({id: 'Card'}), color: ''},
      {value: 'transfer', label: intl.formatMessage({id: 'Transfer'}), color: ''},
    ]

    setPayMethodOptions(newItems)
  }

  const loadData = () => {
    if (id && id.length === 36) {
      productOne(id).then(response => {
        if (response.status === 200) {
          const data = response.data;
          const m: ProductDataModel = {
            id: data.id,
            offer: data.offer === 'true',
            subscriberId: data.subscriber_id,
            productId: data.product_id,
            districtId: data.district_id,
            typeId: data.type_id,
            status: data.status,
            price: data.price,
            home: data.home,
            phone: data.phone ?? '',
            payMethod: data.pay_method,
            payAmount: data.pay_amount,
            payTime: data.pay_time,
            quantity: data.quantity,
            description: data.description,
            message: data.message,
            lon: data.lon,
            lat: data.lat,
            metaKeys: data.meta_keys,
            metaDesc: data.meta_desc,
            createdAt: data.created_at,
            updatedAt: data.updated_at,
          }
          loadProduct(m.productId)
          loadType(m.typeId)
          loadImages(m.id)
          loadPayMethodOptions()

          formik.setValues(m)
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(() => loadData(),[id])

  // eslint-disable-next-line
  useEffect(() => loadProductOptions(),[productMenuOpen, productPage, productSearch])

  // eslint-disable-next-line
  useEffect(() => loadTypeOptions(),[typeMenuOpen, typePage, typeSearch])

  // eslint-disable-next-line
  useEffect(() => {
    const selected = payMethodOptions.find(e=>e.value===formik.values.payMethod)
    if (selected) {
      setPayMethodValue(selected)
    }
  }, [formik.values.payMethod])

  return <>
    <div className="row">
      <div className="col-lg-12">
        {/* Ls widget */}
        <div className="ls-widget">
          <div className="tabs-box">
            <div className="widget-title">
              <h4>{intl.formatMessage({id: `${offer ? 'Seller' : 'Buyer'} advertisement`})}</h4>
            </div>
            {/* begin::PaymentButton */}
            <div style={{position: 'absolute', top: 40, right: 10, zIndex: 1000}}>
              <PayButton id={formik.values.id} name='product-ads' />
            </div>
            {/* end::PaymentButton */}
            <div className="widget-content">
              {offer ? <div className='d-flex justify-content-center'>
                {/* begin::Images */}
                <ModelImages
                  deletes={formik.values.imageDeleteIds}
                  setDeletes={(ids: Array<string>)=>formik.setFieldValue('imageDeleteIds', ids)}
                  images={images}
                  setImages={setImages}
                  max={2}
                />
                {/* end::Images */}</div> : null}
              <form className="default-form">
                <div className="row">
                  {/* begin::SelectDistrict */}
                  <DistrictSelectForm
                    id={formik.values.districtId}
                    callback={id=>formik.setFieldValue('districtId', id)}
                  />
                  {/* end::SelectDistrict */}
                  {/* begin::SelectProduct */}
                  <div className="form-group col-lg-6 col-md-12">
                    <label>{intl.formatMessage({id: 'Product'})}</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      value={productValue}
                      menuIsOpen={productMenuOpen}
                      onChange={(s)=>{
                        if (!s) { s = InitialSelectOptionData }
                        setProductValue(s)
                        formik.setFieldValue('productId', s.value)
                      }}
                      onMenuScrollToBottom={()=>{
                        setProductPage(1 + productPage)
                      }}
                      onInputChange={(s, m)=> {
                        if (m.action === "input-change"){
                          setProductSearch(s)
                        }
                      }}
                      onMenuClose={()=>setProductMenuOpen(false)}
                      onMenuOpen={()=> {
                        setProductMenuOpen(true)
                        setProductHasNextPage(true)
                        setProductOptions([])
                        setProductPage(1)
                        setProductSearch('')
                      }}
                      options={productOptions}
                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          flexWrap: 'nowrap',
                          height: '60px',
                        })
                      }}
                    />
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                  {/* end::SelectProduct */}
                  {/* begin::SelectProductType */}
                  <div className="form-group col-lg-3 col-md-12">
                    <label>{intl.formatMessage({id: 'Product type'})}</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      value={typeValue}
                      menuIsOpen={typeMenuOpen}
                      onChange={(s)=>{
                        if (!s) { s = InitialSelectOptionData }
                        setTypeValue(s)
                        formik.setFieldValue('typeId', s.value)
                      }}
                      onMenuScrollToBottom={()=>{
                        setTypePage(1 + typePage)
                      }}
                      onInputChange={(s, m)=> {
                        if (m.action === "input-change"){
                          setTypeSearch(s)
                        }
                      }}
                      onMenuClose={()=>setTypeMenuOpen(false)}
                      onMenuOpen={()=> {
                        setTypeMenuOpen(true)
                        setTypeHasNextPage(true)
                        setTypeOptions([])
                        setTypePage(1)
                        setTypeSearch('')
                      }}
                      options={typeOptions}
                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          flexWrap: 'nowrap',
                          height: '60px',
                        })
                      }}
                    />
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                  {/* end::SelectProductType */}
                  {/* begin::SelectPayMethod */}
                  <div className="form-group col-lg-3 col-md-12">
                    <label>{intl.formatMessage({id: 'Pay method'})}</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      value={payMethodValue}
                      menuIsOpen={payMethodMenuOpen}
                      onChange={(s)=>{
                        if (!s) { s = InitialSelectOptionData }
                        setPayMethodValue(s)
                        formik.setFieldValue('payMethod', s.value)
                      }}
                      onMenuClose={()=>setPayMethodMenuOpen(false)}
                      onMenuOpen={()=> {
                        setPayMethodMenuOpen(true)
                        loadPayMethodOptions()
                      }}
                      options={payMethodOptions}
                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          flexWrap: 'nowrap',
                          height: '60px',
                        })
                      }}
                    />
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                  {/* end::SelectPayMethod */}
                  {/* begin::SelectBazaars */}
                  <BazaarsSelect districtId={formik.values.districtId} productId={formik.values.id} setValue={setSelectedBazaars} />
                  {/* end::SelectBazaars */}
                  {/* begin::Quantity */}
                  <div className="form-group col-lg-6 col-md-12">
                    <label>{intl.formatMessage({id: 'Quantity'})}</label>
                    <input
                      className={clsx('',
                        { 'is-invalid': formik.touched.quantity && formik.errors.quantity },
                        { 'is-valid': formik.touched.quantity && !formik.errors.quantity }
                      )}
                      type='text'
                      {...formik.getFieldProps('quantity')}
                    />
                    {formik.touched.quantity && formik.errors.quantity && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.quantity}</span>
                      </div>
                    )}
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                  {/* begin::Quantity */}
                  {/* begin::Price */}
                  <div className="form-group col-lg-6 col-md-12">
                    <label>{intl.formatMessage({id: 'Price'})}</label>
                    <input
                      className={clsx('',
                        { 'is-invalid': formik.touched.price && formik.errors.price },
                        { 'is-valid': formik.touched.price && !formik.errors.price }
                      )}
                      type='text'
                      {...formik.getFieldProps('price')}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.price}</span>
                      </div>
                    )}
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Required'})}
                    </div>
                  </div>
                  {/* begin::Price */}
                  {/* begin::Phone */}
                  <div className="form-group col-lg-6 col-md-12">
                    <label>{intl.formatMessage({id: 'Phone'})}</label>
                    <input
                      className={clsx('',
                        { 'is-invalid': formik.touched.phone && formik.errors.phone },
                        { 'is-valid': formik.touched.phone && !formik.errors.phone }
                      )}
                      type='text'
                      {...formik.getFieldProps('phone')}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.phone}</span>
                      </div>
                    )}
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Optional'})}
                    </div>
                  </div>
                  {/* begin::Phone */}
                  {/* begin::Description */}
                  <div className='form-group col-lg-12 col-md-12'>
                    <label>{intl.formatMessage({id: 'Description'})}:</label>
                    <textarea
                      className={clsx('',
                        { 'is-invalid': formik.touched.description && formik.errors.description },
                        { 'is-valid': formik.touched.description && !formik.errors.description }
                      )}
                      rows={4}
                      {...formik.getFieldProps('description')}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                    <div className='form-text text-muted'>
                      {intl.formatMessage({id: 'Optional'})}
                    </div>
                  </div>
                  {/* end::Description */}
                  {/* begin::SubmitButton */}
                  <div className="form-group col-lg-6 col-md-12">
                    <button
                      type='button'
                      className='btn btn-danger mx-4'
                      onClick={deleteHandler}
                    >
                        <span className='indicator-label'>
                          {intl.formatMessage({id: 'Delete'})}
                        </span>
                    </button>
                    <button
                      type='button'
                      className="theme-btn btn btn-primary"
                      onClick={_=>formik.submitForm()}
                      disabled={!formik.isValid}
                    >
                      {intl.formatMessage({id: 'Save'})}
                    </button>
                  </div>
                  {/* begin::SubmitButton */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}
export default ProductAdsForm;