import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {LanguageContext, ModalContext} from "../AppContext";
import InitFooterJS from "./InitFooterJS";
import MainHeader from "../modules/global/components/MainHeader";
import MainFooter from "../modules/global/components/MainFooter";
import Modal from "../modules/global/components/Modal";
import {LocationDataProvider} from './core/HomeData'
import LoadingSpinner from './components/LoadingSpinner'
import {usePageData} from './core/PageData'


const MainLayout: React.FC = () => {
  const {lang, setLang} = useContext(LanguageContext)
  const {setShowLoading} = usePageData()

  const [menuShow, setMenuShow] = useState(false)
  const [modalNode, setModalNode] = useState<React.ReactNode>(null)

  const menuToggleHandler = () => {
    setMenuShow(!menuShow)
  }

  useEffect(()=>{
    if (modalNode) {
      document.querySelector('.dialog-modal-overlay')?.addEventListener('click', ({target})=>{
        if (target && (target as Element).className === 'dialog-modal-overlay') {
          setModalNode(null)
        }
      })
    }
  }, [modalNode])

  return (
    <>
      <ModalContext.Provider value={{open: false, childNode: null, setModal(value: React.ReactNode) {setModalNode(value)}}}>
        {/* begin::Modal */}
        <Modal>{modalNode}</Modal>
        {/* end::Modal */}
        <div className={`${modalNode ? 'overlay-' : ''}page-wrapper`}>
          {/* Loading spinner */}
          <LoadingSpinner/>
          {/* End Loading spinner */}
          {/* Main Header*/}
          <MainHeader/>
          {/*End Main Header */}
          {/* Preloader */}
          {/*<div className="preloader"></div>*/}
          {/* End Preloader */}
          <LocationDataProvider>
            {/*Placeholder*/}
            <Outlet/>
            {/*End Placeholder*/}
          </LocationDataProvider>
          {/* Main Footer */}
          <MainFooter/>
          {/* End Main Footer */}

          <InitFooterJS/>
        </div>
      </ModalContext.Provider>
    </>
  )
}

export default MainLayout