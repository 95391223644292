import axios from "axios/index";


export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL
      const {auth: {token}} = store.getState()
      if (token) { config.headers = {"Authorization": `Token ${token}`} }

      config.headers = {
        ...config.headers,
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/x-www-form-urlencoded',
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
