
const _STORE_KEY = process.env.REACT_APP_STORE_KEY ?? 'app-store-key'
export const GetLocalStorage = (key: string) => {
  let result = ''
  if (key.length > 0) {
    const data = JSON.parse(localStorage.getItem(_STORE_KEY) || '{}')
    if (Object.keys(data).length > 0) {
      try {
        result = data[key]
      } catch (e) { console.log('parse store data error', e)}
    }
  }
  return result
}

export const SetLocalStorage = (data: object) => {
  if (Object.keys(data).length > 0) {
    localStorage.setItem(_STORE_KEY, JSON.stringify(data))
  }
}

export const GetLanguage = (): 'uz'|'oz'|'ru' => {
  switch (GetLocalStorage('lang')) {
    case 'oz': return 'oz'
    case 'ru': return 'ru'
    default: return 'uz'
  }
}

export const SetLanguage = (lang: 'uz'|'oz'|'ru') => {
  SetLocalStorage({lang})
}

