import { FileCategoryModel, InitialFileCategory } from "./FileCategoryModel";
import { InitialSubscriber, SubscriberModel } from "../../subscribers/models/SubscriberModel"
import {DateFormat} from "../../global/helpers/DateHelpers";

export interface DownloadFileModel {
  id: string
  fileCategory: FileCategoryModel
  price: number
  periodEnd: string
  bazaarIds: string[]
  createdAt: string
  updatedAt: string
  subscriber: SubscriberModel
  fileProducts: FileProduct[]
}


export interface DownloadFileDataModel {
  id: string
  subscriberId: string
  fileCategoryId: string
  amount: number
  periodName: string
  periodEnd: string
  bazaarIds?: string[]
  fileProducts?: FileProduct[]
  createdAt: string
  updatedAt: string
}
export interface FileProduct {
  id: string
  typeId: string
  quantity: number
  has: boolean
}

export const InitialDownloadFile:DownloadFileModel = {
  id: '',
  subscriber: InitialSubscriber,
  fileCategory: InitialFileCategory,
  price: 0,
  periodEnd: '',
  bazaarIds: [],
  fileProducts: [],
  createdAt: '',
  updatedAt: '',
}

export const InitialDownloadFileData:DownloadFileDataModel = {
  id: '',
  subscriberId: '',
  fileCategoryId: '',
  amount: 0,
  periodName: 'day',
  periodEnd: DateFormat(new Date().toDateString()),
  createdAt: '',
  updatedAt: '',
}

export const InitialFileProduct: FileProduct = {
  id: '',
  typeId: '',
  quantity: 0,
  has: true,
}