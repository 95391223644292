import React from 'react'
import {Link} from 'react-router-dom'

type Props = {
    title: string
}

const ShareLinks: React.FC<Props> = ({title}) => {

  return <>
      <Link
        className="facebook"
        target='_blank'
        to={`https://www.facebook.com/sharer/sharer.php?u=#${window.location}`}
      >
          <i className="fab fa-facebook-f"></i> Facebook
      </Link>
      <Link
        target='_blank'
        to={`https://t.me/share/url?url=${window.location}&text=${title}`}
        className="twitter"
      >
          <i className="fab fa-telegram"></i> Telegram
      </Link>
  </>
}
export default ShareLinks