import React from "react"
import {useIntl} from "react-intl";
import {Route, Routes} from "react-router-dom";
import ProductAdsTable from "./componenets/ProductAdsTable";
import ProductAdsFormPage from "./ProductAdsFormPage";

type Props = {
  offer: boolean
}

const ProductAdsPage: React.FC<Props> = ({offer}) => {

  return <Routes>
    <Route index element={<ProductAdsTable offer={offer}/>}/>
    <Route path='/list' element={<ProductAdsTable offer={offer}/>}/>
    <Route path='/add' element={<ProductAdsFormPage offer={offer}/>}/>
    <Route path='/edit/:id' element={<ProductAdsFormPage offer={offer}/>}/>
  </Routes>
}
export default ProductAdsPage;