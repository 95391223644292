import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/App'
import {Provider} from "react-redux"
import store, {persistor} from "./setup/redux/Store"
import {PersistGate} from "redux-persist/integration/react";
import setupAxios from "./setup/axios/SetupAxios";
import axios from "axios";

setupAxios(axios, store)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <App />
    </PersistGate>
  </Provider>
);
