import { InitialSubscriber, SubscriberModel } from "../../subscribers/models/SubscriberModel";
import { DistrictModel, InitialDistrict } from "../../districts/models/DistrictModel";

export interface DeliveryModel {
  id: string
  subscriber: SubscriberModel
  approved: boolean
  vehicle: string
  weight: number
  disDate: string
  desDate: string
  phone: string
  offer: boolean
  description: string
  createdAt: string
  updatedAt: string
  curDistrict: DistrictModel
  desDistrict: DistrictModel
}

export interface DeliveryDataModel {
  id: string
  subscriberId: string
  curDistrictId: string
  desDistrictId: string
  vehicle: string
  weight: number
  disDate: string
  desDate: string
  offer: boolean
  adsDays: number
  adsPrice: number
  description: string
  createdAt: string
  updatedAt: string
}

export const InitialDelivery:DeliveryModel = {
  id: '',
  approved: false,
  vehicle: '',
  weight: 0,
  disDate: '',
  desDate: '',
  phone: '',
  offer: false,
  description: '',
  createdAt: '',
  updatedAt: '',
  subscriber: InitialSubscriber,
  curDistrict: InitialDistrict,
  desDistrict: InitialDistrict,
}

export const InitialDeliveryData:DeliveryDataModel = {
  id: '',
  subscriberId: '',
  curDistrictId: '',
  desDistrictId: '',
  vehicle: '',
  weight: 0,
  disDate: '',
  desDate: '',
  offer: false,
  adsDays: 0,
  adsPrice: 0,
  description: '',
  createdAt: '',
  updatedAt: '',
}