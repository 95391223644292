import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import ListViewInfo from '../../global/components/ListViewInfo'
import ListPerPageSelect from '../../global/components/ListPerPageSelect'
import {NumberFormat} from '../../global/helpers/ToHumansString'
import ListPaginate from '../../global/components/ListPaginate'
import {useIntl} from 'react-intl'
import {IDataFilter} from '../../global/models/DataFilter'
import ListFilterLocationSelect from '../../global/components/ListFilterLocationSelect'
import {useLocationData} from '../../../layouts/core/HomeData'
import {CityModel} from '../../cities/models/CityModel'
import {DistrictModel} from '../../districts/models/DistrictModel'
import {DateFormat} from '../../global/helpers/DateHelpers'
import {deliveryAds} from '../redux/deliveryAdsCRUD'
import {DeliveryAdsModel} from '../models/DeliveryAdsModel'

type Props = {
  offer: boolean
}

const DeliveryAdsList: React.FC<Props> = ({offer}) => {
  const intl = useIntl()
  const {cityId: searchCityId, districtId: searchDistrictId} = useLocationData()

  const [search, setSearch] = useState('')
  const [cityId, setCityId] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [list, setList] = useState<DeliveryAdsModel[]>([])
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const loadData = () => {
    const filter: IDataFilter = {offer, lan: intl.locale, perPage, page, search, districtId, cityId}
    deliveryAds(filter).then(response => {
      if (response.status === 200) {
        const items = (response.data.items ?? []).map((e: any) => {
          return {
            id: e.id,
            vehicle: e.vehicle,
            weight: e.weight,
            disDate: e.dis_date,
            desDate: e.des_date,
            description: e.description,
            createdAt: e.created_at,
            updatedAt: e.updated_at,
            curDistrict: {
              id: e.cur_district.id,
              name: e.cur_district.name,
              slug: e.cur_district.slug,
              city: {
                id: e.cur_district.city.id,
                name: e.cur_district.city.name,
                slug: e.cur_district.city.slug
              } as CityModel
            } as DistrictModel,
            desDistrict: {
              id: e.cur_district.id,
              name: e.cur_district.name,
              slug: e.cur_district.slug,
              city: {
                id: e.cur_district.city.id,
                name: e.cur_district.city.name,
                slug: e.cur_district.city.slug
              } as CityModel
            } as DistrictModel,
          } as DeliveryAdsModel
        })
        setPage(Number(response.data.paginate.page))
        setTotal(Number(response.data.paginate.total_items))
        setList([...(page > 1 ? list : []), ...items])
      }
    })
  }

  // eslint-disable-next-line
  useEffect(() => loadData(), [page, perPage, search, cityId, districtId])

  return <>
    {/*Header Span*/}
    <span className="header-span"></span>
    {/* End Header Span*/}
    {/*Page Title*/}
    <section className="page-title">
      <div className="auto-container">
        <div className="title-outer">
          <h1>{intl.formatMessage({id: `Delivery ${offer ? 'cargo' : 'goods'} advertisements`})}</h1>
          <ul className="page-breadcrumb">
            <li><Link to={'/'}>{intl.formatMessage({id: 'Home'})}</Link></li>
            <li>{intl.formatMessage({id: `Delivery ${offer ? 'cargo' : 'goods'} advertisements`})}</li>
          </ul>
        </div>
      </div>
    </section>
    {/*End Page Title*/}
    {/* List section */}
    <section className="ls-section">
      <div className="auto-container">
        <div className="filters-backdrop"></div>

        <div className="row">

          {/* Filters Column */}
          <div className="filters-column col-lg-4 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="filters-outer">
                <button type="button" className="theme-btn close-filters">X</button>

                {/* Filter by keywords */}
                <div className="filter-block">
                  <h4>{intl.formatMessage({id: 'Search by keywords'})}</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      name="listing-search"
                      placeholder={intl.formatMessage({id: 'Enter keywords'})}
                      value={search}
                      onChange={({target})=>setSearch(target.value)}
                    />
                    <span className="icon flaticon-search-3"></span>
                  </div>
                </div>

                {/*  Filter by location */}
                <ListFilterLocationSelect
                  cityId={searchCityId}
                  setCityId={setCityId}
                  districtId={searchDistrictId}
                  setDistrictId={setDistrictId}
                />
                
              </div>

              {/* Call To Action */}
              <div className="call-to-action-four">
                <h5>{intl.formatMessage({id: `Add ${offer ? 'cargo' : 'goods'} advertisement`})}</h5>
                <p>{intl.formatMessage({id: `Add ${offer ? 'cargo' : 'goods'} advertisement description`})}</p>
                <Link to={`/dashboard/delivery-goods-ads/add`} className="btn btn-primary">
                  <span className="btn-title">
                    {intl.formatMessage({id: `Add ${offer ? 'cargo' : 'goods'} advertisement`})}
                  </span>
                </Link>
                <div className="image" style={{backgroundImage: 'url(/images/resource/ads-bg-4.png)'}}></div>
              </div>
              {/* End Call To Action */}
            </div>
          </div>

          {/* Content Column */}
          <div className="content-column col-lg-8 col-md-12 col-sm-12">
            <div className="ls-outer">
              <button type="button" className="theme-btn btn-style-two toggle-filters">Show Filters
              </button>

              {/* ls Switcher */}
              <div className="ls-switcher">
                <div className="showing-result">
                  <div className="text">
                    <ListViewInfo on={list.length > 0 ? 1 : 0} to={list.length} total={total}/>
                  </div>
                </div>
                <div className="sort-by">
                  <ListPerPageSelect setPer={v=>setPerPage(v)}/>
                </div>
              </div>

              {list.map(e => <div key={e.id} className="job-block">
                <div className="inner-box">
                  <div className="content">
                    <span className="company-logo">
                      <img src={`/images/delivery-${offer ? 'car' : 'goods-2'}.png`} alt={intl.formatMessage({id: `Delivery ${offer ? 'cargo' : 'goods'} advertisement`})}/>
                    </span>
                    <h4><Link to={`/delivery-ads/detail/${e.id}`}>{e.vehicle}</Link></h4>
                    <ul className="job-info">
                      <li><i className="icon fa fa-map-location"></i> {`${e.curDistrict.city.name}, ${e.curDistrict.name}`}</li>
                      <li><i className="icon fa fa-arrow-alt-right"></i></li>
                      <li><i className="icon fa fa-map-location-dot"></i> {`${e.desDistrict.city.name}, ${e.desDistrict.name}`}</li>
                      <li><i className="icon fa fa-weight"></i> {`${NumberFormat(e.weight)} ${intl.formatMessage({id: 'Kg'})}`}
                      </li>
                    </ul>
                    <ul className="job-other-info">
                      <li className="time">{DateFormat(e.disDate)}</li>
                    </ul>
                  </div>
                </div>
              </div>)}

              {/* Listing Show More */}
              <ListPaginate showing={list.length} total={total} loadMore={() => setPage(1 + page)}/>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* List section */}
  </>
}
export default DeliveryAdsList