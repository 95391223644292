import axios, { AxiosResponse } from "axios"
import * as querystring from "querystring"
import {IDataFilter} from "../../global/models/DataFilter"

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'global-product'
const LIST = `${API_URL}/${_RESOURCE}/list`
const GET = `${API_URL}/${_RESOURCE}/get`

export function globalProductList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    except: filter.except && filter.except.length > 0 ? JSON.stringify(filter.except) : null,
    search: filter.search,
    page: filter.page,
  }))
}

export function globalProductGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}