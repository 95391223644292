import {useLocation} from "react-router-dom";

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(path: string) {
  const current = getCurrentUrl(path)
  const {pathname} = useLocation()
  if (!current || !path) {
    return false
  }

  if (pathname.indexOf(current) > -1 && path.length <= pathname.length) {
    return true
  }

  return false
}