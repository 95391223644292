import axios, { AxiosResponse } from "axios"
import * as querystring from "querystring"
import {IDataFilter} from "../../global/models/DataFilter";
import {SubscriberDataModel} from "../models/SubscriberModel";
import {DateFormat} from "../../global/helpers/DateHelpers";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'subscriber'
const GET = `${API_URL}/private/${_RESOURCE}/get`
const ONE = `${API_URL}/private/${_RESOURCE}/one`
const SAVE = `${API_URL}/private/${_RESOURCE}/save`
const DELETE = `${API_URL}/private/${_RESOURCE}/delete`
const TRANSACT_LIST = `${API_URL}/private/${_RESOURCE}/transact-list`
const FEEDBACK_LIST = `${API_URL}/private/${_RESOURCE}/feedback-list`

export function subscriberTransactions(filter: IDataFilter) {
  return axios.post(TRANSACT_LIST, querystring.stringify({
    id: filter.id,
    period: filter.period,
    search: filter.search,
    page: filter.page,
  }))
}

export function subscriberFeedbacks(filter: IDataFilter) {
  return axios.post(FEEDBACK_LIST, querystring.stringify({
    id: filter.id,
    search: filter.search,
    page: filter.page,
  }))
}

export function subscriberGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function subscriberOne(id: string) {
  if (id && id.length === 36) {
    return axios.post(ONE, querystring.stringify({id}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

export function subscriberSave(model: SubscriberDataModel) {
  return axios.post(SAVE, querystring.stringify({
    id: model.id,
    district_id: model.districtId,
    full_name: model.fullName,
    phone: model.phone,
    birth: DateFormat(model.birth),
    lon: model.lon,
    lat: model.lat,
    password: model.password,
  }))
}

export function subscriberDelete(ids: Array<string>) {
  return axios.post(DELETE, querystring.stringify({ ids: JSON.stringify(ids) }))
}
