import React, {useEffect, useState} from 'react';
import {messages} from "../i18n/messages";
import {IntlProvider} from "react-intl";
import {LanguageContext} from "./AppContext";
import {GetLanguage, SetLanguage} from "./modules/global/helpers/LocalStoragHelper";
import {SubscriberModel} from "./modules/subscribers/models/SubscriberModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../setup/redux/RootReducer";
import {PageDataProvider} from "./layouts/core/PageData"
import AuthInit from "./modules/auth/redux/AuthInit";
import Routes from "./Route/Routes";
import MainInit from './layouts/MainInit'

function App() {
  const user: SubscriberModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as SubscriberModel
  const [lang, setLang] = useState<'uz'|'oz'|'ru'>(()=>GetLanguage())

  useEffect(()=>SetLanguage(lang), [lang])
  return (
    <PageDataProvider>
      <div className="App"  data-anm=".anm">
        <IntlProvider locale={lang} messages={messages[lang]}>
          <LanguageContext.Provider value={{lang, setLang}}>
            <AuthInit>
              <Routes/>
            </AuthInit>
          </LanguageContext.Provider>
        </IntlProvider>
      </div>
      <MainInit/>
    </PageDataProvider>

  )
}

export default App
