import {IDataFilter} from "../../global/models/DataFilter";
import axios from "axios";
import querystring from "querystring";
import {PaymentModel} from "../models/PaymentModel";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'payment'
const WITH_BALANCE = `${API_URL}/private/${_RESOURCE}/with-balance`
const INIT_PAY = `${API_URL}/private/${_RESOURCE}/init-pay`
const INIT_MODEL = `${API_URL}/private/${_RESOURCE}/init-model`
const CHECK = `${API_URL}/private/${_RESOURCE}/check`

export function paymentCheck(m: PaymentModel) {
  return axios.post(CHECK, querystring.stringify({
    payment_model_id: m.id,
    payment_model_name: m.name
  }))
}

export function paymentWithBalance(m: PaymentModel) {
  return axios.post(WITH_BALANCE, querystring.stringify({
    payment_model_id: m.id,
    payment_model_name: m.name,
  }))
}

export function paymentInitPay(provider: 'PayMe'|'Click', model?: PaymentModel, amount?: number) {
  return axios.post(INIT_PAY, querystring.stringify({
    amount,
    payment_provider: provider,
    payment_model_id: model?.id,
    payment_model_name: model?.name,
  }))
}

export function paymentInitModel(model: PaymentModel) {
  return axios.post(INIT_MODEL, querystring.stringify({
    payment_model_id: model?.id,
    payment_model_name: model?.name,
  }))
}